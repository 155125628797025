import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function Customerenq() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(null);
  const [departments, setdepartments] = useState([]);
  const [customer, setselectedCustomer] = useState();
  const [setStatus, setselectedStatus] = useState("Pending");
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [selectedStatus, setsselectedStatus] = useState("Pending");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  useEffect(() => {
    http.get(`/unique_customer_contact?&login_user=${user.id}`).then((res) => {
      setdepartments(res.data.data);
    });
    fetchTaskDetails(customer, startDate, endDate, setStatus);
  }, [customer, startDate, endDate, setStatus]);

  const fetchTaskDetails = (
    phone = "",
    startDate = "",
    endDate = "",
    statusAll = ""
  ) => {
    setIsLoading(true);
    let endpoint = `/get_customer_enq?department=${phone}&startdate=${startDate}&enddate=${endDate}&status=${statusAll}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.users)) {
          setTasks(res.data.users);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/delete_enq/${taskId}`)
      .then((response) => {
        toast.success("Enqueries Deleted successfully !");
        fetchTaskDetails(customer, startDate, endDate);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleChangeType = async (event) => {
    setsselectedStatus(event.target.value);
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update_customer?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Status updated successfully !");
      fetchTaskDetails(customer, startDate, endDate);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const sendConfirm = async (email, id) => {
    await http
      .post(`/send_confirm_jc_enc/${email}/${id}`)
      .then((response) => {
        toast.success("Confirmation Email/SMS sent");
        // setTasks(response.data.data);
        fetchTaskDetails(customer, startDate, endDate);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleChange = async (event) => {
    setselectedCustomer(event.target.value);
  };

  const handleChangeStatus = async (event) => {
    setselectedStatus(event.target.value);
  };

  const handleChangeStartDate = async (event) => {
    setstartDate(event.target.value);
  };

  const handleChangeEndDate = async (event) => {
    setendDate(event.target.value);
  };

  return (
    <>
      <div className="container mt-1">
        <div className="row mb-2">
          <h1 className="mb-1">Customers Enquery</h1>
          <div className="col-md-12 text-end">
            <Link
              className="btn-link  text-end mb-3"
              to="/jobcart/customerenqadd"
            >
              Add Enquery
            </Link>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-2 form-group text-end">
            <select
              name=""
              onChange={handleChange}
              className="form-control mb-1"
            >
              <option value="">Select Customer</option>
              {departments.map((dept, index) => (
                <option key={index} value={dept.phone}>
                  {dept.phone}-{dept.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 form-group text-end">
            <select
              name=""
              onChange={handleChangeStatus}
              className="form-control mb-1"
            >
              <option value="">Select Status</option>
              <option value="Completed">Completed</option>
              <option value="Pending">Pending</option>
              <option value="Progress">Progress</option>
            </select>
          </div>

          <div className="col-md-2 form-group text-end">
            <input
              name="start_date"
              onChange={handleChangeStartDate}
              class="form-control"
              type="date"
            ></input>
          </div>

          <div className="col-md-2 form-group text-end">
            <input
              name="end_date"
              onChange={handleChangeEndDate}
              class="form-control"
              type="date"
            ></input>
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped">
              <thead className="table-primary">
                <tr>
                  <th className="text-danger">No</th>
                  <th>Created On</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Mode</th>
                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (
                  <tr key={taskuser.id}>
                    <td>{index + 1}</td>
                    <td width="90px">
                      {moment(taskuser.created_at).format("DD MMM,YY hh:mm A")}
                    </td>

                    <td>{taskuser.name}</td>
                    <td>{taskuser.company_name}</td>
                    <td>{taskuser.phone}</td>
                    <td>{taskuser.email}</td>
                    <td>{taskuser.description}</td>
                    <td>
                      <b>{taskuser.status}</b>
                    </td>
                    <td>{taskuser.mode}</td>
                    <td>
                      <select onChange={statusUpdate} className="">
                        <option value="">Select</option>
                        <option value="Pending">Pending</option>
                        <option value="Completed">Completed</option>
                        <option value="Progress">Progress</option>
                      </select>
                      <button
                        className="btn-sm btn btn-link"
                        onClick={() => handleSave(taskuser.id)}
                      >
                        Save
                      </button>

                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this Enqueries?"
                            )
                          ) {
                            deleteTask(taskuser.id);
                          }
                        }}
                      >
                        Delete
                      </button>

                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want send the emails?"
                            )
                          ) {
                            sendConfirm(taskuser.email, taskuser.id);
                          }
                        }}
                      >
                        Send Email
                      </button>
                      {taskuser.ext1 == 1 ? "✅" : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
