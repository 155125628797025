import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Customerenqadd() {
  const navigate = useNavigate();
  const { http, user } = AuthUser();

  const [formData, setFormData] = useState({
    assigned_by: user.id,
    branch: "",
    company_name: "",
    name: "",
    phone: "",
    email: "",
    description: "",
    mode: "",
    status: "Pending",
  });

  const initialState = {
    assigned_by: "",
    branch: "",
    company_name: "",
    name: "",
    phone: "",
    email: "",
    description: "",
    mode: "",
    status: "Pending",
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    try {
      http.post("/enq_add", form, {}).then((res) => {
        toast.success("Enqueries added successfully !");
        setFormData(initialState);
        navigate("/jobcart/customerenq");
      });
    } catch (error) {
      console.error("Error adding company:", error.response.data);
    }
  };
  return (
    <>
      <div className="container mt-5">
        <Toaster />
        <h3>Add Customer Enqueries</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label> Name</label>

            <input
              name="name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <div className="form-group mt-3">
              <label>Company</label>
              <input
                name="company_name"
                className="form-control"
                value={formData.company_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group mt-3">
            <label>Phone</label>

            <input
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Email</label>
            <input
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Mode</label>
            <select
              name="mode"
              value={formData.mode}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select</option>
              <option value="Just dial ">Just dial </option>
              <option value="Google">Google</option>
              <option value="Walk-In">Walk-In</option>
            </select>
          </div>

          <div className="form-group mt-3">
            <label>Description</label>
            <textarea
              name="description"
              className="form-control"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button className="btn button btn-success mt-3" type="submit">
            Add
          </button>
        </form>
      </div>
    </>
  );
}
