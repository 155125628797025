import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function Amc_list() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(null);
  const [departments, setdepartments] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectCompany, setselectedCompany] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [startDate, setstartDate] = useState("");
  const [selectResolve, setselectedResolve] = useState("No");
  const [endDate, setendDate] = useState("");
  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    fetchTaskDetails(selectCompany, startDate, endDate, selectResolve);
  }, [selectCompany, startDate, endDate, selectResolve]);

  const fetchTaskDetails = (
    companyset = "",
    start = "",
    end = "",
    resolve = "No"
  ) => {
    setIsLoading(true);
    let endpoint = `/get_amc?login_user=${user.id}&company=${companyset}&startdate=${start}&enddate=${end}&resolve=${resolve}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.users)) {
          setTasks(res.data.users);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });

    http.get(`/unique_company?login_user=${user.id}`).then((res) => {
      setCompany(res.data.data);
    });
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/amc_del/${taskId}`)
      .then((response) => {
        toast.success("AMC Deleted successfully !");
        fetchTaskDetails();
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "Completed":
        return <span className="badge bg-success font-size-13">Completed</span>;
      case "Pending":
        return <span className="badge bg-warning font-size-13">Pending</span>;
      case "Delayed":
        return <span className="badge bg-danger font-size-13">Delayed</span>;
      case "Delayed Completed":
        return (
          <span className="badge bg-danger font-size-13">
            Delayed Completed
          </span>
        );
      case "Progress":
        return (
          <span className="badge bg-secondary font-size-13">Progress</span>
        );
      default:
        return null; // Handle any other cases or leave it as null if not needed
    }
  };

  const [showModalRevise, setShowModalRevise] = useState(false);
  const [ModalId, setModalId] = useState(false);
  const [billtype, setBilltype] = useState();
  const [billvalue, setBillvalue] = useState();
  const [billTypes, setBillTypes] = useState();
  const [resolved, setResolved] = useState();

  const [formData, setFormData] = useState({
    bill: "",
    bill_value: "",
    bill_type:"",
    is_resolve:""
  });



  const openModalRevise = (user) => {
    setShowModalRevise(true);
    setFormData({
      bill: user.bill || "",
      bill_value: user.bill_value || "",
      bill_type: user.bill_type || "",
      is_resolve: user.is_resolve || "",
    });
    setEditUser(user);
  }

  const closeModal = () => {
    setShowModalRevise(false);
  };



  const handleChangeModal = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      http.put(`/revise_bill/${editUser.id}`, formData).then((res) => {
        toast.success("Bills updated successfully !");
        fetchTaskDetails();
        closeModal();
      });
    } catch (error) {
      console.error("Error adding task:", error.response.data);
    }
  };

  const sendConfirm = async (userId, id) => {
    await http
      .post(`/send_confirm_jc_amc/${userId}/${id}`)
      .then((response) => {
        toast.success("Confirmation Email/SMS sent");
        // setTasks(response.data.data);
        fetchTaskDetails();
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleChange = async (event) => {
    setselectedCompany(event.target.value);
  };

  const handleChangeResolve = async (event) => {


    setselectedResolve(event.target.value);
  };

  const handleChangeStartDate = async (event) => {
    setstartDate(event.target.value);
  };

  const handleChangeEndDate = async (event) => {
    setendDate(event.target.value);
  };

  return (
    <>
      {showModalRevise && (
        <div className="modal" tabindex="-1" style={{ display: "block" }}>
          <form onSubmit={handleSubmit}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Revision Details</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label>Type Bills</label>
                    <select
                      className="form-control"
                      name="bill"
                      value={formData.bill}
                      onChange={handleChangeModal}
                      required
                    >
                      <option value="">Select Any</option>
                      <option value="AMC">AMC</option>
                      <option value="NO BILL">NO BILL</option>
                      <option value="INVOICED">INVOICED</option>
                      <option value="INV PENDING">INV PENDING</option>
                    </select>
                  </div>

                  <div className="form-group mt-3">
                    <label>Bill Value</label>
                    <input
                      type="text"
                      name="bill_value"
                      className="form-control"
                      value={formData.bill_value}
                      onChange={handleChangeModal}
                    ></input>
                  </div>
                  <hr />

                  <div className="form-group  mt-3">
                    <label>Resolve</label>
                    <select
                      className="form-control"
                      name="is_resolve"
                      value={formData.is_resolve}
                      onChange={handleChangeModal}
                      required
                    >
                      <option value="">Select Any</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="form-group  mt-3">
                    <label>Visit Nature</label>
                    <select
                      className="form-control"
                      name="bill_type"
                      value={formData.bill_type}
                      onChange={handleChangeModal}
                    >
                      <option value="">Select Any</option>
                      <option value="Online">Online</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-sm btn btn-success"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="container mt-1">
        <h1>Tickets</h1>

        <div className="row">
          <div className="col-md-3 form-group mt-2 mb-2">
            <select onChange={handleChange} className="form-control mb-1">
              <option value="">All Company</option>
              {company.map((depart) => (
                <option key={depart.id} value={depart.company}>
                  {depart.company}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3 form-group mt-2 mb-2">
            <select
              onChange={handleChangeResolve}
              className="form-control mb-1"
            >
              <option value="">Resolve</option>
              <option value="">All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div className="col-md-2 form-group">
            <input
              name="start_date"
              onChange={handleChangeStartDate}
              className="form-control"
              type="date"
            />
          </div>

          <div className="col-md-2 form-group">
            <input
              name="end_date"
              onChange={handleChangeEndDate}
              className="form-control"
              type="date"
            />
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped center_tbl">
              <thead className="table-primary">
                <tr>
                  <th className="text-danger">No</th>
                  <th>T-Number</th>
                  <th>Created</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Priority</th>
                  <th>Description</th>
                  <th>Assigned</th>
                  <th>Bills</th>
                  <th>Resolve</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>AC{taskuser.id}</td>
                    <td>
                      <td width="14%">
                        {moment(taskuser.created_at).format(
                          "DD MMM,YY hh:mm A"
                        )}
                      </td>
                    </td>
                    <td>{taskuser.name}</td>
                    <td width="8%">
                      <b>{taskuser.company}</b>
                    </td>
                    <td>{taskuser.phone}</td>
                    <td width="3%">{taskuser.email}</td>
                    <td>{taskuser.priority}</td>
                    <td width="10%" >{taskuser.description}</td>
                    <td   className="text-uppercase text-success">
                      {taskuser.tasks.length > 0 ? (
                        <>
                          ✅{" "}
                          {taskuser.tasks.map((task, taskIndex) => (
                            <span key={taskIndex}>
                              {taskIndex > 0 && ", "}
                              {task.assignee.name 
                               }
                            </span>
                          ))}
                        </>
                      ) : (
                        "❌ Not Assigned"
                      )}
                    </td>
                    <td className=" text-primary">
                      {taskuser.bill}-{taskuser.bill_value}
                    </td>
                    <td className=" text-primary">
                      <b>{taskuser.is_resolve}</b>
                    </td>
                    <td className=" text-primary">{taskuser.bill_type}</td>
                    <td>
                      <b>{getStatusBadge(taskuser.tasks[0]?.status)}</b>
                    </td>
                    <td width="17%">
                      <a
                        href={`/jobcart/assign_amc/${taskuser.id}`}
                        className="btn-sm btn btn-link"
                      >
                        Assign
                      </a>
                      <a
                        href="#"
                        className="btn-sm btn btn-link"
                        onClick={() =>
                          openModalRevise(
                            taskuser
                          )
                        }
                      >
                      💶
                      </a>
                      {user.department_id == 1  &&(
                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this Task?"
                            )
                          ) {
                            deleteTask(taskuser.id);
                          }
                        }}
                      >
                      ❌
                      </button>
                      )}
                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want send the emails?"
                            )
                          ) {
                            sendConfirm(taskuser.email, taskuser.id);
                          }
                        }}
                      >
                        📩
                      </button>
                      {taskuser.mail_status == 1 ? "✅" : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
