import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import { Link } from 'react-router-dom';
import TaskSkeleton from './TaskSkeleton';

export default function Category() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [remark, setRemark] = useState(null);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    useEffect(() => {
        fetchTaskDetails();
    }, []);


    const fetchTaskDetails = () => {
        setIsLoading(true);
        let endpoint = `/category`;

        http.get(endpoint).then((res) => {
            setIsLoading(false);
            if (Array.isArray(res.data.data)) {
                setTasks(res.data.data);
            } else {
                setIsLoading(false);
                console.error("API response is not an array");
            }
        }).catch(error => {
            setIsLoading(false);
            console.error("Error fetching tasks:", error);
        });


    }




    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    }

    const handleSave = async (taskId) => {
        if (currentStatus === null || taskId === null) {
            toast.error("Please select proper status");
            return true;
        }
        try {
            const response = await http.put(`task_status_update?task_id=${taskId}&status=${encodeURIComponent(currentStatus)}`);
            toast.success("Task status updated successfully !");
            fetchTaskDetails();
        } catch (error) {
            console.error("Error fetching tasks:", error);
            toast.error("Please select proper item");
        }

    }

    const [formData, setFormData] = useState({
        revised_date: '',
        revised_remark: '',
    });
    const initialState = {
        revised_date: '',
        revised_remark: '',
    }

    const handleChangeModal = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const deleteTask = async (taskId) => {
        console.log(taskId);
        await http.delete(`/departments_del/${taskId}`)
            .then(response => {
                toast.success("Departments Deleted successfully !");
                fetchTaskDetails();
            })
            .catch(err => {
                console.error("Error deleting task", err);
            });
    };


    return (
        <>
            <Toaster />

            <div className="container mt-1">


                <div className='row'>
                    <h1>Category</h1>
                    <Link className="btn-link  text-end mb-3" to="/jobcart/category_add">Add Category</Link>
                </div>

                <div className="table-responsive">
                    {isLoading ? (
                        <TaskSkeleton />

                    ) : (
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>

                                    <th className='text-danger'>No</th>
                                    <th>Name</th>
                                    

                                    {/* Add other fields as necessary */}
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser,index) => (

                                    <tr key={taskuser.id}>
                                        <td>{index+1}</td>
                                        <td>{taskuser.name}</td>
                                        {/* <td>
                                            {
                                                (taskuser.id !== 61 && taskuser.id !== 1 && (
                                                    <button
                                                        className='btn btn-sm btn-danger'
                                                        onClick={() => {
                                                            if (window.confirm('Are you sure you want to delete this Department?')) {
                                                                deleteTask(taskuser.id);
                                                            }
                                                        }}
                                                    >
                                                        Delete
                                                    </button>

                                                ))
                                            }
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

            </div>
        </>
    )
}
