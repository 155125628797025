import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import TaskSkeleton from "./TaskSkeleton";

export default function AddAgents() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { http, user } = AuthUser();
    const [formData, setFormData] = useState({
        name: '',
        ext1: '',
    });

    const initialState = {
        name: '',
        ext1: '',
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        setIsLoading(true);
        let endpoint = `/agent?login_user=${user.id}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    }, []);





    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }

        try {
            http.post('/agent_add', form, {
            }).then((res) => {
                toast.success("Agent added successfully !");
                setFormData(initialState);
                navigate('/agent_list');
            })

        } catch (error) {
            console.error('Error adding company:', error.response.data);
        }
    };

    return (
        <>

            <div className="container mt-5">
                <Toaster />
                <h3>Add Agents</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-3'>
                        <label>Agent Name</label>
                        <input
                            name="name"
                            className='form-control'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add</button>
                </form>

                <div className="table-responsive mt-3">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Name</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr key={taskuser.id}>
                                        <td>{index + 1}</td>
                                        <td>{taskuser.name}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    )
}
