import React, { useRef } from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import moment from "moment";

export default function StudentPrint() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [cat, setCat] = useState([]);
    const [usersjob, setUsersJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        father: "",
        mother: "",
        father_occ: "",
        mother_phone: "",
        mother_occu: "",
        guardian_name: "",
        guardian_occu: "",
        guardian_relation: "",
        guardian_phone: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "",
    });

    const initialState = {
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "",
    }

    const contentRef = useRef(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'category') {
            fetchUsersForDepartment(e.target.value);
        }

        if (e.target.name === 'master_id') {
            fetchMasterIndividual(e.target.value)
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`/student_marks?&id=${id}&company_id=${user.company_id}`);
                // Simulate a 20-second delay using setTimeout
                setTimeout(() => {
                    setUsersJob(response.data.data);
                    setLoading(false); // Set loading to false after setting the state
                }, 200); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false in case of an error
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();

        for (const key in formData) {
            form.append(key, formData[key]);
        }

        const taskFileInput = document.querySelector('input[type="file"]');
        if (taskFileInput.files[0])
            form.append("ext2", taskFileInput.files[0]);

        for (const key in formData) {
            form.append(key, formData[key]);
        }
        try {
            http.post('/student_add', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },

            }).then((res) => {
                toast.success("Profile data added successfully !");
                setFormData(initialState);
                navigate('/student_list');
            })
        } catch (error) {
            console.error('Error adding student:', error.response.data);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };
    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
    }

    const handlePrint = () => {
        const printContent = contentRef.current.innerHTML;
        const originalContent = document.body.innerHTML;
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    console.log(usersjob);
    return (
        <>
            <div className="container mt-2 row">
                <Toaster />
                <div className="d-flex justify-content-end mb-2">
                    <button className="btn btn-primary mr-2" onClick={handlePrint}>
                        Print
                    </button>
                </div>
                <h3>Student Details</h3>
                <hr />
                <div className="col-md-4 mb-3" >

                </div>
                <div className='row  blue-input-container' ref={contentRef}>
                    <div className='col-md-4 border p-4 mx-1'>
                        <h4>Personal Details</h4>
                        {usersjob[0].photo ? (
                            <img
                                src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].photo}`}
                                width="135"
                                height="145"
                                alt="Photo"
                            />
                        ) : (
                            <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                        )}
                        <div className='form-group mt-3'>
                            <label>Admission Number: </label>
                            <label><b> {usersjob[0].adm_no}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Admission Date: </label>
                            <label><b>&nbsp; {moment(usersjob[0].adm_date).format('DD MMM,YYYY')}</b></label>

                        </div>

                        <div className='form-group mt-3'>
                            <label>First Name:</label>
                            <label><b>&nbsp;{usersjob[0].ext_2}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Last Name:</label>
                            <label><b>&nbsp; {usersjob[0].ext_3}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Gender:</label>
                            <label><b>&nbsp; {usersjob[0].ext_9}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>STS Number:</label>
                            <label><b>&nbsp; {usersjob[0].gvt_no}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Class:</label>
                            <label><b>&nbsp; {usersjob[0].class}</b></label>
                        </div>


                        <div className='form-group mt-3'>
                            <label>Section:</label>
                            <label><b>&nbsp;{usersjob[0].section}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Roll No:</label>
                            <label><b>&nbsp;{usersjob[0].roll_no}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Sibling Name & Class:</label>
                            <label><b>&nbsp;{usersjob[0].ext_7}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Identity Marks:</label>
                            <label><b>&nbsp; {usersjob[0].ext_8}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Group Name:</label>
                            <label><b>&nbsp; {usersjob[0].group_name}</b></label>

                        </div>

                        <div className='form-group mt-3'>
                            <label>Date Of Birth:</label>
                            <label><b>&nbsp; {moment(usersjob[0].dob).format('DD MMM,YYYY')}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Category:</label>
                            <label><b>{usersjob[0].category}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mobile Number</label>
                            <label><b>&nbsp; {usersjob[0].mobile}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Caste:</label>
                            <label><b>&nbsp;{usersjob[0].cast}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Religion:</label>
                            <label><b>&nbsp;{usersjob[0].religion}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Aadhaar No/UID:</label>
                            <label><b>&nbsp;{usersjob[0].adhar}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Email:</label>
                            <label><b>&nbsp;{usersjob[0].email}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Other Remark:</label>
                            <label><b>&nbsp;{usersjob[0].club}</b></label>
                        </div>

                        {/* <div className='form-group mt-3'>
                            <label>Transport:</label>
                            <label><b>&nbsp;{usersjob[0].ext_12}</b></label>
                        </div> */}

                        <div className='form-group mt-3'>
                            <label>Way:</label>
                            <label><b>&nbsp;{usersjob[0].ext_13}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Pickup Location</label>
                            <label><b>&nbsp; {usersjob[0].ext_16}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Previous Class:</label>
                            <label><b>&nbsp; {usersjob[0].ext_14}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Agent:</label>
                            <label><b>&nbsp; {usersjob[0].ext_11}</b></label>
                        </div>
                    </div>

                    <div className='col-md-4 border  p-4 mx-2'>
                        <h4>Parent / Guardian's Details</h4>

                        <div className='form-group mt-3'>
                            <label>Father's Name:</label>
                            <label><b>&nbsp; {usersjob[0].father}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Father's Phone:</label>
                            <label><b>&nbsp; {usersjob[0].ext_1}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Father's Occupation:</label>
                            <label><b>&nbsp; {usersjob[0].father_occ}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mother's Name:</label>
                            <label><b>&nbsp;{usersjob[0].mother}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Mother's Phone:</label>
                            <label><b>&nbsp; {usersjob[0].mother_phone}</b></label>
                        </div>


                        <div className='form-group mt-3'>
                            <label>Mother's Occupation:</label>
                            <label><b>&nbsp; {usersjob[0].mother_occu}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's  Name:</label>
                            <label><b>&nbsp;{usersjob[0].guardian_name}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Relation:</label>
                            <label><b>&nbsp;{usersjob[0].guardian_relation}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Occupation:</label>
                            <label><b>&nbsp;{usersjob[0].guardian_occu}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Phone:</label>
                            <label><b>&nbsp;{usersjob[0].guardian_phone}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Guardian's Address:</label>
                            <label><b>&nbsp;{usersjob[0].guardian_address}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>Current Address:</label>
                            <label><b>&nbsp;{usersjob[0].ext_4}</b></label>
                        </div>
                        <div className='form-group mt-3'>
                            <label>Permanent Address:</label>
                            <label><b>&nbsp;{usersjob[0].ext_5}</b></label>
                        </div>
                    </div>

                    <div className='col-md-3 border  p-2 px-2'>
                        <h4>Miscellaneous Details</h4>

                        <div className='form-group mt-3'>
                            <label>Previous School Details:</label>
                            <label><b>&nbsp;{usersjob[0].pre_school}</b></label>
                        </div>

                        <div className='form-group mt-3'>
                            <label>TC Number:</label>
                            <label><b>&nbsp;{usersjob[0].tc_num}</b></label>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
