import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function TermAdd() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [formData, setFormData] = useState({
        name: '',
        year: '',
        class: '',
    });

    const initialState = {
        name: '',
        year: '',
        class: '',

    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }

        try {
            http.post('/team_add', form, {
            }).then((res) => {
                toast.success("Terms added successfully !");
                setFormData(initialState);
                navigate('/terms');
            })

        } catch (error) {
            console.error('Error adding company:', error.response.data);
        }
    };
    return (
        <>

            <div className="container mt-5">
                <Toaster />
                <h3>Add Terms</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-3'>
                        <label>Term Name</label>

                        <input
                            name="name"
                            className='form-control'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>Term Name</label>

                        <input
                            name="name"
                            className='form-control'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add</button>
                </form>
            </div>
        </>
    )
}
