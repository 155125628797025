import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function Compny_add() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [formData, setFormData] = useState({
        company_address: '',
        company_name: '',
        contact_name: '',
        contact_phone: '',
        company_logo: '',


    });

    const initialState = {
        company_address: '',
        company_name: '',
        contact_name: '',
        contact_phone: '',
        company_logo: '',

    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }

        const taskFileInput = document.querySelector('input[type="file"]');
        if (taskFileInput.files[0]) form.append('company_logo', taskFileInput.files[0]);

        try {
            http.post('/company_add', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                toast.success("Company added successfully !");
                setFormData(initialState);
                navigate('/company_view');
            })

        } catch (error) {
            console.error('Error adding company:', error.response.data);
        }
    };
    return (
        <>

            <div className="container mt-5">
                <Toaster />
                <h3>Add School</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-3'>
                        <label>School Name</label>

                        <input
                            name="company_name"
                            className='form-control'
                            value={formData.company_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>School Address</label>

                        <input
                            name="company_address"
                            className='form-control'
                            value={formData.company_address}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>Contact Person</label>

                        <input
                            name="contact_name"
                            className='form-control'
                            value={formData.contact_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>Contact Mobile</label>

                        <input
                            name="contact_phone"
                            className='form-control'
                            value={formData.contact_phone}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form-group mt-3'>
                        <label>School Logo</label>
                        <input
                            type="file"
                            className='form-control'
                        />
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add</button>
                </form>
            </div>
        </>
    )
}
