import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function Taskjob() {
  const navigate = useNavigate();
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [loading_ts, setLoadingTS] = useState(false);

  const [formData, setFormData] = useState({
    customer_id: "",
    assigned_by: user.id,
    assigned_to: "",
    assiger_branch: "",
    description: "",
    inout: "",
    priority: "Medium",
    warranty: "Out Of Warranty",
    del_date: "",
    est_time: "1",
    service_amount: "",
    damage_remark: "",
    eng_remark: "",
    product_name: "",
    brand: "",
    model: "",
    power_adapter: "",
    bettery: "2",
    carry_case: "",
    laptop_bag: "",
    completed_on: "",
    type: "",
    status: "Pending",
    target_date: "",
    comp_type: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    customer_name: "",
  });

  const initialState = {
    customer_id: "",
    assigned_by: user.id,
    assigned_to: "",
    assiger_branch: "",
    description: "",
    inout: "",
    priority: "Medium",
    warranty: "Out Of Warranty",
    del_date: "",
    est_time: "1",
    eng_remark: "",
    service_amount: "",
    damage_remark: "",
    product_name: "",
    brand: "",
    model: "",
    power_adapter: "",
    bettery: "2",
    carry_case: "",
    laptop_bag: "",
    completed_on: "",
    type: "",
    status: "",
    target_date: "",
    comp_type: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    customer_name: ""
  };

  const [departmetsall, setdepartmentsall] = useState([]);
  const [customer, setcustomer] = useState([]);
  const [users, setUsers] = useState([]);
  const [available, setAvailable] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
      setUsers(res.data.users);
    });
    http.get(`/get_customer`).then((res) => {
      setcustomer(res.data.users);
    });
    fetchTaskDetails();
    http.get(`/branch`).then((res) => {
      setdepartments(res.data.users);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "assigned_to") {
      fetchCusterAvail(e.target.value);
    }
    if (e.target.name === "comp_type" && e.target.value === "2") {
      setShowProductDetails(true);
    }

    if (e.target.name === "comp_type" && e.target.value === "1") {
      setShowProductDetails(false);
    }
  };

  const fetchCusterAvail = async (custId) => {
    try {
      const response = await http.get(
        `/cust_available?cust_id=${custId}&login_user=${user.id}`
      );
      setAvailable(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingTS(1);
    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    try {
      http
        .post("/tasksjob", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success("Task added successfully !");
          setFormData(initialState);
          setLoadingTS(false);
          navigate("/jobcart/dashboard-job");
        });
    } catch (error) {
      console.error("Error adding task:", error.response.data);
    }
  };

  const options = customer.map((user) => ({
    value: user.id,
    label: `${user.name} - ${user.phone} - ${user.company}`,
  }));

  const [showModalRevise, setShowModalRevise] = useState(false);

  const openModalRevise = () => {
    setShowModalRevise(true);
  };
  const closeModal = () => {
    setShowModalRevise(false);
  };

  const fetchTaskDetails = () => {
    setIsLoading(true);
    let endpoint = `/cust_available_total?login_user=${user.id}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };
  const submitButtonText = loading_ts ? "Submitting..." : "Add Job Card";

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad single-digit months and days with a leading zero
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const [minDate, setMinDate] = useState(getCurrentDate());

  return (
    <>
      <>
        {showModalRevise && (
          <div className="modal" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
              <form onSubmit={handleSubmit}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Work status Details</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <table className="table table-striped">
                      <thead className="table-primary">
                        <tr>
                          <th className="text-danger">No</th>
                          <th>Name</th>
                          <th>Total Time</th>
                          <th>Total Analitics</th>
                          <th>Today</th>
                          <th>Tomorrow</th>
                          <th>Day-after</th>
                          {/* Add other fields as necessary */}
                        </tr>
                      </thead>
                      <tbody>
                        {tasks.map((taskuser, index) => (
                          <tr key={taskuser.id}>
                            <td>{index + 1}</td>
                            <td className="text-uppercase">
                              {taskuser.name} {taskuser.last_name}
                            </td>
                            <td>
                              <b>{taskuser.total_est_time}</b> Hours
                            </td>
                            <td width="17%">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${taskuser.percentage}%` }}
                                  aria-valuenow={taskuser.percentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {taskuser.percentage}%
                                </div>
                              </div>
                            </td>
                            {/* Today logic */}
                            <td width="17%">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${taskuser.today_percentage}%`,
                                  }}
                                  aria-valuenow={taskuser.today_percentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {taskuser.today_percentage}%
                                </div>
                              </div>
                              <div className="row">
                                <span className="text-center col-12">
                                  <b>{taskuser.today_est_time} Hrss</b>
                                </span>
                              </div>
                            </td>
                            {/* Tommorrw logic */}
                            <td width="17%">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${taskuser.tommorrow_percentage}%`,
                                  }}
                                  aria-valuenow={taskuser.tommorrow_percentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {taskuser.tommorrow_percentage}%
                                </div>
                              </div>
                              <div className="row">
                                <span className="text-center col-12">
                                  <b>{taskuser.tommorrow_est_time} Hr</b>
                                </span>
                              </div>
                            </td>

                            {/* Dayafter logic */}
                            <td width="17%">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${taskuser.dayafter_percentage}%`,
                                  }}
                                  aria-valuenow={taskuser.dayafter_percentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {taskuser.dayafter_percentage}%
                                </div>
                              </div>
                              <div className="row">
                                <span className="text-center col-12">
                                  <b>{taskuser.dayafter_est_time} Hr</b>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn-sm btn btn-success"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="container mt-5">
          <Toaster />
          <h1>Add Job Card</h1>
          <a
            href="#"
            className="text-end btn-link"
            onClick={() => openModalRevise()}
          >
            Analitics 📊
          </a>
          <form onSubmit={handleSubmit} className="row">
            <div className="col-md-6 border p-3 rounded-3 mx-4">

              <div className="form-group mt-3 border p-2 border-success rounded">
                <label>Branch</label>
                <select
                  onChange={handleChange}
                  name="assiger_branch"
                  className="form-control mb-1"
                  required
                >
                  <option value="">Select Branch</option>
                  {departments.map((dept, index) => (
                    <option key={index} value={dept.id}>
                      {dept.branch_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group  mt-3">
                <label>Type</label>
                <select
                  name="comp_type"
                  className="form-control text-uppercase"
                  value={formData.comp_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="2">Walk In (New) </option>
                  <option value="1">Exist Company</option>
                </select>
              </div>
              {showProductDetails && (
                <div className="border border-warning p-2 mt-2 rounded">
                  <div className="form-group mt-3">
                    <label>Person Name</label>

                    <input
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Phone</label>

                    <input
                      name="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Email</label>

                    <input
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Location</label>

                    <input
                      name="address"
                      className="form-control"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              )}
              {!showProductDetails && (
                <div className="form-group mt-3">
                  <label>Customer</label>

                  <Select
                    options={options}
                    name="customer_id"
                    value={options.find(
                      (option) => option.value === formData.customer_id
                    )}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        customer_id: selectedOption.value,
                        customer_name: selectedOption.label,
                      })
                    }
                  />
                </div>
              )}
              <div className="form-group  mt-3">
                <label>Assigned To</label>
                <select
                  name="assigned_to"
                  className="form-control text-uppercase"
                  value={formData.assigned_to}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} {user.last_name}
                    </option>
                  ))}
                </select>
              </div>
              {available && (
                <div className="border border-warning mt-3 p-3 rounded">
                  <div className="form-group  mt-3 ">
                    <label className="text-danger px-2">
                      Total Time Occupied :{" "}
                    </label>
                    <span class="badge bg-success">
                      {available.est_time_sum == 0
                        ? "Fully available"
                        : available.est_time_sum + " Hours"}{" "}
                    </span>
                  </div>

                  <div className="progress  mt-2">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${available.percentage}%` }}
                      aria-valuenow={available.percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {available.percentage}%
                    </div>
                  </div>
                </div>
              )}



              <div className="form-group  mt-2">
                <label>Establish Time</label>
                <select
                  name="est_time"
                  onChange={handleChange}
                  className="form-control"
                  value={formData.est_time}
                  required
                >
                  <option value="0">0 Hour</option>
                  <option value="1">1 Hour</option>
                  <option value="2">2 Hour</option>
                  <option value="3">3 Hour</option>
                  <option value="4">4 Hour-Half day</option>
                  <option value="5">5 Hour</option>
                  <option value="6">6 Hour</option>
                  <option value="7">7 Hour</option>
                  <option value="8">8 Hour</option>
                </select>
              </div>

              <div className="form-group mt-3">
                <label>Problem & Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  value={formData.description}
                  onChange={handleChange}
                  rows="2"
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label>InSide/Outside</label>
                <select
                  name="inout"
                  className="form-control"
                  value={formData.inout}
                  onChange={handleChange}
                  required
                >
                  <option value="1">Inside</option>
                  <option value="2">Outside</option>
                </select>
              </div>

              <div className="form-group  mt-2">
                <label>Tentative Delivery Date</label>
                <input
                  name="del_date"
                  className="form-control"
                  type="date"
                  value={formData.del_date}
                  onChange={handleChange}

                />
              </div>

              <div className="form-group  mt-2">
                <label>Target Date</label>
                <input
                  name="target_date"
                  className="form-control"
                  type="date"
                  value={formData.target_date}
                  onChange={handleChange}
                  min={minDate}
                  required
                />
              </div>

              <div className="form-group  mt-2">
                <label>Warranty Detail</label>
                <select
                  className="form-control"
                  onChange={handleChange}
                  name="warranty"
                >
                  <option value="Out Of Warranty">Out Of Warranty</option>
                  <option value="Under Warranty">Under Warranty</option>
                </select>
              </div>

              <div className="form-group mt-3">
                <label>Priority</label>
                <select
                  name="priority"
                  className="form-control"
                  value={formData.priority}
                  onChange={handleChange}
                  required
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>

              <div className="form-group  mt-2">
                <label>Service Amount</label>
                <input
                  name="service_amount"
                  onChange={handleChange}
                  value={formData.service_amount}
                  className="form-control"
                  type="number"

                />
              </div>

              <div className="form-group  mt-2">
                <label>Engineer Remark</label>
                <input
                  name="eng_remark"
                  onChange={handleChange}
                  value={formData.eng_remark}
                  className="form-control"
                  type="text"

                />
              </div>

              <div className="form-group  mt-2">
                <label>Damage Remark</label>
                <input
                  name="damage_remark"
                  onChange={handleChange}
                  value={formData.damage_remark}
                  className="form-control"
                  type="text"
                />
              </div>
            </div>

            <div className="col-md-5 border p-3 rounded-3">
              <h1>Product Details</h1>
              <div className="form-group  mt-2">
                <label>Product Name</label>
                <input
                  name="product_name"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.product_name}
                  type="text"
                  required
                />
              </div>

              <div className="form-group  mt-2">
                <label>Others</label>
                <input
                  name="brand"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.brand}
                  type="text"
                />
              </div>

              <div className="form-group  mt-2">
                <label>Model</label>
                <input
                  name="model"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.model}
                  type="text"
                />
              </div>

              <div className="form-group  mt-2">
                <label>Product Password</label>
                <input
                  name="type"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.type}
                  type="text"
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor="view_branch">Product Attributes</label>

                <div className="form-group mt-3">
                  <label>Power adapter</label>
                  <select
                    name="power_adapter"
                    className="form-control"
                    onChange={handleChange}
                    value={formData.power_adapter}
                    required
                  >
                    <option value="">Select Any</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>

                {/* <div className="form-group mt-3">
                  <label htmlFor="k2">Bettery</label>
                  <select
                    name="bettery"
                    className="form-control"
                    onChange={handleChange}
                    value={formData.bettery}
                    
                  >
                    <option value="">Select Any</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div> */}

                <div className="form-group mt-3">
                  <label htmlFor="k2">Carry case</label>
                  <select
                    name="carry_case"
                    className="form-control"
                    onChange={handleChange}
                    value={formData.carry_case}
                    required
                  >
                    <option value="">Select Any</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="k2">Laptop Bag</label>
                  <select
                    name="laptop_bag"
                    className="form-control"
                    onChange={handleChange}
                    value={formData.laptop_bag}
                    required
                  >
                    <option value="">Select Any</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>
              </div>
            </div>

            <button
              className="btn button btn-success mt-3 mb-2"
              type="submit"
              disabled={loading_ts}
            >
              {submitButtonText}
            </button>
          </form>
        </div>
      </>
    </>
  );
}
