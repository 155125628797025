import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import { Link } from 'react-router-dom';
import TaskSkeleton from './TaskSkeleton';

export default function Branch() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(null);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);

  useEffect(() => {
    fetchTaskDetails();
}, []);

const fetchTaskDetails = () => {
    setIsLoading(true);
    let endpoint = `/branch`;

    http.get(endpoint).then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.users)) {
            setTasks(res.data.users);
        } else {
            setIsLoading(false);
            console.error("API response is not an array");
        }
    }).catch(error => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
    });
}



  return (
    <>
      <div className="container mt-1">
        <div className='row mb-2'>
          <h1 className="mb-1">Customers Enquery</h1>
          <Link className="btn-link  text-end mb-3" to="/jobcart/branch_add">Add Branch</Link>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />

          ) : (
            <table className="table table-striped">
              <thead className="table-primary">
                <tr>

                  <th className='text-danger'>No</th>
                  <th>Branch Name</th>
                 

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (

                  <tr key={taskuser.id}>
                    <td>{index + 1}</td>
                    <td>{taskuser.branch_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}
