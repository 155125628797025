import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "../components/home";
import Dashboard from "../components/dashboard";
import Users from "../components/users";
import AuthUser from "../components/AuthUser";
import Tasks from "../components/tasks";
import Tasksview from "../components/tasksview";
import Departmenttask from "../components/departmenttask";
import Departmentview from "../components/departmentview";
import Department_add from "../components/department_add";
import toast, { Toaster } from "react-hot-toast";
import Resetpassword from "../components/resetpassword";
import Designation from "../components/designation";
import Designation_add from "../components/designation_add";
import Compny_add from "../components/compny_add";
import BulkTaskWeek from "../components/bulktaskweek";
import Taskjob from "../components/taskjob";
import Customer from "../components/customer";
import Customeradd from "../components/customeradd";
import Customerenqadd from "../components/customerenqadd";
import Customerenq from "../components/customerenq";
import Dashboard_job from "../components/dashboard_job";
import Amc_list from "../components/amc_list";
import Assign_amc from "../components/assign_amc";
import Branch from "../components/branch";
import Branch_add from "../components/branch_add";
import Category_add from "../components/category_add";
import Category from "../components/category";
import Stocks from "../components/stocks";
import Amc_dashboard from "../components/amc_dashboard";
import List_group from "../components/list_group";
import Group_task from "../components/group_task";
import Teacher_list from "../components/teacher_list";
import Admin_list from "../components/admin_list";
import FeesList from "../components/FeesList";
import TutionAdd from "../components/TutionAdd";
import StudentList from "../components/StudentList";
import StudentAdd from "../components/StudentAdd";
import BillList from "../components/BillList";
import TermList from "../components/TermList";
import TermsAdd from "../components/TermAdd";
import AddProgress from "../components/AddProgress";
import Marks from "../components/Marks";
import ClassList from "../components/ClassList";
import ClassAdd from "../components/ClassAdd";
import SubjectList from "../components/SubjectList";
import SubjectAdd from "../components/SubjectAdd";
import Devision from "../components/Devision";
import AddDevision from "../components/AddDevision";
import YearList from "../components/YearList";
import YearAdd from "../components/YearAdd";
import StudentEdit from "../components/StudentEdit";
import StudentView from "../components/StudentView";
import FeesItem from "../components/FeesItem";
import PrintSt from "../components/PrintSt.js";
import StudentListAll from "../components/StudentListAll.js";
import DueList from "../components/DueList.js";
import PaidList from "../components/PaidList.js";
import Connsess from "../components/Connsess.js";
import ConnessAdd from "../components/ConnessAdd.js";
import SeriesSet from "../components/SeriesSet.js";
import AgentMaster from "../components/AgentMaster.js";
import AddAgents from "../components/AddAgents.js";
import Location from "../components/Location.js";
import AddLocation from "../components/AddLocation.js";
import PrintAll from "../components/PrintAll.js";
import StudentPrint from "../components/StudentPrint.js";
import Company_view from "../components/company_view.js";
import ReportAll from "../components/ReportAll.js";
import DueReport from "../components/DueReport.js";
import SubAdminList from "../components/SubAdminList.js";
import DueListTeacher from "../components/DueListtech.js";
import DueListtech from "../components/DueListtech.js";


function Auth() {
  const { http } = AuthUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const [statusUser, setStatus] = useState();
  const [companylogo, setCompanylogo] = useState();
  const [companyname, setCompanyname] = useState();
  const [companyId, setCompanyId] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const { token, logout, user } = AuthUser();
  const [masterMenuOpen, setMasterMenuOpen] = useState(false);

  const toggleMasterMenu = () => {
    setMasterMenuOpen(!masterMenuOpen);
  };

  const logoutUser = () => {
    if (token != undefined) {
      logout();
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    fetchStatus();
  }, []);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const fetchStatus = async () => {
    // await http.get(`/user_status=${user.id}`).then((res) => {
    //     setStatus(res[0].status);
    // })

    await http.get(`/companyinfo?login_user=${user.id}`).then((resp) => {
      setCompanyname(resp.data.data[0].company_name);
      setCompanylogo(resp.data.data[0].company_logo);
      setCompanyId(resp.data.data[0].company_id);
    });
  };

  if (user.status === 0) {
    sessionStorage.clear();
    navigate("/login");
    toast.error("Your Account Not Yet Approved !");
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  }

  // Check if the current URL is http://localhost:3000/jobcart
  const isJobCartPage = location.pathname.startsWith("/jobcart");

  if (isJobCartPage) {
    if (user.branch_admin != 1) {
      sessionStorage.clear();
      navigate("/jobcart/login");
      toast.error("Your Account Not Yet Approved !");
      setTimeout(() => {
        navigate("/jobcart/login");
      }, 1000);
    }
    return (
      <>
        <Toaster />



        <div className="containers">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/jobcart/dashboard-job" element={<Dashboard_job />} />
            <Route path="/jobcart/taskjob" element={<Taskjob />} />
            <Route path="/jobcart/customer" element={<Customer />} />
            <Route path="/jobcart/customeradd" element={<Customeradd />} />
            <Route path="/jobcart/customerenq" element={<Customerenq />} />
            <Route path="/jobcart/branch" element={<Branch />} />
            <Route path="/jobcart/branch_add" element={<Branch_add />} />
            <Route
              path="/jobcart/customerenqadd"
              element={<Customerenqadd />}
            />
            <Route path="/jobcart/amc_list" element={<Amc_list />} />
            <Route path="/jobcart/assign_amc/:id" element={<Assign_amc />} />
            <Route path="/jobcart/category_add" element={<Category_add />} />
            <Route path="/jobcart/stocks" element={<Stocks />} />
            <Route path="/jobcart/category" element={<Category />} />
          </Routes>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Toaster />
        <div className="sidebar pe-2 pb-3">
          <nav className="navbar bg-light navbar-light">
            <a href="/" className="navbar-brand mx-4 mb-3">
              <h3 className="text-primary"> <img src={`http://3.111.220.78/api/storage/tasks/${companylogo}`} width={160} alt="photo" className="p-2" /></h3>
            </a>
            <div className="d-flex align-items-center ms-4 mb-4">
              <div className="position-relative">
                <i class="fa fa-user fa-2x"></i>
                <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1">
                </div>
              </div>
              <div className="ms-3">
                <h6 className="mb-0">{user.name} {user.last_name} </h6>
                <span></span>
              </div>
            </div>
            <div className="navbar-nav w-100">
              <Link to="/" className="nav-item nav-link active"><i
                className="fa fa-tachometer-alt me-2"></i>Dashboard</Link>
              {user.status === 1 && (user.department_id == 1 || user.department_id == 3) &&
                <div className="nav-item dropdown">
                  {/* data-bs-toggle="dropdown" */}
                  <Link to="/" className="nav-link dropdown-toggle" ><i
                    className="fa fa-keyboard me-2"></i>Sub Admin</Link>
                  <div className="dropdown-menu bg-transparent border-0 show">


                    <Link to="student_list_all" className={`dropdown-item ${activeItem === 'student_list_all' ? 'active' : ''}`}
                      onClick={() => handleItemClick('student_list_all')}>Student List</Link>



                    <Link to="student_list" className={`dropdown-item ${activeItem === 'student_list' ? 'active' : ''}`}
                      onClick={() => handleItemClick('student_list')}>Fees Pay</Link>


                  </div>
                </div>}

              <div className="nav-item dropdown">
                <a href="/" className="nav-link dropdown-toggle" ><i
                  className="fa fa-flag me-2"></i>Reports</a>
                <div className="dropdown-menu bg-transparent border-0 show">
                  {user.status === 1 && (user.department_id == 1 || user.department_id == 3) &&
                    <Link to='/duelist' className={`dropdown-item ${activeItem === 'duelist' ? 'active' : ''}`}
                      onClick={() => handleItemClick('duelist')}>Student Due</Link>
                  }

                  {user.status === 1 && (user.department_id == 2) &&
                    <Link to='/duelist_teach' className={`dropdown-item ${activeItem === 'duelist_teach' ? 'active' : ''}`}
                      onClick={() => handleItemClick('duelist_teach')}>Student Due(T)</Link>
                  }

                  {user.status === 1 && (user.department_id == 1 || user.department_id == 3) &&
                    <Link to='/paidlist' className={`dropdown-item ${activeItem === 'paidlist' ? 'active' : ''}`}
                      onClick={() => handleItemClick('paidlist')}>Daily Report</Link>
                  }

                  {user.status === 1 && (user.department_id == 1 || user.department_id == 3) &&
                    <Link to='/duereport' className={`dropdown-item ${activeItem === 'duereport' ? 'active' : ''}`}
                      onClick={() => handleItemClick('duereport')}>Class Due</Link>
                  }

                  {user.status === 1 && (user.department_id == 1 || user.department_id == 3) &&
                    <Link to='/report_all' className={`dropdown-item ${activeItem === 'report_all' ? 'active' : ''}`}
                      onClick={() => handleItemClick('report_all')}>All Receipts</Link>
                  }
                </div>
              </div>

              {/* {user.status === 1 && user.department_id === 1 &&
                <div className="nav-item dropdown">
                  <a href="/" className="nav-link dropdown-toggle" ><i
                    className="fa fa-laptop me-2"></i>Progress</a>
                  <div className="dropdown-menu bg-transparent border-0 show">

                    <Link to='/terms' className={`dropdown-item ${activeItem === 'terms' ? 'active' : ''}`}
                      onClick={() => handleItemClick('terms')}>Terms List</Link>
                    <Link to='/marks' className={`dropdown-item ${activeItem === 'marks' ? 'active' : ''}`}
                      onClick={() => handleItemClick('marks')}>Marks </Link>

                    <Link to="/subject" className={`dropdown-item ${activeItem === 'subject' ? 'active' : ''}`}
                      onClick={() => handleItemClick('subject')}>Subject List</Link>

                  </div>
                </div>
              } */}


              {/* Master list */}
              <div className="nav-item dropdown">
                <a href="/" className="nav-link dropdown-toggle" onClick={(e) => { e.preventDefault(); toggleMasterMenu(); }}><i
                  className="fa fa-window-restore me-2"></i>Master</a>
                <div className={`dropdown-menu bg-transparent border-0 ${masterMenuOpen ? 'show' : ''}`}>
                  {user.status === 1 && user.department_id === 1 && (
                    <>
                      <Link to="fees_list" className={`dropdown-item ${activeItem === 'fees_list' ? 'active' : ''}`}
                        onClick={() => handleItemClick('fees_list')}>Fees Type</Link>
                      <Link to="fees_add" className={`dropdown-item ${activeItem === 'fees_add' ? 'active' : ''}`}
                        onClick={() => handleItemClick('fees_add')}>Add Fees Types</Link>
                      <Link to="year" className={`dropdown-item ${activeItem === 'year' ? 'active' : ''}`}
                        onClick={() => handleItemClick('year')}>Year List</Link>
                      <Link to="/class" className={`dropdown-item ${activeItem === 'class' ? 'active' : ''}`}
                        onClick={() => handleItemClick('class')}>Class List</Link>
                      <Link to="/devision" className={`dropdown-item ${activeItem === 'devision' ? 'active' : ''}`}
                        onClick={() => handleItemClick('devision')}>Division List</Link>
                      <Link to="location_list" className={`dropdown-item ${activeItem === 'location_list' ? 'active' : ''}`}
                        onClick={() => handleItemClick('location_list')}>Location List</Link>
                      <Link to="agent_list" className={`dropdown-item ${activeItem === 'agent_list' ? 'active' : ''}`}
                        onClick={() => handleItemClick('agent_list')}>Agent List</Link>
                      <Link to="fees_item" className={`dropdown-item ${activeItem === 'fees_item' ? 'active' : ''}`}
                        onClick={() => handleItemClick('fees_item')}>Master Fees Update</Link>
                      <Link to="series" className={`dropdown-item ${activeItem === 'series' ? 'active' : ''}`}
                        onClick={() => handleItemClick('series')}>Admn Series</Link>
                    </>
                  )}
                </div>
              </div>

              {user.status === 1 && user.department_id === 1 &&
                <div className="nav-item dropdown">
                  <a href="/" className="nav-link dropdown-toggle" ><i
                    className="fa fa-window-restore me-2"></i>Admin</a>
                  <div className="dropdown-menu bg-transparent border-0 show">


                    {user.status === 1 && user.department_id === 1 &&
                      <Link to="admin_list" className={`dropdown-item ${activeItem === 'admin_list' ? 'active' : ''}`}
                        onClick={() => handleItemClick('admin_list')}>Admin List</Link>
                    }

                    {user.status === 1 && user.department_id === 1 &&
                      <Link to="subadmin_list" className={`dropdown-item ${activeItem === 'subadmin_list' ? 'active' : ''}`}
                        onClick={() => handleItemClick('subadmin_list')}>Sub-Admin List</Link>
                    }


                    {user.status === 1 && user.department_id === 1 &&
                      <Link to="department" className={`dropdown-item ${activeItem === 'department' ? 'active' : ''}`}
                        onClick={() => handleItemClick('department')}>Roles</Link>
                    }

                    {user.status === 1 && user.department_id === 1 &&
                      <Link to='/teachers' className={`dropdown-item ${activeItem === 'teachers' ? 'active' : ''}`}
                        onClick={() => handleItemClick('teachers')}>Teachers List</Link>
                    }


                  </div>
                </div>
              }

              {user && (user.id == 4 || user.id == 5) && (
                <li
                  className={`nav-item ${location.pathname === "/company_view" ? "active" : ""
                    }`}
                >
                  <Link className="nav-link" to="/company_view">
                    <i className="fa fa-th me-2"></i>
                    Schools
                  </Link>
                </li>
              )}

              <li
                className={`nav-item ${location.pathname === "/resetpass" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/resetpass">
                  <i className="fa fa-th me-2"></i>
                  Password
                </Link>
              </li>

            </div>

          </nav>
        </div>
        <div class="content">
          <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">

            <a href="/" className="">
              <span className="mr-2 pr-3"><b>{companyId}</b> { } - { } <b>{companyname}</b></span>
            </a>

            <div className="navbar-nav align-items-center ms-auto">
              <div className="nav-item dropdown">
                <a href="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                  <span className="d-none d-lg-inline-flex" onClick={logoutUser}>Logout</span>
                </a>

              </div>
            </div>
          </nav>
        </div>

        <div className="content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/teachers" element={<Teacher_list />} />
            <Route path="/fees_list" element={<FeesList />} />
            <Route path="/fees_add" element={<TutionAdd />} />
            <Route path="/student_list" element={<StudentList />} />
            <Route path="/student_list_all" element={<StudentListAll />} />
            <Route path="/student_add" element={<StudentAdd />} />
            <Route path="/bill_list/:id" element={<BillList />} />
            <Route path="/terms" element={<TermList />} />
            <Route path="/class" element={<ClassList />} />
            <Route path="/class_add" element={<ClassAdd />} />
            <Route path="/devision_add" element={<AddDevision />} />
            <Route path="/terms_add" element={<TermsAdd />} />
            <Route path="/add_progress" element={<AddProgress />} />
            <Route path="/subject_add" element={<SubjectAdd />} />
            <Route path="/marks" element={<Marks />} />
            <Route path="/devision" element={<Devision />} />
            <Route path="/subject" element={<SubjectList />} />
            <Route path="/year" element={<YearList />} />
            <Route path="/series" element={<SeriesSet />} />
            <Route path="/conns" element={<Connsess />} />
            <Route path="/year_add" element={<YearAdd />} />
            <Route path="/conn_add" element={<ConnessAdd />} />
            <Route path="/agent_add" element={<AddAgents />} />
            <Route path="/location_add" element={<AddLocation />} />
            <Route path="/student_edit/:id" element={<StudentEdit />} />
            <Route path="/student_view/:id" element={<StudentView />} />
            <Route path="/print_view/:id" element={<StudentPrint />} />
            <Route path="/duelist" element={<DueList />} />
            <Route path="/duelist_teach" element={<DueListtech />} />
            <Route path="/duereport" element={<DueReport />} />
            <Route path="/paidlist" element={<PaidList />} />
            <Route path="/report_all" element={<ReportAll />} />
            <Route path="fees_item" element={<FeesItem />} />
            <Route path="agent_list" element={<AgentMaster />} />
            <Route path="location_list" element={<Location />} />

            <Route path="/admin_list" element={<Admin_list />} />
            <Route path="/subadmin_list" element={<SubAdminList />} />
            <Route path="/users" element={<Users />} />
            <Route path="/add_task" element={<Tasks />} />
            <Route path="/task_view" element={<Tasksview />} />
            <Route path="/departmenttask" element={<Departmenttask />} />
            <Route path="/department" element={<Departmentview />} />
            <Route path="/department_add" element={<Department_add />} />
            <Route path="/designation_add" element={<Designation_add />} />
            <Route path="/resetpass" element={<Resetpassword />} />
            <Route path="/designation" element={<Designation />} />
            <Route path="/company_add" element={<Compny_add />} />
            <Route path="/bulk_task_week" element={<BulkTaskWeek />} />
            <Route path="/amc_dash" element={<Amc_dashboard />} />
            <Route path="/group_tasks" element={<List_group />} />
            <Route path="/group_create" element={<Group_task />} />
            <Route path="/print/:id" element={<PrintSt />} />
            <Route path="/print_all/:id" element={<PrintAll />} />
            <Route path="/company_view" element={<Company_view />} />

          </Routes>
        </div>
      </>
    );
  }
}

export default Auth;
