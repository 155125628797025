import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function StudentEdit() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [cat, setCat] = useState([]);
    const [usersjob, setUsersJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [standard, setstandard] = useState([]);
    const [devision, setDevision] = useState([]);
    const [agent, setAgent] = useState([]);
    const [location, setLocation] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: '',
        father: '',
        mother: '',
        father_occ: '',
        mother_phone: '',
        mother_occu: '',
        guardian_name: '',
        guardian_occu: '',
        guardian_relation: '',
        guardian_phone: '',
        ext_1: '',
        ext_2: '',
        ext_3: '',
        ext_4: '',
        ext_5: '',
        ext_6: '',
        ext_7: '',
        ext_8: '',
        ext_9: '',
        ext_10: '',
        ext_11: '',
        ext_12: '',
        ext_13: '',
        ext_16: '',
        ext_14: '',
        ext_15: '',
        adhar: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (e.target.name === 'category') {
            fetchUsersForDepartment(e.target.value);
        }

        if (e.target.name === 'master_id') {
            fetchMasterIndividual(e.target.value)
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`/student_marks?&id=${id}&company_id=${user.company_id}`);
                // Simulate a 20-second delay using setTimeout
                setTimeout(() => {
                    setUsersJob(response.data.data);
                    setLoading(false); // Set loading to false after setting the state
                }, 200); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false in case of an error
            }
        };

        http.get(`/class?login_user=${user.id}`).then((res) => {
            setstandard(res.data.data);
        });

        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        fetchData();

        http.get(`/agent?login_user=${user.id}`).then((res) => {
            setAgent(res.data.data);
        });

        http.get(`/location?login_user=${user.id}`).then((res) => {
            setLocation(res.data.data);
        });

    }, []);

    useEffect(() => {
        if (usersjob.length > 0) {
            setFormData({
                adm_no: usersjob[0].adm_no || '',
                assigned_by: user.id,
                adm_date: usersjob[0].adm_date || '',
                gvt_no: usersjob[0].gvt_no || '',
                class: usersjob[0].class || '',
                section: usersjob[0].section || '',
                roll_no: usersjob[0].roll_no || '',
                sibling_study: usersjob[0].sibling_study || '',
                group_name: usersjob[0].group_name || '',
                dob: usersjob[0].dob || '',
                category: usersjob[0].category || '',
                mobile: usersjob[0].mobile || '',
                cast: usersjob[0].cast || '',
                religion: usersjob[0].religion || '',
                adhar: usersjob[0].adhar || '',
                email: usersjob[0].email || '',
                club: usersjob[0].club || '',
                photo: usersjob[0].photo || '',
                father: usersjob[0].father || '',
                mother: usersjob[0].mother || '',
                father_occ: usersjob[0].father_occ || '',
                mother_phone: usersjob[0].mother_phone || '',
                mother_occu: usersjob[0].mother_occu || '',
                guardian_name: usersjob[0].guardian_name || '',
                guardian_occu: usersjob[0].guardian_occu || '',
                guardian_relation: usersjob[0].guardian_relation || '',
                guardian_phone: usersjob[0].guardian_phone || '',
                ext_1: usersjob[0].ext_1 || '',
                ext_2: usersjob[0].ext_2 || '',
                ext_3: usersjob[0].ext_3 || '',
                ext_4: usersjob[0].ext_4 || '',
                ext_5: usersjob[0].ext_5 || '',
                ext_6: usersjob[0].ext_6 || '',
                ext_7: usersjob[0].ext_7 || '',
                ext_8: usersjob[0].ext_8 || '',
                ext_9: usersjob[0].ext_9 || '',
                ext_10: usersjob[0].ext_10 || '',
                ext_11: usersjob[0].ext_11 || '',
                ext_12: usersjob[0].ext_12 || '',
                ext_13: usersjob[0].ext_13 || '',
                ext_14: usersjob[0].ext_14 || '',
                ext_16: usersjob[0].ext_16 || '',
                ext_15: usersjob[0].ext_15 || '',
                adhar: usersjob[0].adhar || '',
            });
        }
    }, [usersjob, user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const form = new FormData();

        const taskFileInput = document.querySelector('input[type="file"]');
        // if (taskFileInput.files[0])
        //     form.append("photo", taskFileInput.files[0]);


        for (const key in formData) {
            if (formData[key]) {
                form.append(key, formData[key]);
            }
        }

        try {
            await http.put(`/student_edit/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Profile data updated successfully !");
            navigate('/student_list_all');
        } catch (error) {
            console.error('Error updating student:', error.response.data);
            setIsSubmitting(false);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };
    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
    }

    return (
        <>
            <div className="container mt-2 row">
                <Toaster />
                <h3>Student Details</h3>
                <hr />
                <div className="col-md-4 mb-3">
                    {usersjob && usersjob.length > 0 && usersjob[0].photo ? (
                        <img
                            src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].photo}`}
                            width="135"
                            height="145"
                            alt="Photo"
                        />
                    ) : (
                        <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                    )}

                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row blue-input-container'>

                        <div className='col-md-4 border p-4 mx-1'>
                            <h4>Personal Details</h4>

                            <div className='form-group mt-3'>
                                <label>Admission Number</label>
                                <input
                                    name="adm_no"
                                    className='form-control'
                                    defaultValue={usersjob[0].adm_no}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Student Type</label>
                                <select className="form-control" name="ext_10" defaultValue={usersjob[0].ext_10}
                                    onChange={handleChange} required disabled={user.department_id !== 1}>
                                    <option value="">Select Any</option>
                                    <option value="New">New</option>
                                    <option value="Old">Old</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Admission Date</label>
                                <input
                                    name="adm_date"
                                    className='form-control'
                                    defaultValue={usersjob[0].adm_date}
                                    onChange={handleChange}
                                    type='date'
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>First Name</label>
                                <input
                                    name="ext_2"
                                    className='form-control'
                                    defaultValue={usersjob[0].ext_2}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Last Name</label>
                                <input
                                    name="ext_3"
                                    className='form-control'
                                    defaultValue={usersjob[0].ext_3}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Gender</label>
                                <select className="form-control" name="ext_9" defaultValue={usersjob[0].ext_9}
                                    onChange={handleChange}>
                                    <option value="Male">Male</option>
                                    <option value="Fe-Male">Fe-Male</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>STS Number</label>
                                <input
                                    name="gvt_no"
                                    className='form-control'
                                    defaultValue={usersjob[0].gvt_no}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Class</label>
                                {/* <input
                                    name="class"
                                    className='form-control'
                                    defaultValue={usersjob[0].class}
                                    onChange={handleChange}
                                /> */}
                                <select className="form-control" name="class" defaultValue={usersjob[0].class}
                                    onChange={handleChange} disabled={user.department_id !== 1} required>
                                    {standard.map((depart) => (
                                        <option key={depart.id} value={depart.class_name} >
                                            {depart.class_name}
                                        </option>

                                    ))}
                                </select>
                            </div>


                            <div className='form-group mt-3'>
                                <label>Section</label>
                                <select className="form-control" name="section" defaultValue={usersjob[0].section}
                                    onChange={handleChange}>
                                    <option value="">Select</option>
                                    {devision.map((depart) => (
                                        <option key={depart.id} value={depart.name}>
                                            {depart.name}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Roll No</label>
                                <input
                                    name="roll_no"
                                    className='form-control'
                                    defaultValue={usersjob[0].roll_no}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Agent</label>

                                <select name="ext_11"
                                    className='form-control'
                                    defaultValue={usersjob[0].ext_11}
                                    onChange={handleChange} disabled={user.department_id !== 1}
                                >
                                    <option value="">Select any</option>
                                    {agent.map((depart) => (
                                        <option key={depart.id} value={depart.name}>
                                            {depart.name}
                                        </option>
                                    ))}
                                </select>

                            </div>

                            <div className='form-group mt-3'>
                                <label>Transport</label>
                                <select className="form-control" name="ext_12" defaultValue={usersjob[0].ext_12}
                                    onChange={handleChange} disabled={user.department_id !== 1} >
                                    <option value="">Select Any</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Way</label>
                                <select className="form-control" name="ext_13" defaultValue={usersjob[0].ext_13} onChange={handleChange} disabled={user.department_id !== 1}>
                                    <option value="">Select Any</option>
                                    <option value="One-Way">One Way</option>
                                    <option value="Two-Way">Two Way</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Pickup Location</label>
                                {usersjob.length > 0 && (
                                    <select
                                        name="ext_16"
                                        className='form-control'
                                        defaultValue={usersjob[0].ext_16 || ""}
                                        onChange={handleChange} disabled={user.department_id !== 1}
                                    >
                                        <option value="">Select any</option>
                                        {location.map((depart) => (
                                            <option key={depart.id} value={depart.name}>
                                                {depart.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>


                            <div className='form-group mt-3'>
                                <label>Previous Class</label>
                                <input
                                    name="ext_14"
                                    defaultValue={usersjob[0].ext_14}
                                    className='form-control'
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Sibling Studying</label>
                                <input
                                    name="sibling_study"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].sibling_study}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Group Name</label>
                                <input
                                    name="group_name"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].group_name}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Date Of Birth</label>
                                <input
                                    name="dob"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].dob}

                                    type='date'
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Category</label>
                                <input
                                    name="category"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].category}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mobile Number</label>
                                <input
                                    name="mobile"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].mobile}
                                    required
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Caste</label>
                                <input
                                    name="cast"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].cast}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Caste Category</label>
                                <input
                                    name="ext_15"
                                    className='form-control'
                                    defaultValue={usersjob[0].ext_15}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Aadhaar No/UID</label>
                                <input
                                    name="adhar"
                                    className='form-control'
                                    defaultValue={usersjob[0].adhar}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Religion</label>
                                <input
                                    name="religion"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].religion}

                                />
                            </div>

                            {/* <div className='form-group mt-3'>
                                <label>Aadhaar No/UID</label>
                                <input
                                    name="adhar"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].adhar}

                                />
                            </div> */}

                            <div className='form-group mt-3'>
                                <label>Email</label>
                                <input
                                    name="email"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].email}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Other Remark</label>
                                <input
                                    name="club"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].club}

                                />
                            </div>

                            {/* <div className="form-group mt-3">
                                <label>Photo</label>
                                <input type="file" className="form-control" name='photo' onChange={handleChange} />
                            </div> */}

                            {/* <div className="form-group mt-3">
                                <label>Adhar</label>
                                <input type="file" name='adhar' className="form-control" onChange={handleChange} />
                            </div> */}

                            {/* <div className="form-group mt-3">
                                <label>Photo</label>
                                {usersjob[0].photo ? (
                                    <img
                                        src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].photo}`}
                                        width="135"
                                        height="145"
                                        alt="Photo"
                                    />
                                ) : (
                                    <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                )}
                            </div> */}
                        </div>

                        <div className='col-md-4 border  p-4 mx-2'>
                            <h4>Parent / Guardian's Details</h4>

                            <div className='form-group mt-3'>
                                <label>Father's Name</label>
                                <input
                                    name="father"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].father}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Father's Phone</label>
                                <input
                                    name="ext_1"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].ext_1}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Father's Occupation</label>
                                <input
                                    name="father_occ"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].father_occ}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mother's Name</label>
                                <input
                                    name="mother"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].mother}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mother's Phone</label>
                                <input
                                    name="mother_phone"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].mother_phone}

                                />
                            </div>


                            <div className='form-group mt-3'>
                                <label>Mother's Occupation</label>
                                <input
                                    name="mother_occu"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].mother_occu}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Name</label>
                                <input
                                    name="guardian_name"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].guardian_name}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Relation</label>
                                <input
                                    name="guardian_relation"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].guardian_relation}


                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Occupation</label>
                                <input
                                    name="guardian_occu"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].guardian_occu}


                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Phone</label>
                                <input
                                    name="guardian_phone"
                                    onChange={handleChange}
                                    className='form-control'
                                    defaultValue={usersjob[0].guardian_phone}


                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Address</label>
                                <textarea name="guardian_address"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].guardian_address}

                                ></textarea>
                            </div>
                            <hr />

                            <div className='border border-success p-3'>

                                <div className='form-group mt-3'>
                                    <label>Current Address</label>
                                    <textarea name="ext_4"
                                        className='form-control'
                                        onChange={handleChange}
                                        defaultValue={usersjob[0].ext_4}
                                    ></textarea>
                                </div>

                                <div className='form-group mt-3'>
                                    <label>Permanent Address</label>
                                    <textarea name="ext_5"
                                        className='form-control'
                                        onChange={handleChange}
                                        defaultValue={usersjob[0].ext_5}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 border  p-2 px-2'>
                            <h4>Miscellaneous Details</h4>

                            <div className='form-group mt-3'>
                                <label>Previous School Details</label>
                                <textarea name="pre_school"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].pre_school}

                                >

                                </textarea>
                            </div>

                            <div className='form-group mt-3'>
                                <label>TC Number</label>
                                <input
                                    name="tc_num"
                                    className='form-control'
                                    onChange={handleChange}
                                    defaultValue={usersjob[0].tc_num}

                                />
                            </div>
                        </div>

                    </div>
                    <button className="btn button btn-success mt-3" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Updating...' : 'Update'}
                    </button>
                </form>
            </div>
        </>
    )
}
