import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";


export default function BillList() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [tasksAll, setTasksAll] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [selectedStatus, setsselectedStatus] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModalAll, setShowModalall] = useState(false);
    const [showModalOnce, setShowModalOce] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editUser, setEditUser] = useState({});
    const [editUserUpdate, setEditUserUpdate] = useState({});
    const [editUserEdit, setEditUserEdit] = useState({});
    const [cat, setCat] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [tasksind, setTasksInd] = useState([]);
    const [editColumn, setEditColumn] = useState(null);
    const [users, setUsers] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [bulkData, setBulkData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalSum, setTotalSum] = useState('');
    const { id } = useParams();
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [isAmountValidPay, setIsAmountValidPay] = useState(false);
    const [isAmountValidPayEdit, setIsAmountValidPayEdit] = useState(false);
    const [isAmountValidPayNag, setIsAmountValidPayNag] = useState(false);
    const [isTrigger, setIsisTrigger] = useState(false);
    const [isTriggerEdit, setIsisTriggerEdit] = useState(false);
    const [selectedFees, setSelectedFees] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [currentDates, setCurrentDates] = useState('');
    const [annualIds, setAnnualIds] = useState([]);
    const [minDate, setMinDate] = useState('');
    const [handleDate, handleChangeDate] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`/student_marks?&id=${id}&company_id=${user.company_id}`);
                setTimeout(() => {
                    setStudents(response.data.data);
                    setLoading(false); // Set loading to false after setting the state
                }, 200); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false in case of an error
            }

            try {
                const response = await http.get(`/bulk_print?&user=${id}`);
                setTimeout(() => {
                    setTasksAll(response.data.data);
                }, 200); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error bulk print files:", error);
            }
        };
        fetchData();
        fetchTaskDetails();

        const today = new Date().toISOString().split('T')[0];
        setCurrentDate(today);
        setCurrentDates(today);
        setMinDate(today);
    }, [id]);

    //After fetch update calling this
    const fetchDataBulkAfterUpdate = async () => {
        try {
            const response = await http.get(`/bulk_print?&user=${id}`);
            setTimeout(() => {
                setTasksAll(response.data.data);
            }, 200); // 20 seconds in milliseconds
        } catch (error) {
            console.error("Error bulk print files:", error);
        }
    };


    const fetchTaskDetails = (
    ) => {
        setIsLoading(true);
        let endpoint = `/feeslist?student_id=${id}`;
        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);

                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });

    };

    const [formData2, setFormData2] = useState({
        ext_1: '',
        student_id: '',
        first_name: '',
        last_name: '',
        amount: '',
        class: '',
        dev: '',
        adm_no: '',
        type: '',
        reference: '',
        ext_4: '',
        trig_date_club: currentDates,
        user_id: user.id,
    });


    const handleSave = async (taskId) => {
        if (currentStatus === null || taskId === null) {
            toast.error("Please select proper status");
            return true;
        }
        try {
            const response = await http.put(
                `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
                    currentStatus
                )}`
            );
            toast.success("Task status updated successfully !");
            fetchTaskDetails();
        } catch (error) {
            console.error("Error fetching tasks:", error);
            toast.error("Please select proper item");
        }
    };

    const handleChangeModal = (e) => {
        setFormData1({
            ...formData1,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "category") {
            fetchUsersForDepartment(e.target.value);
        }
        if (e.target.name === "master_id") {
            fetchMasterIndividual(e.target.value);
        }

        if (e.target.name === "ext_2") {
            setIsAmountValidPay(parseFloat(e.target.value) > parseFloat(isTrigger) ? true : false);
        }

        if (e.target.name === "ext_2") {
            setIsAmountValidPayEdit(parseFloat(e.target.value) > parseFloat(isTriggerEdit) ? true : false);
        }

        if (e.target.name === "ext_2") {
            setIsAmountValidPayNag(parseFloat(e.target.value) < 0 ? true : false);
        }

    };

    const handleChangeModalOnce = (e) => {
        setFormData2({
            ...formData2,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeModalEdit = (e) => {
        setFormData4({
            ...formData4,
            [e.target.name]: e.target.value,
        });

        if (e.target.name === "ext_2") {
            setIsAmountValidPay(parseFloat(e.target.value) > parseFloat(isTrigger) ? true : false);
        }

        if (e.target.name === "ext_2") {
            setIsAmountValidPayEdit(parseFloat(e.target.value) > parseFloat(isTriggerEdit) ? true : false);
        }

        if (e.target.name === "ext_2") {
            setIsAmountValidPayNag(parseFloat(e.target.value) < 0 ? true : false);
        }

    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const [formData1, setFormData1] = useState({
        student_id: "",
        fees_id: "",
        first_name: "",
        last_name: "",
        amount: "",
        class: "",
        dev: "",
        adm_no: "",
        type: "",
        reference: "",
        amount: "",
        mode: "",
        ext_7: "",
        user_id: user.id,
        trig_date: currentDate
    });

    const [formData4, setFormData4] = useState({
        student_id: "",
        fees_id: "",
        first_name: "",
        last_name: "",
        amount: "",
        class: "",
        dev: "",
        adm_no: "",
        type: "",
        reference: "",
        amount: "",
        method: "",
        ext_7: "",
        ext_2: "",
        user_id: user.id,
        reff: "",
        date: ""
    });


    const openEditModal = async (taskAll, index) => {
        setIsisTrigger(taskAll.amount - taskAll.ext_6 || "");
        setIsisTriggerEdit(taskAll.amount || "");
        setEditColumn(index);
        setShowModal(true);
        setFormData1({
            name: taskAll.tution_fees.name || "",
            ext_1: taskAll.tution_fees.ext_2 || "",
            ext_2: taskAll.amount - taskAll.ext_6 || "",
            reff: formData1.reff || "",
            student_id: taskAll.student_id || "",
            method: taskAll.method || "",
            fees_id: taskAll.fees_id || "",
            first_name: taskAll.first_name || "",
            last_name: taskAll.last_name || "",
            class: taskAll.class || "",
            dev: taskAll.dev || "",
            adm_no: taskAll.admn_no || "",
            ext_7: taskAll.ext_3 || "",
            user_id: user.id || ""
        });
        setEditUser(taskAll);
    };

    const openEditModalUpdate = async (taskAll, index) => {
        try {
            const res = await http.get(`/is_bulk?id=${taskAll.id}`);
            const bulkData = res.data.data;
            setBulkData(bulkData);

            const annualIds = bulkData.map(data => data.annual_id);
            setAnnualIds(annualIds);
            const lastPaidFee = taskAll.related_paid_fee_all.length > 0 ? taskAll.related_paid_fee_all[taskAll.related_paid_fee_all.length - 1] : null; // Check if array is not empty

            setIsisTrigger(taskAll.amount - taskAll.ext_6 || "");
            setIsisTriggerEdit(taskAll.amount || "");
            setEditColumn(index);
            setFormData4({
                name: taskAll.tution_fees.name || "",
                ext_1: taskAll.tution_fees.ext_2 || "",
                ext_2: lastPaidFee ? lastPaidFee.amount : "",
                reff: taskAll && taskAll.related_paid_fee ? taskAll.related_paid_fee.ext_2 || "" : "",
                student_id: user.id || "",
                method: taskAll && taskAll.related_paid_fee ? taskAll.related_paid_fee.ext_1 || "" : "",
                fees_id: taskAll.fees_id || "",
                first_name: taskAll.first_name || "",
                last_name: taskAll.last_name || "",
                class: taskAll.class || "",
                dev: taskAll.dev || "",
                adm_no: taskAll.admn_no || "",
                ext_7: taskAll.ext_3 || "",
                user_id: user.id || "",
                date: taskAll && taskAll.related_paid_fee
                    ? taskAll.related_paid_fee.alt_date || taskAll.related_paid_fee.created_at || ""
                    : ""
            });

            if (bulkData && Object.keys(bulkData).length > 0) {
                setShowModalall(true);
            } else {
                setShowModalEdit(true);
            }

            setIsAmountValidPay("");
            setIsAmountValidPayNag("");
            setEditUserUpdate(taskAll);
        } catch (error) {
            console.error('Error fetching bulk data:', error);
            // Handle error appropriately
        }
    };


    const openEditModalUpdateOnce = async (taskAll, index) => {
        try {
            setEditColumn(index);
            setFormData4({
                name: taskAll.tution_fees.name || "",
                ext_1: taskAll.tution_fees.ext_2 || "",
                ext_2: taskAll.ext_6 || "",
                reff: taskAll.related_paid_fee.ext_2 || "",
                student_id: user.id || "",
                method: taskAll.related_paid_fee.ext_1 || "",
                fees_id: taskAll.fees_id || "",
                first_name: taskAll.first_name || "",
                last_name: taskAll.last_name || "",
                class: taskAll.class || "",
                dev: taskAll.dev || "",
                adm_no: taskAll.admn_no || "",
                ext_7: taskAll.ext_3 || "",
                user_id: user.id || "",

            });
            setShowModalEdit(true);
            setIsAmountValidPay("");
            setIsAmountValidPayNag("");
            setEditUserUpdate(taskAll);
        } catch (error) {
            setIsAmountValidPay("");
            setIsAmountValidPayNag("");
            console.error('Error fetching bulk data:', error);
            // Handle error appropriately
        }
    };


    const openEditModalOnce = async () => {
        setTotalSum("");
        setShowModalOce(true);
        try {
            const response = await http.get(`/get_pay_fees?&login_user=${user.id}&ids=${selectedItems}`);
            const total = response.data.data.total;
            // Check if total is retrieved correctly
            setTotalSum(total);
            setLoading(false); // Set loading to false after setting the state
        } catch (error) {
            setIsAmountValidPay("");
            setIsAmountValidPayNag("");
            console.error("Error fetching total:", error);
        }

        if (students.length > 0) {
            let totalSumAll = '';
            try {
                const response = await http.get(`/get_pay_fees?&login_user=${user.id}&ids=${selectedItems}`);
                totalSumAll = response.data.data.total;
            } catch (error) {
                console.error("Error fetching total:", error);
            }

            const student = students[0];
            const selectedItemsString = selectedItems.join(',');
            const formData = {
                ext_1: selectedItemsString,
                ext_4: student.photo,
                student_id: student.id,
                first_name: student.ext_2,
                last_name: student.ext_3,
                amount: totalSumAll,
                class: student.class,
                dev: student.section,
                adm_no: student.adm_no,
                type: formData2.type || "",
                reference: formData2.reference || "",
                trig_date_club: currentDates,
                user_id: user.id || "",
            };

            setFormData2(formData);
        }

    };


    const closeModal = () => {
        setShowModal(false);
        setShowModalall(false);
        setShowModalOce(false);
        setShowModalEdit(false);
        setEditColumn("");
    };
    const saveEditedCustemer = async (e) => {
        e.preventDefault();
        setPaymentInProgress(true);
        try {
            if (!editUser) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }
            // Make two asynchronous calls concurrently
            const [response1, response2] = await Promise.all([
                http.put(`/fees_edit_ind/${editUser.id}`, formData1),
                http.put(`/fees_add_ind/${editUser.id}`, formData1) // replace otherData with the data you want to send
            ]);

            if (response1) {
                toast.success("Fees data updated successfully!");
                setShowModal(false);
                fetchTaskDetails();
            } else {
                console.log("API response is not an array");
            }

            // You can handle response2 here if needed

        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the customer.");
        }
        setPaymentInProgress(false);
        setEditColumn("");
        setFormData1({});
        setCurrentDate("");
        fetchTaskDetails();
    };


    //Edit individual reff amount and method
    const saveEditedCustemerUpdate = async (e) => {
        e.preventDefault();
        setPaymentInProgress(true);
        try {
            if (!editUserUpdate) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }
            // Make two asynchronous calls concurrently
            const [response1, response2] = await Promise.all([
                http.post(`/fees_edit_ind_update/${editUserUpdate.id}`, formData4),
            ]);

            if (Array.isArray(response1.data.data)) {
                toast.success("Fees data updated successfully!");
                setShowModal(false);
                fetchTaskDetails();
            } else {
                console.log("API response is not an array");
            }

            // You can handle response2 here if needed

        } catch (error) {
            console.error("Error updating user", error);
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error("An error occurred while updating user");
            }
        }
        setPaymentInProgress(false);
        setEditColumn("");
        closeModal();
    };

    const saveEditedCustemerAll = async (e) => {
        e.preventDefault();
        setPaymentInProgress(true);
        try {
            if (!editUser) {
                console.error("No user data to edit.");
                return;
            }
            const response = await http.post('/fees_add_club', formData2);

            if (response) {
                toast.success("Fees data updated successfully!");
                setShowModalOce(false);
                fetchTaskDetails();
            } else {
                console.log("API response is not an array");
            }

        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the customers.");
        } finally {
            setPaymentInProgress(false);
            setEditColumn("");
            setCurrentDates("");
            fetchDataBulkAfterUpdate();
        }
    };


    const saveEditedCustemerEdit = async (e) => {

    }


    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };
    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
    }


    const handleCheckboxChange = (e, taskId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedItems([...selectedItems, taskId]);
        } else {
            setSelectedItems(selectedItems.filter(id => id !== taskId));
        }
    };
    const handleCheckboxChanges = (e, index) => {
        const isChecked = e.target.checked;
        setSelectedFees(prevSelectedFees => {
            if (isChecked) {
                return [...prevSelectedFees, index];
            } else {
                return prevSelectedFees.filter(i => i !== index);
            }
        });

    };
    const isAnyCheckboxSelected = selectedFees.length > 0;

    const handleChangeModalUpdate = (e, index) => {
        const { name, value } = e.target;
        setBulkData(prevBulkData => {
            const newFormData = [...prevBulkData];
            newFormData[index] = {
                ...newFormData[index],
                [name]: value,
            };
            return newFormData;
        });
    };

    const saveEditedCustomer = async (e) => {
        e.preventDefault();
        const dataToUpdate = bulkData.filter((_, index) => selectedFees.includes(index));
        try {
            await http.post('/update-paid-fees', { fees: dataToUpdate, alt_date: handleDate });
            toast.success("Bulk fees updated successfully!");
            window.location.reload();
        } catch (error) {
            console.error('Error updating fees:', error);
        }
    };

    const handleDelete = async (event, id) => {
        event.preventDefault();
        await http
            .delete(`/fees_del_bul/${id}`)
            .then((response) => {
                toast.success("Fees removed successfully !");
                fetchTaskDetails();
                window.location.reload();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });

    };

    const handleDeleteBulk = async (event, id) => {
        event.preventDefault();

        const userConfirmed = window.confirm("Are you sure you want to delete these fees?");

        if (userConfirmed) {
            await http
                .delete(`/fees_del_bulk/${id}`)
                .then((response) => {
                    toast.success("Fees removed successfully!");
                    setBulkData(prevBulkData => prevBulkData.filter(item => item.id !== id));
                    fetchTaskDetails();
                    window.location.reload();

                })
                .catch((err) => {
                    console.error("Error deleting task", err);
                });
        }
    };


    return (
        <>
            <Toaster />

            {/* individual first pay */}
            {showModal && (
                <form onSubmit={saveEditedCustemer}>
                    <div className="modal" tabindex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Fees
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={closeModal}
                                    ></button>
                                </div>

                                <div className="modal-body">
                                    {students.map((student) => (
                                        <div className="row mb-3 border border-success">
                                            <div className="col-md-8 p-4">
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Name:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.ext_2} {student.ext_3}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Adm-No:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.adm_no}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Standard:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.class}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Division:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.section}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {student.photo ? (
                                                    <img
                                                        src={`http://3.111.220.78/api/storage/tasks/${student.photo}`}
                                                        width="135"
                                                        height="145"
                                                        alt="Photo"
                                                    />
                                                ) : (
                                                    <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="form-group mt-3">
                                        <label>Pending Amount</label>
                                        <input type="text"
                                            name="ext_2"
                                            className="form-control"
                                            value={formData1.ext_2}
                                            onChange={handleChangeModal}
                                        />
                                    </div>
                                    {isAmountValidPay && <p className="text-danger">Amount is greater than the actual pending amount.</p>}

                                    {isAmountValidPayNag && <p className="text-danger">Amount is less than zero</p>}


                                    <div className="form-group mt-3">
                                        <label>Payment Method</label>
                                        <select className="form-control" value={formData1.method}
                                            onChange={handleChangeModal} name="method" required>
                                            <option value="">Select</option>
                                            <option value="Cash">Cash</option>
                                            <option value="UPI">UPI/Card</option>
                                            <option value="Dbank">Dbank</option>
                                            <option value="Concession">Concession</option>
                                        </select>
                                    </div>

                                    <div className="form-group mt-3"
                                        onChange={handleChangeModal} required>
                                        <label>Ref No</label>
                                        <input type="text" value={formData1.reff} name="reff" required
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group mt-3"
                                        onChange={handleChangeModal} required>
                                        <label>Date</label>
                                        <input type="date" value={currentDate} onChange={e => setCurrentDate(e.target.value)} name="trig_date"
                                            className="form-control" max={minDate}
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={paymentInProgress || isAmountValidPay || isAmountValidPayNag}>
                                        {paymentInProgress ? 'Processing...' : 'Pay'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}

            {showModalAll && (
                <form onSubmit={saveEditedCustomer}>
                    <div className="modal" tabindex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Bulk Paid Fees</h5>
                                    <button type="button" className="btn-close" onClick={closeModal}></button>
                                </div>
                                <div className="modal-body">
                                    {students.map((student) => (
                                        <div className="row mb-3 border border-success">
                                            <div className="col-md-8 p-4">
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Name:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.ext_2} {student.ext_3}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Adm-No:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.adm_no}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Standard:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.class}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Division:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.section}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {student.photo ? (
                                                    <img
                                                        src={`http://3.111.220.78/api/storage/tasks/${student.photo}`}
                                                        width="135"
                                                        height="145"
                                                        alt="Photo"
                                                    />
                                                ) : (
                                                    <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Check</th>
                                                <th scope="col">Fees Name</th>
                                                <th scope="col">Mode</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Reff</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bulkData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`fee${index}`}
                                                            name={`fee${index}`}
                                                            onChange={(e) => handleCheckboxChanges(e, index)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <label className="form-label" htmlFor={`fee${index}`}><b>{item.fees_name.name}</b></label>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                defaultValue={item.ext_1 || ''}
                                                                name="ext_1"
                                                                onChange={(e) => handleChangeModalUpdate(e, index)}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="UPI">UPI/Card</option>
                                                                <option value="Dbank">Dbank</option>
                                                                <option value="Concession">Concession</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h6
                                                        >{item.amount || ''}</h6>

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={item.ext_2 || ''}
                                                            name="ext_2"
                                                            onChange={(e) => handleChangeModalUpdate(e, index)}
                                                        />
                                                    </td>

                                                    <td width="14%">
                                                        {moment(item.alt_date).format("DD MMM,YYYY")}
                                                    </td>
                                                    <td className="trash-bulk">
                                                        {user.status === 1 && user.department_id === 1 &&
                                                            <a href="#" onClick={(e) => handleDeleteBulk(e, item.id)}>🚮</a>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                    {/* Add your validation messages here */}
                                </div>

                                <div className="modal-footer">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label for="alt_date">Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="alt_date"
                                                        onChange={(e) => handleChangeDate(e.target.value)} max={minDate}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={!isAnyCheckboxSelected}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            )
            }

            {/* //Bulk pay */}
            {
                showModalOnce && (
                    <form onSubmit={saveEditedCustemerAll}>
                        <div className="modal" tabindex="-1" style={{ display: "block" }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            Bulk Pay
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={closeModal}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {students.map((student) => (
                                            <div className="row mb-3 border border-success">
                                                <div className="col-md-8 p-4">
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0">Name:</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0 text-primary">{student.ext_2} {student.ext_3}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0">Adm-No:</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0 text-primary">{student.adm_no}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0">Standard:</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0 text-primary">{student.class}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0">Division:</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="mb-0 text-primary">{student.section}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    {student.photo ? (
                                                        <img
                                                            src={`http://3.111.220.78/api/storage/tasks/${student.photo}`}
                                                            width="135"
                                                            height="145"
                                                            alt="Photo"
                                                        />
                                                    ) : (
                                                        <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        {totalSum < 0 && <span className="badge bg-danger ms-2 mb-2">There is no due payment</span>}
                                        <div className="form-group mt-3">
                                            <label>Total Pending Amount</label>
                                            <input
                                                type="text"
                                                name="amount"
                                                className="form-control"
                                                value={totalSum >= 0 ? totalSum : 'Loading...'}
                                                onChange={handleChangeModalOnce}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group mt-3">
                                            <label>Payment Method</label>
                                            <select className="form-control" value={formData2.type}
                                                onChange={handleChangeModalOnce} name="type" required>
                                                <option value="">Select</option>
                                                <option value="Cash">Cash</option>
                                                <option value="UPI">UPI/Card</option>
                                                <option value="Dbank">Dbank</option>

                                            </select>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label>Ref No</label>
                                            <input type="text" value={formData2.reference}
                                                onChange={handleChangeModalOnce} name="reference"
                                                className="form-control" disabled={paymentInProgress || totalSum < 0}
                                                required />
                                        </div>

                                        <div className="form-group mt-3">
                                            <label>Date</label>

                                            <input type="date" value={currentDates} onChange={e => {
                                                setCurrentDates(e.target.value);
                                                handleChangeModalOnce(e);
                                            }} name="trig_date_club" className="form-control" max={minDate} />
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            //  disabled={totalSum < 0} // 
                                            disabled={paymentInProgress || totalSum <= 0} //Disable if payment in progress or totalSum is not greater than 0
                                        >
                                            {paymentInProgress ? 'Processing...' : 'Pay All'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }

            {/* for individual update modal */}
            {
                showModalEdit && (
                    <form onSubmit={saveEditedCustemerUpdate}>
                        <div className="modal" tabindex="-1" style={{ display: "block" }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            Fees
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={closeModal}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group mt-3">
                                            <label>Paid Amount</label>
                                            <input type="text"
                                                name="ext_2"
                                                className="form-control"
                                                value={formData4.ext_2}
                                                onChange={handleChangeModalEdit}
                                            />
                                        </div>
                                        {isAmountValidPayEdit && <p className="text-danger">Amount is greater than the actual pending amount.</p>}

                                        {isAmountValidPayNag && <p className="text-danger">Amount is less than zero</p>}


                                        <div className="form-group mt-3">
                                            <label>Payment Method</label>
                                            <select className="form-control" value={formData4.method}
                                                onChange={handleChangeModalEdit} name="method" required>
                                                <option value="">Select</option>
                                                <option value="Cash">Cash</option>
                                                <option value="UPI">UPI/Card</option>
                                                <option value="Dbank">Dbank</option>
                                                <option value="Concession">Concession</option>
                                            </select>
                                        </div>

                                        <div className="form-group mt-3"
                                            onChange={handleChangeModalEdit}>
                                            <label>Ref No</label>
                                            <input type="text" value={formData4.reff} name="reff"
                                                className="form-control" required
                                            />
                                        </div>

                                        <div className="form-group mt-3"
                                            onChange={handleChangeModalEdit}>
                                            <label>Date</label>
                                            <input type="date" onChange={(e) => handleChangeModalEdit(e.target.value)} max={minDate} name="date"
                                                className="form-control"
                                            />
                                            <label>Date: {moment(formData4.date).format("DD MMM, YYYY")}</label>
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                        <button type="submit" className="btn btn-primary" disabled={paymentInProgress || isAmountValidPayEdit || isAmountValidPayNag}>
                                            {paymentInProgress ? 'Processing...' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }

            {
                students.length > 0 ? (
                    <div className="container mt-1">
                        <div className="container">
                            <h3>Fees List</h3>
                            <div className="table-responsive">
                                <div className="table">
                                    {students.map((student) => (
                                        <div className="row mb-3 border border-success">
                                            <div className="col-md-8 p-4">
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Name:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.ext_2} {student.ext_3}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Adm-No:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.adm_no}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Standard:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.class}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0">Division:</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="mb-0 text-primary">{student.section}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {student.photo ? (
                                                    <img
                                                        src={`http://3.111.220.78/api/storage/tasks/${student.photo}`}
                                                        width="135"
                                                        height="145"
                                                        alt="Photo"
                                                    />
                                                ) : (
                                                    <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>


                        <div className="table-responsive">
                            {isLoading ? (
                                <TaskSkeleton />
                            ) : (
                                <table className="table table-striped center_tbl">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>Check</th>
                                            <th className="text-danger">No</th>
                                            <th>Name</th>
                                            <th>Year</th>
                                            <th>Class</th>
                                            <th>Div</th>
                                            <th>Type</th>
                                            <th>Total Amount</th>
                                            <th>Total Paid</th>
                                            <th>Due Amount</th>
                                            <th>Due Date</th>
                                            <th>Paid Date</th>
                                            <th>Action</th>

                                            {/* Add other fields as necessary */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.map((taskuser, index) => (
                                            <tr
                                                key={taskuser.id}>
                                                <td className={`${selectedItems.includes(taskuser.id) ? "bg-primary text-white" : ""} 
                                            ${taskuser.ext_9 === 1 ? "light-color-sc" : ""} 
                                            ${annualIds.includes(taskuser.id) ? "" : ""}`} style={annualIds.includes(taskuser.id) ? { backgroundColor: "gray", color: "white" } : {}}>
                                                    <input type="checkbox" onChange={(e) => handleCheckboxChange(e, taskuser.id)} disabled={taskuser.ext_9 == 1 || taskuser.ext_6 > 0} />
                                                </td>
                                                <td className={`${selectedItems.includes(taskuser.id) ? "bg-primary text-white" : ""} 
                                            ${taskuser.ext_9 === 1 ? "light-color-sc" : ""} 
                                            ${annualIds.includes(taskuser.id) ? "" : ""}`} style={annualIds.includes(taskuser.id) ? { backgroundColor: "gray", color: "white" } : {}}>{index + 1}</td>
                                                <td className={`${selectedItems.includes(taskuser.id) ? "bg-primary text-white" : ""} 
                                            ${taskuser.ext_9 === 1 ? "light-color-sc" : ""} 
                                            ${annualIds.includes(taskuser.id) ? "" : ""}`} style={annualIds.includes(taskuser.id) ? { backgroundColor: "gray", color: "white" } : {}}>{taskuser.fees_name ? taskuser.fees_name.name : ''}</td>

                                                <td>{taskuser.fees_name ? taskuser.fees_name.ext_2 : ''}</td>
                                                <td>{taskuser.class ? taskuser.class : ''}</td>
                                                <td>{taskuser.dev ? taskuser.dev : ''}</td>
                                                <td>{taskuser.std_type ? taskuser.std_type : ''}</td>
                                                <td>{taskuser.amount >= 0 ? taskuser.amount : ''}
                                                    {taskuser.amount >= 0 && taskuser.ext_6 > 0 &&
                                                        <a onClick={() => openEditModalUpdate(taskuser, index)} className="">
                                                            ✏️
                                                        </a>
                                                    }

                                                    {/* {taskuser.amount >= 0 && taskuser.ext_6 > 0 && taskuser.ext_10 == 1 &&
                                                        <a onClick={() => openEditModalUpdateOnce(taskuser, index)} className="">
                                                            ✏️
                                                        </a>
                                                    } */}

                                                    {taskuser.is_revised == 1 &&
                                                        <span className="round-icon">R</span>
                                                    }



                                                </td>
                                                <td className="text-success">
                                                    <b> {taskuser.ext_6}</b>
                                                </td>
                                                <td className="text-danger">
                                                    <b>{taskuser.amount - taskuser.ext_6}</b>
                                                </td>
                                                <td>
                                                    {taskuser.amount >= 0 ? moment(taskuser.ext_5).format("DD MMM,YYYY") : ''}

                                                </td>
                                                <td>
                                                    {taskuser.ext_6 ? (taskuser.ext_9 ? moment(taskuser.ext_9).format("DD MMM, YYYY") : moment(taskuser.updated_at).format("DD MMM, YYYY")) : null}

                                                </td>
                                                <td>
                                                    {taskuser.amount >= 0 && (
                                                        <>
                                                            <button
                                                                className="btn btn-sm btn-link action_link"
                                                                onClick={() => openEditModal(taskuser, index)}
                                                            >
                                                                Pay
                                                            </button>
                                                            {/* {!taskuser?.ext_8 && taskuser?.related_paid_fee?.id && ( */}
                                                            {taskuser?.related_paid_fee_all?.map((fee) => (
                                                                fee.fees_type === 1 ? (
                                                                    <Link
                                                                        key={fee.id}
                                                                        to={`/print/${fee.id}`}
                                                                        className="btn btn-sm btn-link action_link"
                                                                    >
                                                                        📙
                                                                    </Link>
                                                                ) : null
                                                            ))}

                                                        </>
                                                    )}
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            )}
                            <button type="submit" onClick={() => openEditModalOnce()} className="btn btn-success button test_class">
                                Pay Once
                            </button>

                            {tasksAll
                                .filter(taskuser => taskuser.ext_1) // Filter tasks where ext_1 is not empty or null
                                .map((taskuser, index) => (
                                    <row key={taskuser.id}>
                                        <Link to={`/print_all/${taskuser.id}`} className="btn btn-sm btn-link action_link">
                                            📙
                                        </Link>
                                    </row>
                                ))
                            }



                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )
            }
        </>
    );
}