import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function Customer() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(null);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    fetchTaskDetails();
  }, []);

  const fetchTaskDetails = () => {
    setIsLoading(true);
    let endpoint = `/get_customer?login_user=${user.id}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.users)) {
          setTasks(res.data.users);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/delete_customer/${taskId}`)
      .then((response) => {
        toast.success("Customer Deleted successfully !");
        fetchTaskDetails();
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    address: "",
  });

  const openEditModal = (user) => {
    setShowModal(true);
    setFormData({
      name: user.name || "",
      company: user.company || "",
      phone: user.phone || "",
      email: user.email || "",
      address: user.address || "",
    });
    setEditUser(user);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const saveEditedUser = async (e) => {
    e.preventDefault();
    try {
      if (!editUser) {
        // Handle the case when editUser is null
        console.error("No user data to edit.");
        return;
      }

      const response = await http.put(
        `/customer_edit/${editUser.id}`,
        formData
      );
      if (Array.isArray(response.data.data)) {
        toast.success("Customer data updated successfully!");
        setShowModal(false);
        fetchTaskDetails();
      } else {
        console.log("API response is not an array");
      }
    } catch (error) {
      console.error("Error updating user", error);
      toast.error("An error occurred while updating the customer.");
    }
  };

  const handleChangeModal = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {showModal && (
        <form onSubmit={saveEditedUser}>
          <div className="modal" tabindex="-1" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Customer Details</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label> Name</label>
                    <input
                      name="name"
                      required
                      className="form-control"
                      type="text"
                      value={formData.name}
                      onChange={handleChangeModal}
                    />
                  </div>
                  <div className="form-group  mt-3">
                    <label>Company</label>
                    <input
                      name="company"
                      required
                      className="form-control"
                      type="text"
                      value={formData.company}
                      onChange={handleChangeModal}
                    />
                  </div>
                  <div className="form-group  mt-3">
                    <label>Phone</label>
                    <input
                      name="phone"
                      required
                      className="form-control"
                      type="text"
                      value={formData.phone}
                      onChange={handleChangeModal}
                    />
                  </div>

                  <div className="form-group  mt-3">
                    <label>Email</label>
                    <input
                      name="phone"
                      required
                      className="form-control"
                      type="text"
                      value={formData.email}
                      onChange={handleChangeModal}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      name="address"
                      onChange={handleChangeModal}
                      value={formData.address}
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <div className="container mt-1">
        <div className="row mb-2">
          <h1 className="mb-1">Customers</h1>
          <Link className="btn-link  text-end mb-3" to="/jobcart/customeradd">
            Add Customer
          </Link>
        </div>
        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped">
              <thead className="table-primary">
                <tr>
                  <th className="text-danger">No</th>
                  <th>Created On</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Adress</th>
                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (
                  <tr key={taskuser.id}>
                    <td>{index + 1}</td>
                    <td width="15%">
                      {moment(taskuser.created_at).format("DD MMM,YY hh:mm A")}
                    </td>
                    <td>{taskuser.name}</td>
                    <td>{taskuser.company}</td>
                    <td>{taskuser.phone}</td>
                    <td>{taskuser.email}</td>
                    <td width="20%">{taskuser.address}</td>
                    <td>
                      {" "}
                      {user.department_id == 1 ? (
                        <button
                          className="btn btn-sm btn-link"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this Department?"
                              )
                            ) {
                              deleteTask(taskuser.id);
                            }
                          }}
                        >
                          Delete
                        </button>
                      ) : (
                        ""
                      )}
                      {user.department_id == 1 ? (
                        <button
                          className="btn  btn-sm btn-link"
                          onClick={() => openEditModal(taskuser)}
                        >
                          Edit
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
