import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function Branch_add() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [formData, setFormData] = useState({
        branch_name: '',    
    });

    const initialState = {
        branch_name: '',
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }
        try {
            http.post('/branch_add', form, {
                
            }).then((res) => {
                toast.success("Branch added successfully !");
                setFormData(initialState);
                navigate('/jobcart/branch');
            })

        } catch (error) {
            console.error('Error adding branches:', error.response.data);
        }
    };
  return (
   <>
    
    <div className="container mt-5">
                <Toaster />
                <h3>Add  Branches</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-3'>
                        <label> Name</label>

                        <input
                            name="branch_name"
                            className='form-control'
                            value={formData.branch_name}
                            onChange={handleChange}
                            required
                        />    
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add</button>
                </form>
            </div>
   </>
  )
}
