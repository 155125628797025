import React, { useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function Forgotpassword() {
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        http.post('/forgotpass', { email: email })
            .then((res) => {
                console.log('success', res);
                setLoading(false);
                if (res) {
                    toast.success('New password sent to your email');
                }
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false);
                if (error.response) {
                    toast.error("User not exist with this email!");
                }
            });
    };

    return (
        <div>
            <div className="row justify-content-center pt-5">
                <div className="col-sm-6">
                    <div className="card p-4">
                        <Toaster />
                        <h1 className="text-center mb-3">Forgot Password</h1>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group mt-3'>
                                <label>Enter your email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={e => setEmail(e.target.value)}
                                    id="email"
                                    required
                                />
                            </div>
                            <button
                                className='btn button btn-success mt-3'
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
