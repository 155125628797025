import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function Category_add() {
    const { http, user } = AuthUser();
    const [formData, setFormData] = useState({
        name: '',
       
    });
    const navigate = useNavigate();
    const initialState = {
        name: '',
      
    }
    
    const [users, setUsers] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const handleChange = (e) => {
        setFormData({
            login_user: user.id,
            ...formData,
            [e.target.name]: e.target.value
        });
       
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
           
            const res = await http.post('/category_add', formData);
            setFormData(initialState);
            setTimeout(() => {
                navigate('/jobcart/category');
            }, 1000); // 10 seconds
            toast.success("category added successfully !");
        } catch (error) {
            // Here we handle the error
            if (error.response && error.response.data) {
                // If there's a detailed error message from the server, use that
                toast.error(error.response.data.message || 'Make sure all fields are filled!');
            } else {
                // If there's no detailed error message, use a generic one
                toast.error('Make sure all fields are filled!');
            }
            console.error('Error adding task:', error);
        }
    };

    return (
        <>

            <div className="container mt-5">
                <Toaster />
                <h3>Add Category</h3>
                <form onSubmit={handleSubmit}>

                    <div className='form-group mt-3'>
                        <label>Name</label>
                        <input type='text'
                            name="name"
                            className='form-control'
                            onChange={handleChange} required
                        ></input>
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add Category</button>
                </form>
            </div>
        </>
    )
}
