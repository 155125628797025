import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Tasks() {
  const navigate = useNavigate();
  const { http, user } = AuthUser();
  const [formData, setFormData] = useState({
    description: "",
    department_id: "",
    target_date: "",
    additional_info: "",
    task_weightage: "3",
    priority: "Medium",
    assigned_by: user.id,
    assigned_to: "",
    task_file: "",
    est_time: "1",
  });

  const initialState = {
    description: "",
    department_id: "",
    target_date: "",
    additional_info: "",
    task_weightage: "3",
    priority: "Medium",
    assigned_by: user.id,
    assigned_to: "",
    task_file: "",
    est_time: "1",
  };

  const [departmetsall, setdepartmentsall] = useState([]);
  const [department, setDepartment] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    http.get(`/departments?login_user=${user.id}`).then((res) => {
      setdepartmentsall(res.data.data);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "department_id") {
      fetchUsersForDepartment(e.target.value);
    }
  };

  const fetchUsersForDepartment = async (departmentId) => {
    try {
      const response = await http.get(
        `/department_user?department=${departmentId}&login_user=${user.id}`
      );
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }

    const taskFileInput = document.querySelector('input[type="file"]');
    if (taskFileInput.files[0])
      form.append("task_file", taskFileInput.files[0]);

    try {
      http
        .post("/tasks", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success("Task added successfully !");
          setFormData(initialState);
          navigate("/dashboard");
        });
    } catch (error) {
      console.error("Error adding task:", error.response.data);
    }
  };

  return (
    <>
      <div className="container mt-5">
        <Toaster />
        <h1>Add Task</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>Department</label>

            <select
              name="department_id"
              className="form-control"
              value={formData.department_id}
              onChange={handleChange}
              required
            >
              <option value="">Select Department</option>
              {departmetsall.map((depart) => (
                <option key={depart.id} value={depart.id}>
                  {depart.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group  mt-3">
            <label>Assigned To</label>
            <select
              disabled={!users.length}
              name="assigned_to"
              className="form-control text-uppercase"
              value={formData.assigned_to}
              onChange={handleChange}
              required
            >
              <option value="">Select a user</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name} {user.last_name}
                </option>
              ))}
            </select>
          </div>

          {user.company_id == 1 && (
            <div className="form-group  mt-2">
              <label>Establish Time</label>
              <select
                name="est_time"
                onChange={handleChange}
                className="form-control"
                value={formData.est_time}
                required
              >
                <option value="0">0 Hour</option>
                <option value="1">1 Hour</option>
                <option value="2">2 Hour</option>
                <option value="3">3 Hour</option>
                <option value="4">4 Hour-Half day</option>
                <option value="5">5 Hour</option>
                <option value="6">6 Hour</option>
                <option value="7">7 Hour</option>
                <option value="8">8 Hour</option>
              </select>
            </div>
          )}

          <div className="form-group mt-3">
            <label>Description</label>
            <textarea
              name="description"
              className="form-control"
              value={formData.description}
              onChange={handleChange}
              rows="2"
            />
          </div>

          <div className="form-group  mt-3">
            <label>Target Date</label>
            <input
              name="target_date"
              className="form-control"
              type="date"
              value={formData.target_date}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Additional Info</label>
            <textarea
              name="additional_info"
              className="form-control"
              value={formData.additional_info}
              onChange={handleChange}
              rows="2"
            />
          </div>

          <div className="form-group  mt-3">
            <label>Task Weightage</label>
            <select
              name="task_weightage"
              className="form-control"
              value={formData.task_weightage}
              onChange={handleChange}
              required
            >
              <option value="">Select a weightage</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <div className="form-group  mt-3">
            <label>Priority</label>
            <select
              name="priority"
              className="form-control"
              value={formData.priority}
              onChange={handleChange}
              required
            >
              <option value="">Select a Priority</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>

          <div className="form-group mt-3">
            <label>Task Files</label>
            <input type="file" className="form-control" />
          </div>
          <button className="btn button btn-success mt-3" type="submit">
            Add Task
          </button>
        </form>
      </div>
    </>
  );
}
