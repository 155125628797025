import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import Select from "react-select";
import { debounce } from "lodash";
import ReactPaginate from 'react-paginate';


export default function PaidList() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [devision, setDevision] = useState([]);
    const [subject, setSubjects] = useState([]);
    const [year, setYear] = useState([]);
    const [term, setTerm] = useState([]);
    const [fees, setFeesList] = useState([]);
    const [standard, setstandard] = useState([]);
    const [selclass, setselclass] = useState("");
    const [allStudent, setAllStudent] = useState([]);
    const [selectedStud, setSelectedStudent] = useState("");
    const [selectedClassStud, setselectedClassStud] = useState("");
    const [selectedDevStud, setselectedDevStud] = useState("");
    const [selectedFeesStud, setselectedFeesStud] = useState("");
    const [amount, setAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [stClass, setStClass] = useState([]);
    const [stDev, setstDev] = useState([]);
    const [stAdmin, setstAdmin] = useState([]);
    const [selectedDevStudType, setselectedDevStudType] = useState("");
    const [selectedDAdmin, setselectedAdmin] = useState("");
    const [selectedFrom, setselectedFrom] = useState(moment().format("YYYY-MM-DD HH:mm:ss")); // Default to today's date
    const [selectedTo, setselectedTo] = useState(moment().format("YYYY-MM-DD HH:mm:ss")); // Default to today's date
    const [stYear, setstYear] = useState([]);
    const [selectedDevYear, setselectedDevYear] = useState("");
    const [loading, setLoading] = useState(false);


    const [currentPage, setCurrentPage] = useState(0);
    const [totalTasks, setTotalTasks] = useState("");
    const [tasksPerPage] = useState(100);  // Assuming 10 tasks per page

    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1; // ReactPaginate uses zero-based index
        if (selectedPage !== currentPage) {
            setCurrentPage(selectedPage);
            fetchTaskDetails(selectedFrom, selectedTo, selectedClassStud, selectedDevStud, selectedDevStudType, selectedDAdmin, selectedPage);
        }
    };

    useEffect(() => {

        http.get(`/class`).then((res) => {
            setStClass(res.data.data);
        });

        http.get(`/devision`).then((res) => {
            setstDev(res.data.data);
        });

        http.get(`/admins?login_user=${user.id}`).then((res) => {
            setstAdmin(res.data.users);
        });

        http.get(`/year`).then((res) => {
            setstYear(res.data.data);
        });

        fetchTaskDetails(selectedFrom, selectedTo, selectedClassStud, selectedDevStud, selectedDevStudType, selectedDAdmin, currentPage + 1);



        // http.get(`/student_all?company_id=${user.company_id}`).then((res) => {
        //     setAllStudent(res.data.data);
        // });

        // Fetch divisions
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        http.get(`/terms?login_user=${user.id}`).then((res) => {
            setTerm(res.data.data);
        });

        http.get(`/class?login_user=${user.id}`).then((res) => {
            setstandard(res.data.data);
        });

        //year
        http.get(`/year?login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });

        // http.get(`/tution_feeslist?login_user=${user.id}`).then((res) => {
        //     setFeesList(res.data.data);
        // });

    }, [selectedFrom, selectedTo, selectedClassStud, selectedDevStud, selectedDevStudType, selectedDAdmin, currentPage
    ]);

    const fetchTaskDetails = (fromDate, toDate, selClass, selDev, typePays, byAdmin, currentPage = 1) => {
        setIsLoading(true);
        let endpoint = `/student_annual_fee_sub?date_from=${fromDate}&date_to=${toDate}&class=${selClass}&dev=${selDev}&type=${typePays}&added=${byAdmin}&page=${currentPage}&per_page=${tasksPerPage}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data); // Set the tasks for the current page
                    setTotalTasks(res.data.total); // Set the total number of tasks
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };


    const handleSelectChangeClass = (event) => {
        setselectedClassStud(event.target.value);
    };

    const handleSelectChangeDev = (event) => {
        setselectedDevStud(event.target.value);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedStudent(selectedOption);
    };

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const initialState = {
        name: "",
    };

    const deleteTask = async (taskId) => {
        await http
            .delete(`/departments_del/${taskId}`)
            .then((response) => {
                toast.success("Departments Deleted successfully !");
                fetchTaskDetails();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };

    const options = allStudent.map((user) => ({
        value: user.id,
        label: `${user.adm_no} - ${user.ext_2}${user.ext_3} - ${user.class}${user.section}  - ${user.mobile}`,
    }));

    const handleClearFields = () => {
        window.location.reload();
    };

    const handleEmailSent = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await http.get(`/student_annual_fee_sub_mail`);
            toast.success("Email sent successfully!");
            window.location.reload();
        } catch (err) {
            console.error("Error sending mail", err);
            toast.error("Failed to send email.");
        } finally {
            setLoading(false); // Reset loading state
        }
    }

    const handleEmailSentExport = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await http.get(`/student_annual_fee_sub_mail_export`, {
                responseType: 'blob', // Important to handle the file download
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'fees.csv'); // or any other filename you want
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success("Exported successfully!");
        } catch (err) {
            console.error("Error in export", err);
            toast.error("Failed to export.");
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    //for filter export
    const handleEmailSentExportFilter = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await http.get(`/student_annual_fee_sub_mail_export_filter?date_from=${selectedFrom}&date_to=${selectedTo}`, {
                responseType: 'blob', // Important to handle the file download
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'fees.csv'); // or any other filename you want
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast.success("Exported successfully!");
        } catch (err) {
            console.error("Error in export", err);
            toast.error("Failed to export.");
        } finally {
            setLoading(false); // Reset loading state
        }
    };


    const handleSubmit = async () => {
        if (tasks.length === 0 || !selectedFeesStud) {
            toast.error("Please select a student and fees type before submitting.");
            return true;
        }

        if (tasks.length === 0 || !selectedFeesStud || !amount || !dueDate) {
            toast.error("Please enter due date and amount");
            return;
        }


        try {
            const promises = [];

            for (let i = 0; i < tasks.length; i++) {
                const task = tasks[i];

                const formData = {
                    admn_no: task.adm_no,
                    ext_1: task.roll_no,
                    first_name: task.ext_2,
                    last_name: task.ext_3,
                    class: task.class,
                    dev: task.section,
                    fees_id: selectedFeesStud,
                    student_id: task.id,
                    amount: amount,
                    ext_5: dueDate,
                };

                // Push the promise for each API call to the array
                promises.push(http.post('/annual_fees', formData));
            }

            // Wait for all promises to be resolved
            await Promise.all(promises);

            // If all promises are resolved, show success toast
            toast.success("Fees data updated successfully");
            fetchTaskDetails(selectedStud, selectedClassStud, selectedDevStud, selectedFeesStud);

            // handleClearFields();
        } catch (error) {
            // Handle errors
            console.error("Error submitting tasks:", error);
            // toast.error("Error submitting tasks. Please try again.");
        }
    };
    function handleSelectChangeFees(event) {
        setselectedFeesStud(event.target.value);
    }

    function handleChangeAmount(event) {
        setAmount(event.target.value)
    }

    function handleChangeDate(event) {
        setselectedFrom(event.target.value)
    }

    function handleChangeDateEnd(event) {
        setselectedTo(event.target.value)
    }

    const calculateDue = (amount, ext_6) => {
        const due = amount - ext_6;
        return due < 0 ? "No due" : due;
    };
    const cashSum = tasks.reduce((total, task) => task.ext_1 === "Cash" ? total + parseFloat(task.amount) : total, 0);
    const upiSum = tasks.reduce((total, task) => task.ext_1 === "UPI" ? total + parseFloat(task.amount) : total, 0);
    const DbankSum = tasks.reduce((total, task) => task.ext_1 === "Dbank" ? total + parseFloat(task.amount) : total, 0);
    const totalSum = tasks.reduce((total, task) => total + parseFloat(task.amount), 0);
    //  const totalSumCon = tasks.reduce((total, task) => total + parseFloat(task.amount), 0);

    const totalNegativeSum = tasks.reduce((total, task) => {
        const amount = parseFloat(task.amount);
        return task.ext_1 === "Concession" ? total + amount : total;
    }, 0);

    const handleSelectChangeDevType = (event) => {
        setselectedDevStudType(event.target.value);
    };

    const handleSelectChangeAdmin = (event) => {
        setselectedAdmin(event.target.value);
    };




    const formatLastName = (lastName) => {
        if (!lastName) {
            return ''; // or handle it as you see fit
        }
        return lastName.length > 13 ? lastName.substring(0, 13) + '...' : lastName;
    };
    //Real time year changing
    const handleSelectChangeYear = (event) => {
        setselectedDevYear(event.target.value);
        console.log(selectedDevYear);
        http.get(`/tution_feeslists?login_user=${user.id}&year=${event.target.value}`)
            .then((res) => {
                setFeesList(res.data.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Toaster />
            <div className="container mt-2">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Student Pay List</h3>
                    </div>
                    <div className="col-md-2 form-group">
                        <input type="date" name="due_date_start" className="form-control border border-success border-2" placeholder="Due Date" onChange={handleChangeDate}></input>
                    </div>

                    <div className="col-md-2 form-group">
                        <input type="date" name="due_date_end" className="form-control border border-success border-2" placeholder="Due Date" onChange={handleChangeDateEnd}></input>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select onChange={handleSelectChangeClass} className="form-control">
                            <option value="">Select Class</option>
                            {stClass.map((option, index) => (
                                <option key={index} value={option.class_name}>
                                    {option.class_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-1 form-group text-end">
                        <select onChange={handleSelectChangeDev} className="form-control">
                            <option value="">Select Dev</option>
                            {stDev.map((options, index) => (
                                <option key={index} value={options.name}>
                                    {options.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-1  text-end">
                        <select onChange={handleSelectChangeYear} className="form-control">
                            <option value="">Select Year</option>
                            {stYear.map((options, index) => (
                                <option key={index} value={options.name}>
                                    {options.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    {<div className="col-md-2  text-end">
                        <select onChange={handleSelectChangeDevType} className="form-control">
                            {loading ? (
                                <option>Loading...</option>
                            ) : (
                                <>
                                    <option value="">Fees Type</option>
                                    {fees.map((options, index) => (
                                        <option key={index} value={options.id}>
                                            {options.name}
                                        </option>
                                    ))}
                                </>
                            )}
                        </select>
                    </div>}

                    <div className="col-md-2 form-group text-end">
                        <select onChange={handleSelectChangeAdmin} className="form-control">
                            <option value="">Added By</option>
                            {stAdmin.map((options, index) => (
                                <option key={index} value={options.id}>
                                    {options.name} { } {options.last_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-1 form-group">
                        <Link to="/fees_item" className="btn bt-sm btn-success" onClick={handleClearFields}>
                            Clear
                        </Link>
                    </div>

                    <div className="col-md-2 form-group mt-2">
                        <Link to="#" className="btn bt-sm btn-success" onClick={handleEmailSent}>
                            {loading ? 'Sending...' : 'Send Email Report'}
                        </Link>
                    </div>

                    <div className="col-md-4 form-group mt-2">
                        <Link to="#" className="btn bt-sm btn-success me-2" onClick={handleEmailSentExport}>
                            {'Export'}
                        </Link>

                        <Link to="#" className="btn bt-sm btn-success ms-3" onClick={handleEmailSentExportFilter}>
                            {'Filter Export'}
                        </Link>
                    </div>

                    <div className="col-md-2 form-group mt-2">

                    </div>

                </div>
                <hr />
                <div className="row">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped center_tbl mt-2">
                            <thead className="table-primary">

                                <tr>
                                    <th className="text-danger">No</th>
                                    <th width="15%">Paid On</th>
                                    <th width="15%">Adm No</th>
                                    <th width="22%">Student Name</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th width="20%">Fee Type</th>
                                    <th width="">Invoice</th>
                                    <th width="">CInvoice</th>
                                    <th>Concession</th>
                                    <th>Cash </th>
                                    <th>GPA/UPI </th>
                                    <th>Dbank </th>
                                    <th>Total</th>
                                    <th width="10%">Add By</th>


                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>  (<b>{totalNegativeSum.toFixed(2)}</b>)</th>
                                    <th> (<b>{cashSum.toFixed(2)}</b>)</th>
                                    <th> (<b>{upiSum.toFixed(2)}</b>)</th>
                                    <th> (<b>{DbankSum.toFixed(2)}</b>)</th>
                                    <th> (<b>{totalSum.toFixed(2)}</b>)</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            {task.alt_date ? moment(task.alt_date).format("DD MMM,YYYY") : moment(task.created_at).format("DD MMM,YYYY hh:mm A")}
                                        </td>

                                        <td>{task.adm_no}</td>
                                        <td>{task.first_name} {formatLastName(task.last_name)}</td>
                                        <td>{task.class}</td>
                                        <td>{task.dev}</td>
                                        <td>{task.fees_name ? task.fees_name.name : 'N/A'}</td>
                                        <td>
                                            {task.invoice ? (
                                                <Link to={`/print/${task.invoice.paid_id}`}>
                                                    {task.invoice.invoice_id}
                                                </Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>

                                        <td>
                                            {task.cinvoice ? (
                                                <Link to={`/print_all/${task.cinvoice.club_id}`}>
                                                    {task.cinvoice.invoice_id}
                                                </Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>

                                        <td> {(
                                            <b><span style={{ color: 'red' }}>{task.ext_1 === "Concession" && task.amount}</span></b>
                                        )}</td>
                                        <td>{task.ext_1 === "Cash" && task.amount}</td>
                                        <td>{task.ext_1 === "UPI" && task.amount}</td>
                                        <td>{task.ext_1 === "Dbank" && task.amount}</td>
                                        <td><b>{task.amount}</b></td>

                                        <td>{task.user_name ? task.user_name.name.toUpperCase() : 'N/A'}</td>



                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </div>

                <div className="pagination-controls">
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(totalTasks / tasksPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>


            </div >
        </>
    );
}
