import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export default function Dashboard() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [TaskCount, setTaskCount] = useState();
  const [date, setDate] = useState(null);
  const [remark, setRemark] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [loginUserId, setloginUserId] = useState(user.id);
  const [selectedDepartmentId, setselectedDepartmentId] = useState("");
  const [selectedUser, setselectedUser] = useState("");
  const [selectedStatus, setsselectedStatus] = useState("Pending");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dueamt, setDue] = useState("");
  const [userss, setUserss] = useState([]);

  useEffect(() => {
    http.get(`/department_user_company_baseuser?dept=${selectedDepartmentId}&login_user=${user.id}`).then((res) => {
      setUserss(res.data.users);
      // try {
      //   setTimeout(() => {
      //     http.get(`/due`).then((res) => {
      //       setDue(res.data.data.total_due);
      //     });
      //   }, 200); // 20 seconds in milliseconds
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    });

    fetchTaskDetails(
      selectedDepartmentId,
      selectedStatus,
      startDate,
      endDate,
      selectedUser
    );
  }, [selectedDepartmentId, selectedStatus, startDate, endDate, selectedUser]);



  const fetchTaskDetails = (
    seldepartment = "",
    selstatus = "",
    startDate = "",
    endDate = "",
    seluser = ""
  ) => {
    setIsLoading(true);
    let endpoint;
    if (user && user.department_id === 1) {
      endpoint = `/tasks?department=${seldepartment}&status=${selstatus}&login_user=${user.id}&startdate=${startDate}&enddate=${endDate}&seluser=${seluser}`;
    } else if (user) {
      endpoint = `/tasks?user=${user.id}&department=${seldepartment}&status=${selstatus}&login_user=${user.id}&startdate=${startDate}&enddate=${endDate}&seluser=${seluser}`;
    } else {
      setIsLoading(false);
      console.error("User is not defined or authenticated");
      return;
    }

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
          setTaskCount(res.data.data.length);
        } else {
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });

    http.get(`/departments?login_user=${user.id}`).then((res) => {
      setdepartments(res.data.data);
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalRevise, setShowModalRevise] = useState(false);
  const [ModalId, setModalId] = useState(false);
  const openModal = (taskId) => {
    setShowModal(true);
    setModalId(taskId);
  };

  const openModalRevise = (rmark, revisedate) => {
    setDate(revisedate);
    setRemark(rmark);
    setShowModalRevise(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalRevise(false);
  };
  const handleChange = async (event) => {
    setselectedDepartmentId(event.target.value);
  };

  const handleChangeUser = async (event) => {
    setselectedUser(event.target.value);
  };

  const handleChangeType = async (event) => {
    setsselectedStatus(event.target.value);
  };

  const handleChangeStartDate = async (event) => {
    setstartDate(event.target.value);
  };

  const handleChangeEndDate = async (event) => {
    setendDate(event.target.value);
  };

  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/tasks/${taskId}`)
      .then((response) => {
        toast.success("Task Deleted successfully !");
        // setTasks(response.data.data);
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Task status updated successfully !");
      fetchTaskDetails(selectedDepartmentId, selectedStatus);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const [formData, setFormData] = useState({
    revised_date: "",
    revised_remark: "",
  });
  const initialState = {
    revised_date: "",
    revised_remark: "",
  };

  const handleChangeModal = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.revised_remark || !formData.revised_date) {
      toast.error("Please fill in both Revised Remark and New Revision Date.");
      return;
    }
    try {
      http.put(`/revise/${ModalId}`, formData).then((res) => {
        toast.success("revise date added successfully !");
        setFormData(initialState);
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
        closeModal();
      });
    } catch (error) {
      console.error("Error adding task:", error.response.data);
    }
  };

  const downloadFile = (filename) => {
    // Construct the URL to the file.
    const fileUrl = `http://3.6.125.26/api/storage/tasks/${filename}`;

    // Create a link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", ""); // the download attribute can be empty
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link from the DOM
  };
  function getRandomLightColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const backgroundColorMap = new Map();

  // Function to get a unique background color for a user.id
  function getUniqueBackgroundColor(userId) {
    if (!backgroundColorMap.has(userId)) {
      // If the user.id doesn't have a background color assigned, generate one and store it in the map
      backgroundColorMap.set(userId, getRandomLightColor());
    }
    return backgroundColorMap.get(userId);
  }


  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-3">
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i className="fa fa-chart-line fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Total Dues <b></b></p>
                <h6 className="mb-0"></h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i className="fa fa-chart-bar fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Total Teachers</p>
                <h6 className="mb-0"></h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i className="fa fa-chart-area fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Admins</p>
                <h6 className="mb-0"></h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
              <i className="fa fa-chart-pie fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Others</p>
                <h6 className="mb-0"></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
