import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "../components/home";
import Login from "../components/login";
import Register from "../components/register";
import Forgotpassword from "../components/forgotpassword";
import RegisterJob from "../components/register_job";
import LoginJob from "../components/login_job";
import { useEffect, useState } from "react";

function Guest() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ticketCondi, setticketCondi] = useState(false);

  const isJobCartPage = location.pathname.startsWith("/jobcart");
  const shouldShowNav = !location.pathname.includes("/jobcart/jc-amc");

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/jobcart/tickets") {
      setticketCondi(true);
    }
  }, []);

  if (isJobCartPage) {
    // Render JSX specific to the /jobcart page
    return (
      <>
        <nav className="navbar navbar-expand-sm navbar-dark bg-gray">
          <div className="container-fluid">
            {/* Logo */}
            {!ticketCondi ? (
              <Link className="navbar-brand" to="/login">
                <img
                  src="/logo.png"
                  alt="Company Logo"
                  width="80"
                  height="39"
                />
              </Link>
            ) : (
              <img src="/logo.png" alt="Company Logo" width="80" height="39" />
            )}

            {/* Toggler for responsive behavior */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {shouldShowNav && (
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  {!ticketCondi && (
                    <li className="nav-item">
                      <Link className="nav-link text-white" to="/jobcart">
                        JC-Login
                      </Link>
                    </li>
                  )}
                  {/* <li className="nav-item">
                                <Link className="nav-link text-white" to="/jobcart/jc-register">JC-Register</Link>
                            </li> */}
                </ul>
              </div>
            )}
          </div>
        </nav>

        <div className="container">
          <Routes>
            <Route path="/jobcart" element={<LoginJob />} />
            <Route path="/jobcart/login" element={<LoginJob />} />
            <Route path="/jobcart/jc-register" element={<RegisterJob />} />

          </Routes>
        </div>
      </>
    );
  }
  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark bg-secondary">
        <div className="container-fluid">
          {/* Logo */}
          <Link className="navbar-brand" to="/login">
            {/* <img src="/logo.png" alt="Company Logo" width="80" height="39" /> */}
          </Link>

          {/* Toggler for responsive behavior */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Main navbar content */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/login">
                  Login
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/register">
                  Register
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register_job" element={<RegisterJob />} />
          <Route path="/forgot" element={<Forgotpassword />} />
        </Routes>
      </div>
    </>
  );
}

export default Guest;
