import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function Marks() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [remark, setRemark] = useState(null);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [editColumn, setEditColumn] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editUser, setEditUser] = useState({});
    const [devision, setDevision] = useState([]);
    const [subject, setSubjects] = useState([]);
    const [year, setYear] = useState([]);
    const [term, setTerm] = useState([]);
    const [standard, setstandard] = useState([]);
    const [selclass, setselclass] = useState("");
    const [seldev, setSeldev] = useState("");
    useEffect(() => {
        http.get(`/subject?login_user=${user.id}`).then((res) => {
            setSubjects(res.data.data);
        });

        // Fetch divisions
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        http.get(`/terms?login_user=${user.id}`).then((res) => {
            setTerm(res.data.data);
        });

        http.get(`/class?login_user=${user.id}`).then((res) => {
            setstandard(res.data.data);
        });

        //year
        http.get(`/year?login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });
        fetchTaskDetails(selclass, seldev);
    }, [selclass, seldev]);


    const handleSelectChangeClass = (event) => {
        setselclass(event.target.value);
    };

    const handleSelectChangeDev = (event) => {
        setSeldev(event.target.value);
    };



    const fetchTaskDetails = (selclassVar, seldevVar) => {
        setIsLoading(true);
        let endpoint = `/student_marks?login_user=${user.id}&class=${selclassVar}&devision=${seldevVar}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };

    console.log(tasks);

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const saveEditedCustemer = async (e) => {
        e.preventDefault();
        try {
            if (!editUser) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }

            const response = await http.put(`/dept_edit/${editUser.id}`, formData1);
            if (Array.isArray(response.data.data)) {
                toast.success("Department updated successfully!");
                setShowModal(false);
                fetchTaskDetails();
            } else {
                console.log("API response is not an array");
            }
        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the customer.");
        }
        setEditColumn("");
    };

    const handleSave = async (taskId) => {
        toast.success("Progress data added successfully !");
        // if (currentStatus === null || taskId === null) {
        //     toast.error("Please select proper status");
        //     return true;
        // }
        // try {
        //     const response = await http.put(
        //         `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
        //             currentStatus
        //         )}`
        //     );
        //     toast.success("Task status updated successfully !");
        //     fetchTaskDetails();
        // } catch (error) {
        //     console.error("Error fetching tasks:", error);
        //     toast.error("Please select proper item");
        // }
    };

    const initialState = {
        name: "",
    };

    const handleChangeModal = (e) => {
        setFormData1({
            ...formData1,
            [e.target.name]: e.target.value,
        });
    };

    const deleteTask = async (taskId) => {
        console.log(taskId);
        await http
            .delete(`/departments_del/${taskId}`)
            .then((response) => {
                toast.success("Departments Deleted successfully !");
                fetchTaskDetails();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };

    const openEditModal = async (taskAll) => {
        setShowModal(true);
        setFormData1({
            name: taskAll.name || "",
        });
        setEditUser(taskAll);
    };

    const [formData1, setFormData1] = useState({
        name: "",
    });

    const closeModal = () => {
        setShowModal(false);
        setEditColumn("");
    };

    return (
        <>
            <Toaster />
            {showModal && (
                <form onSubmit={saveEditedCustemer}>
                    <div className="modal" tabindex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Department</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={closeModal}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group  mt-3">
                                        <label> Name </label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            value={formData1.name}
                                            onChange={handleChangeModal}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}

            <div className="container mt-2">
                <div className="row">
                    <div className="col-md-3 form-group text-end">
                        <select className="form-control mb-2" value={selclass} onChange={handleSelectChangeClass}>
                            <option>Select Class</option>
                            {standard.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.class_name}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-md-3 form-group text-end">
                        <select className="form-control mb-2" value={seldev} onChange={handleSelectChangeDev}>
                            <option>Select Division</option>
                            {devision.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-md-3 form-group text-end">
                        <select className="form-control mb-2">
                            <option>Select Subject</option>
                            {subject.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-3 form-group text-end">
                        <select className="form-control mb-2">
                            <option>Select Year</option>
                            {year.map((depart) => (
                                <option key={depart.id} value={depart.name}>
                                    {depart.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="table-responsive">
                    {isLoading ? (
                        // <p>Loading------------------</p>
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Name</th>
                                    <th>Division</th>
                                    <th>Roll No</th>
                                    <th>Subject</th>
                                    <th>Marks</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr key={taskuser.id}>
                                        <td>{index + 1}</td>
                                        <td><b>{taskuser.ext_1} {taskuser.ext_2}</b></td>
                                        <td>{taskuser.section}</td>
                                        <td>{taskuser.roll_no}</td>
                                        <td></td>
                                        <td width="29%"><input type="text" className="form-control"></input></td>
                                        <td>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="absentCheckbox" />
                                                <label className="form-check-label" htmlFor="absentCheckbox">Absent</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="medicalCheckbox" />
                                                <label className="form-check-label" htmlFor="medicalCheckbox">Medical</label>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                                <div className="col-md-12 form-group text-end mt-2">
                                    <select className="form-control mb-2">
                                        <option>Select Terms</option>
                                        {term.map((depart) => (
                                            <option key={depart.id} value={depart.name}>
                                                {depart.name}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                                <button className="btn btn-success mt-3" onClick={handleSave}>Save</button>
                            </tbody>
                        </table>

                    )}
                </div>
            </div >
        </>
    );
}
