import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Dashboard_job() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [tasksind, setTasksInd] = useState([]);
  const [TaskCount, setTaskCount] = useState();
  const [TotalCount, setTotalCount] = useState();
  const [date, setDate] = useState(null);
  const [remark, setRemark] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [loginUserId, setloginUserId] = useState(user.id);
  const [selectedDepartmentId, setselectedDepartmentId] = useState("");
  const [selectedStatus, setsselectedStatus] = useState("Pending");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [editColumn, setEditColumn] = useState(null);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
      setUsers(res.data.users);
    });
    fetchTaskDetails(selectedDepartmentId, selectedStatus, startDate, endDate);
  }, [selectedDepartmentId, selectedStatus, startDate, endDate]);

  const fetchTaskDetails = (
    seldepartment = "",
    selstatus = "",
    startDate = "",
    endDate = ""
  ) => {
    setIsLoading(true);
    let endpoint;
    if (user && user.department_id === 1) {
      endpoint = `/tasks_job?department=${seldepartment}&status=${selstatus}&login_user=${user.id}&startdate=${startDate}&enddate=${endDate}`;
    } else if (user) {
      endpoint = `/tasks_job?user=${user.id}&department=${seldepartment}&status=${selstatus}&login_user=${user.id}&startdate=${startDate}&enddate=${endDate}`;
    } else {
      setIsLoading(false);
      console.error("User is not defined or authenticated");
      return;
    }

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
          setTaskCount(res.data.data.length);
          setTotalCount(res.data.total_count);
        } else {
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });

    http.get(`/branch`).then((res) => {
      setdepartments(res.data.users);
    });
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModalRevise, setShowModalRevise] = useState(false);
  const openModal = async (taskId) => {
    setShowModal(true);
    await http
      .get(`/tasks_job_ind?id=${taskId}`)
      .then((res) => {
        setTasksInd(res.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  const openModalRevise = (rmark, revisedate) => {
    setDate(revisedate);
    setRemark(rmark);
    setShowModalRevise(true);
  };

  const handleChange = async (event) => {
    setselectedDepartmentId(event.target.value);
  };

  const handleChangeType = async (event) => {
    setsselectedStatus(event.target.value);
  };

  const handleChangeStartDate = async (event) => {
    setstartDate(event.target.value);
  };

  const handleChangeEndDate = async (event) => {
    setendDate(event.target.value);
  };

  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/tasks_jc/${taskId}`)
      .then((response) => {
        toast.success("Task Deleted successfully !");
        // setTasks(response.data.data);
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const sendConfirm = async (userId, id) => {
    await http
      .post(`/send_confirm_jc/${userId}/${id}`)
      .then((response) => {
        toast.success("Confirmation Email/SMS sent");
        // setTasks(response.data.data);
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update_jc?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Task status updated successfully !");
      fetchTaskDetails(selectedDepartmentId, selectedStatus);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const [formData, setFormData] = useState({
    revised_date: "",
    revised_remark: "",
  });
  const initialState = {
    revised_date: "",
    revised_remark: "",
  };

  const handleChangeModal = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };

  function getRandomLightColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const backgroundColorMap = new Map();

  // Function to get a unique background color for a user.id
  function getUniqueBackgroundColor(userId) {
    if (!backgroundColorMap.has(userId)) {
      // If the user.id doesn't have a background color assigned, generate one and store it in the map
      backgroundColorMap.set(userId, getRandomLightColor());
    }
    return backgroundColorMap.get(userId);
  }

  const [formData1, setFormData1] = useState({
    assigned_to: "",
    assiger_branch: "",
    est_time: "",
    service_amount: "",
    description: "",
  });

  const openEditModal = async (taskAll, index) => {
    setEditColumn(index);
    setShowModal1(true);
    setFormData1({
      assigned_to: taskAll.assigned_to || "",
      assiger_branch: taskAll.assiger_branch || "",
      est_time: taskAll.est_time || "",
      service_amount: taskAll.service_amount || "",
      description: taskAll.description || "",
    });
    setEditUser(taskAll);
  };

  const saveEditedCustemer = async (e) => {
    e.preventDefault();
    try {
      if (!editUser) {
        // Handle the case when editUser is null
        console.error("No user data to edit.");
        return;
      }

      const response = await http.put(`/jc_edit/${editUser.id}`, formData1);
      if (response.data.data) {
        toast.success("Data updated successfully!");
        setShowModal1(false);
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
      } else {
        console.log("API response is not an array");
      }
    } catch (error) {
      console.error("Error updating user", error);
      toast.error("An error occurred while updating the customer.");
    }
    setEditColumn("");
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setEditColumn("");
  };

  const calculateDaysDifference = (created_at) => {
    const createdDate = moment(created_at).startOf('day');
    const presentDate = moment().startOf('day');
    return presentDate.diff(createdDate, 'days');
};



  return (
    <>
      <Toaster />

      {showModal && tasksind.length > 0 && (
        <div className="modal" tabindex="-1" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Product Details: <b>#JC {tasksind[0].id}</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group  mt-3">
                  <label>Product Name</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].product_name}
                  />
                </div>

                <div className="form-group  mt-3">
                  <label>Model</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].model}
                  />
                </div>

                <div className="form-group  mt-3">
                  <label>Others</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].brand}
                  />
                </div>

                <div className="form-group  mt-3">
                  <label>Engineer Remark</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].eng_remark}
                  />
                </div>

                <div className="form-group  mt-3">
                  <label>Damage Remark</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].damage_remark}
                  />
                </div>

                <div className="form-group  mt-3">
                  <label>Password</label>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    value={tasksind[0].type}
                  />
                </div>

                <div className="form-group  mt-3">
               
                  <label>Power adapter:</label>
                  
                  {tasksind[0].power_adapter == 1 ? (
                    <b className="text-danger px-1">Yes</b>
                  ) : (
                    <b className="text-success px-1">No</b>
                  )}
                  <br />
                  <label>Carry case:</label>
                  
                  {tasksind[0].carry_case == 1 ? (
                    <b className="text-danger px-1">Yes</b>
                  ) : (
                    <b className="text-success px-1">No</b>
                  )}
                  <br />
                  <label>Laptop Bag:</label>
                 
                  {tasksind[0].laptop_bag == 1 ? (
                    <b className="text-danger px-1">Yes</b>
                  ) : (
                    <b className="text-success px-1">No</b>
                  )}
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal1 && (
        <form onSubmit={saveEditedCustemer}>
          <div className="modal" tabindex="-1" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Job card</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label>Assigned To</label>
                    <select
                      name="assigned_to"
                      className="form-control text-uppercase"
                      defaultValue={formData1.assigned_to}
                      onChange={handleChangeModal}
                      required
                    >
                      <option value="">Select a user</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name} {user.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group  mt-3">
                    <select
                      defaultValue={formData1.assiger_branch}
                      onChange={handleChangeModal}
                      name="assiger_branch"
                      className="form-control mb-1"
                      required
                    >
                      <option value="">Select Branch</option>
                      {departments.map((dept, index) => (
                        <option key={index} value={dept.id}>
                          {dept.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group  mt-2">
                    <label>Establish Time</label>
                    <select
                      name="est_time"
                      onChange={handleChangeModal}
                      className="form-control"
                      defaultValue={formData1.est_time}
                      required
                    >
                      <option value="0">0 Hour</option>
                      <option value="1">1 Hour</option>
                      <option value="2">2 Hour</option>
                      <option value="3">3 Hour</option>
                      <option value="4">4 Hour-Half day</option>
                      <option value="5">5 Hour</option>
                      <option value="6">6 Hour</option>
                      <option value="7">7 Hour</option>
                      <option value="8">8 Hour</option>
                    </select>
                  </div>

                  <div className="form-group  mt-2">
                    <label>Service Amount</label>
                    <input
                      name="service_amount"
                      onChange={handleChangeModal}
                      defaultValue={formData1.service_amount}
                      className="form-control"
                      type="number"
                    />
                  </div>

                  <div className="form-group  mt-2">
                    <label>Description</label>
                    <textarea
                      name="description"
                      onChange={handleChangeModal}
                      defaultValue={formData1.description}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      <div className="container mt-1">
        <div className="row mb-2">
          <div className="col-md-3">
            {user && user.department_id == 1 ? (
              <h1 className="mb-1">
                All Tasks List ({TaskCount}) Total Price
                <span className="text-primary">({TotalCount})</span>
              </h1>
            ) : (
              <h1 className="mb-1">Your Tasks List ({TaskCount}) </h1>
            )}
          </div>

          <div className="col-md-2 form-group text-end">
            <select onChange={handleChange} className="form-control mb-1">
              <option value="">Select Branch</option>
              <option value="">All Branch</option>
              {departments.map((dept, index) => (
                <option key={index} value={dept.id}>
                  {dept.branch_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 form-group text-end">
            <select onChange={handleChangeType} className="form-control">
              <option value="">Select Status</option>
              <option value="">All Status</option>
              <option value="Pending">Pending</option>
              <option value="Pend-Cus-Confir">Pend-Cus-Confir</option>
              <option value="Delivered">Delivered</option>
              <option value="Hold-In-HQ">Hold-In-HQ</option>
              <option value="Hold-To-HQ">Hold-To-HQ</option>
              <option value="Hold-waiting-RM"> Hold - waiting RM</option>
              <option value="Ready infomed">Ready infomed</option>
              <option value="Ready Return">Ready Return</option>
              <option value="Ready waiting po">Ready waiting po</option>
              <option value="Ready">Ready</option>
              <option value="Not_Taken">Not Taken</option>
              <td>
                {user.status === "Delivered" && (
                  <span className="badge bg-success font-size-13">
                    Delivered
                  </span>
                )}
                {user.status === "Pending" && (
                  <span className="badge bg-warning font-size-13">Pending</span>
                )}
                {user.status === "Hold-In-HQ" && (
                  <span className="badge bg-danger font-size-13">
                    Hold-In-HQ
                  </span>
                )}
                {user.status === "Hold-To-HQ" && (
                  <span className="badge bg-danger font-size-13">
                    Hold-To-HQ
                  </span>
                )}
                {user.status === "Hold-waiting-RM" && (
                  <span className="badge bg-warning font-size-13">
                    Hold - waiting RM
                  </span>
                )}
                {user.status === "Pend-Cus-Confir" && (
                  <span className="badge bg-danger font-size-13">
                    Pend-Cus-Confir
                  </span>
                )}
                {user.status === "Ready infomed" && (
                  <span className="badge bg-secondary font-size-13">
                    Ready infomed
                  </span>
                )}
                {user.status === "Ready waiting po" && (
                  <span className="badge bg-secondary font-size-13">
                    Ready Waiting PO
                  </span>
                )}
                {user.status === "Ready" && (
                  <span className="badge bg-success font-size-13">Ready</span>
                )}

                {user.status === "Not_Taken" && (
                  <span className="badge bg-secondary font-size-13">
                    Not Taken
                  </span>
                )}
                {user.status === "Ready Return" && (
                  <span className="badge bg-secondary font-size-13">
                    Ready Return
                  </span>
                )}
                {user.status === "Ready informed" && (
                  <span className="badge bg-secondary font-size-13">
                    Ready Informed
                  </span>
                )}
              </td>
            </select>
          </div>

          <div className="col-md-2 form-group text-end">
            <input
              name="start_date"
              onChange={handleChangeStartDate}
              class="form-control"
              type="date"
            ></input>
          </div>

          <div className="col-md-2 form-group text-end">
            <input
              name="end_date"
              onChange={handleChangeEndDate}
              class="form-control"
              type="date"
            ></input>
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped table-center">
              <thead className="table-primary">
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th className="text-danger">Created</th>
                  <th>DD</th>
                  <th>BR</th>
                  <th>Description</th>
                  <th>Company</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Status</th>
                  <th>Del Date</th>
                  <th>Est</th>
                  <th>Price</th>
                  <th>ABy</th>
                  <th>ATo</th>

                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>JC{user.id}</td>
                    <td width="6%">
                      {moment(user.created_at).format("DD MMM,YY hh:mm A")}
                    </td>
                    <td> <td>{calculateDaysDifference(user.created_at)}D</td></td>
                    <td>
                      <b
                        className={
                          user.branch?.branch_name == "K1"
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {user.branch?.branch_name}
                      </b>
                    </td>
                    <td
                      width="17%"
                      className="custom-tooltip font_desc"
                      title={user.description}
                      
                    >
                      {user.description}
                    </td>
                    <td>
                      <td className="text-uppercase fw-bold">
                        {user.customer?.company}
                      </td>
                    </td>
                    <td>
                      <td className="text-uppercase fw-bold">
                        {user.customer?.name}
                      </td>
                    </td>
                    <td>
                      <td className="text-uppercase fw-bold">
                        {user.customer?.phone}
                      </td>
                    </td>
                    <td>
                      {user.status === "Delivered" && (
                        <span className="badge bg-success font-size-13">
                          Delivered
                        </span>
                      )}
                      {user.status === "Pending" && (
                        <span className="badge bg-warning font-size-13">
                          Pending
                        </span>
                      )}
                      {user.status === "Hold-In-HQ" && (
                        <span className="badge bg-danger font-size-13">
                          Hold-In-HQ
                        </span>
                      )}
                      {user.status === "Hold-To-HQ" && (
                        <span className="badge bg-danger font-size-13">
                          Hold-To-HQ
                        </span>
                      )}
                      {user.status === "Hold-waiting-RM" && (
                        <span className="badge bg-warning font-size-13">
                          Hold - waiting RM
                        </span>
                      )}
                      {user.status === "Pend-Cus-Confir" && (
                        <span className="badge bg-danger font-size-13">
                          Pend-Cus-Confir
                        </span>
                      )}
                      {user.status === "Ready infomed" && (
                        <span className="badge bg-secondary font-size-13">
                          Ready infomed
                        </span>
                      )}
                      {user.status === "Ready waiting po" && (
                        <span className="badge bg-secondary font-size-13">
                          Ready Waiting PO
                        </span>
                      )}
                      {user.status === "Ready Return" && (
                        <span className="badge bg-secondary font-size-13">
                          Ready Return
                        </span>
                      )}

                      {user.status === "Ready" && (
                        <span className="badge bg-success font-size-13">
                          Ready
                        </span>
                      )}

                      {user.status === "Not_Taken" && (
                        <span className="badge bg-secondary font-size-13">
                          Not Taken
                        </span>
                      )}
                      {user.status === "Ready informed" && (
                        <span className="badge bg-secondary font-size-13">
                          Ready Informed
                        </span>
                      )}
                    </td>
                    <td width="7%">
                      {moment(user.target_date).format("DD MMM,YY")}{" "}
                    </td>
                    <td >{user.est_time} </td>
                    <td>{user.service_amount}</td>

                    <td className="text-uppercase fw-bold">
                      {user.assigner?.name} 
                    </td>
                    <td
                      
                      className={`text-uppercase fw-bold ${
                        user.assignee?.name && user.assignee?.last_name
                          ? "background-color-class"
                          : ""
                      }`}
                      style={{
                        color: getUniqueBackgroundColor(user.assignee?.id),
                      }}
                    >
                      {user.assignee?.name} 
                    </td>

                    <td width="30%">
                      <select
                        onChange={statusUpdate}
                        className="form-select-sm mb-2 custom_select"
                      >
                        <option value="0">Select</option>
                        <option value="Pending">Pending</option>
                        <option value="Pend-Cus-Confir">
                          Pend-Cus-Confir
                        </option>
                        <option value="Delivered">Delivered</option>
                        <option value="Hold-In-HQ">Hold-In-HQ</option>
                        <option value="Hold-To-HQ">Hold-To-HQ</option>
                        <option value="Hold-waiting-RM">
                          {" "}
                          Hold - waiting RM
                        </option>
                        <option value="Ready infomed">Ready infomed</option>
                        <option value="Ready Return">Ready Return</option>
                        <option value="Ready">Ready</option>
                        <option value="Not_Taken">Not Taken</option>
                        <option value="Ready waiting po">
                          Ready waiting po
                        </option>
                      </select>
                      <button
                        className="btn-sm btn btn-link"
                        onClick={() => handleSave(user.id)}
                      >
                        Save
                      </button>

                      <Link
                        to={`/jobcart/print/${user.id}`}
                        className="btn-link px-1"
                      >
                        🖨️
                      </Link>

                      <a
                        className="btn-link px-1"
                        onClick={() => openModal(user.id)}
                      >
                        VM
                      </a>

                      {loginUser === 1 ? (
                        <a
                          className="btn-link px-1 text-danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this task?"
                              )
                            ) {
                              deleteTask(user.id);
                            }
                          }}
                        >
                          Del
                        </a>
                      ) : (
                        ""
                      )}

                     
                        <a
                          className="btn-link px-1"
                          onClick={() => openEditModal(user, index)}
                        >
                          ✏️
                        </a>
                      
                      
                      <button
                        className="btn btn-sm btn-link"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want send the emails?"
                            )
                          ) {
                            sendConfirm(user.customer_id, user.id);
                          }
                        }}
                      >
                        📩
                      </button>
                      {user.mail_status == 1 ? "✅" : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
