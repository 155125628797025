import React, { useState } from 'react';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";


export default function Resetpassword() {
    const { http, user } = AuthUser();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const resetPassword = async () => {
        try {
            const response = await http.post('/reset-password', {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmPassword,
            });
            toast.success(response.data.message);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message); // Displaying error message with toast
                setCurrentPassword('');
                setConfirmPassword('');
                setNewPassword('');
            } else {
                toast.error('Something went wrong!/Old password does not matching'); // Generic error message
            }
        }
    };


    return (
        <>
            <Toaster />
            <div className="row justify-content-center pt-5">

                <div className="col-sm-6">
                    <div className="card p-4">
                        <h2>Reset Password</h2>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className='form-group'>
                                <label >Current Password</label>
                                <input className='form-control' type="password" onChange={(e) => setCurrentPassword(e.target.value)} required />
                            </div>
                            <div className='form-group' >
                                <label >New Password</label>
                                <input className='form-control' type="password" onChange={(e) => setNewPassword(e.target.value)} required />
                            </div>
                            <div className='form-group'>
                                <label >Confirm New Password</label>
                                <input className='form-control' type="password" onChange={(e) => setConfirmPassword(e.target.value)} required />
                            </div>
                            <button className='btn btn-success mt-3' onClick={resetPassword}>Reset Password</button>
                        </form>

                    </div>

                </div>
            </div>
        </>

    )
}
