import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import TaskSkeleton from './TaskSkeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Company_view() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [selectedDepartmentId, setselectedDepartmentId] = useState("");
    const [selectedStatus, setsselectedStatus] = useState("Pending");
    const [editUser, setEditUser] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
    }, [selectedStatus]);

    const fetchTaskDetails = (selstatus = '') => {
        setIsLoading(true);
        let endpoint;
        endpoint = `/company?status=${selstatus}`;
        http.get(endpoint).then((res) => {
            setIsLoading(false);
            if (Array.isArray(res.data.data)) {
                setTasks(res.data.data);
            } else {
                console.error("API response is not an array");
            }
        }).catch(error => {
            setIsLoading(false);
            console.error("Error fetching tasks:", error);
        });
    }

    const handleSave = (id) => {

    }


    const handleChangeModal = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const [formData, setFormData] = useState({
        company_address: user.company_address || '',
        company_name: user.company_name || '',
        contact_name: user.contact_name || '',
        contact_phone: user.contact_phone || '',

    })

    const handleApproveSave = async (idCompany) => {
        await http.get(`/approve_company_admin?company=${idCompany}`)
            .then(response => {
                toast.success("Approved successfully !");
            })
            .catch(err => {
                console.error("Error on approval", err);
            });
    }

    const openEditModal = (user) => {
        setShowModal(true);
        setFormData({
            company_address: user.company_address || '',
            company_name: user.company_name || '',
            contact_name: user.contact_name || '',
            contact_phone: user.contact_phone || '',
        });
        setEditUser(user);
    };

    const saveEditedUser = async (e) => {
        e.preventDefault();
        try {
            if (!editUser) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }

            const response = await http.put(`/company/${editUser.id}`, formData);
            if (Array.isArray(response.data.data)) {
                toast.success("User updated successfully!");
                setShowModal(false);
                fetchTaskDetails(selectedDepartmentId, selectedStatus);

            } else {
                console.log("API response is not an array");
            }
        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the user.");
        }
    };

    const closeModal = () => {
        setShowModal(false);
    }
    console.log(tasks);
    return (
        <>
            <div className='row mb-2'>
                <div className='col-md-4'>
                    <h1>School List</h1> </div>
                <Link className="mb-3 add_link text-end p-4" to="/company_add">Add</Link>
            </div>
            <Toaster />

            {showModal && (
                <form onSubmit={saveEditedUser}>
                    <div className="modal" tabindex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Company Details</h5>
                                    <button type="button" className="btn-close" onClick={closeModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group  mt-3'>
                                        <label>Company Name</label>
                                        <input name="company_name" required className='form-control' type="text" value={formData.company_name}
                                            onChange={handleChangeModal} />
                                    </div>
                                    <div className='form-group  mt-3'>
                                        <label>Address</label>
                                        <input name="company_address" required className='form-control' type="text" value={formData.company_address}
                                            onChange={handleChangeModal} />
                                    </div>
                                    <div className='form-group  mt-3'>
                                        <label>Contacct Person</label>
                                        <input name="contact_name" required className='form-control' type="text" value={formData.contact_name}
                                            onChange={handleChangeModal} />
                                    </div>

                                    <div className='form-group  mt-3'>
                                        <label>Contacct Person</label>
                                        <input name="contact_phone" required className='form-control' type="text" value={formData.contact_phone}
                                            onChange={handleChangeModal} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                    <button type="submit" className="btn btn-primary" >Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            <div className="table-responsive">
                {isLoading ? (
                    <TaskSkeleton />

                ) : (
                    <table className="table table-striped">
                        <thead className="table-primary">
                            <tr>

                                <th className='text-danger'>Created On</th>
                                <th>Company Code</th>
                                <th>Comapany Name</th>
                                <th>Contact Person</th>
                                <th>Contact No</th>
                                <th>Address</th>
                                <th>Logo</th>
                                <th>Status</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map(comp => (
                                <tr key={comp.id}>
                                    <td>{moment(comp.created_at).format('DD MMM,YY')}</td>
                                    <td className='font-weight-bold'>{comp.company_id}</td>
                                    <td>{comp.company_name}
                                        <br /><b>Email:</b> {comp.admin?.email}
                                        <br /><b>Pass:</b> {comp.admin?.pass}
                                    </td>
                                    <td>{comp.contact_name}</td>
                                    <td>{comp.contact_phone}</td>
                                    <td>{comp.company_address}</td>
                                    <td><img src={`http://3.111.220.78/api/storage/tasks/${comp.company_logo}`} width="35" alt="Company Logo"
                                        height="35"></img></td>
                                    <td>{comp.status == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Non-Active</span>}</td>
                                    <td> <button className='btn-sm btn btn-link' onClick={() => handleSave(comp.id)}>De-Activate</button>
                                        <button className='btn-sm btn btn-link' onClick={() => handleApproveSave(comp.id)}>Approve Admin</button>
                                        {user.status === 1 ? <button className='btn-sm btn btn-link' onClick={() => openEditModal(comp)}>Edit</button> : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}
