import React, { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function BulkTaskWeek() {
  const navigate = useNavigate();
  const { http, user } = AuthUser();
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    description: "",
    department_id: "",
    target_date: "",
    additional_info: "",
    task_weightage: "3",
    priority: "Medium",
    assigned_by: user.id,
    assigned_to: "",
    type: "",
    type_number: "",
    type_number_month: "",
    target_date_year: "",
    company_logo: "",
    est_time:"1",
  });

  const initialState = { ...formData };

  const [departmentsAll, setDepartmentsAll] = useState([]);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([initialState]); // Initialize with one empty task
  const [userForTasks, setUserForTasks] = useState(
    Array.from({ length: tasks.length }, () => [])
  );
  const [hasError, setHasError] = useState(false);

  const handleFileChange = (e, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = {
      ...updatedTasks[index],
      company_logo: e.target.files[0], // Store the selected file for the task
    };
    setTasks(updatedTasks);
  };

  useEffect(() => {
    http.get(`/departments?login_user=${user.id}`).then((res) => {
      setDepartmentsAll(res.data.data);
    });
  }, []);

  const handleAddMore = () => {
    setTasks((prevTasks) => {
      const lastTask = prevTasks[prevTasks.length - 1];
      const newTask = {};

      for (const key in initialState) {
        if (key === "department_id" || key === "assigned_to") {
          // Initialize department_id and assigned_to with empty values
          newTask[key] = "";
        } else {
          newTask[key] = lastTask[key];
        }
      }

      return [...prevTasks, newTask];
    });

    // Add an empty array to userForTasks to track "Assigned To" values for the new task
    setUserForTasks((prevUserForTasks) => [...prevUserForTasks, []]);
  };

  const handleRemove = (index) => {
    // Remove a task by its index
    if (index == 0) {
      toast.error("You cannot delete initial item");
      return true;
    }
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1);
    setTasks(updatedTasks);
  };

  const handleChange = (e, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = {
      ...updatedTasks[index],
      [e.target.name]: e.target.value,
    };
    setTasks(updatedTasks);

    if (e.target.name === "department_id") {
      fetchUsersForDepartment(e.target.value, index);
    }
  };

  const fetchUsersForDepartment = async (departmentId, index) => {
    try {
      const response = await http.get(
        `/department_user?department=${departmentId}&login_user=${user.id}`
      );
      const updatedUserForTasks = [...userForTasks];
      updatedUserForTasks[index] = response.data.users;
      setUserForTasks(updatedUserForTasks);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    tasks.forEach((task, index) => {
      for (const key in task) {
        // Exclude the 'task_file' field from validation
        if (
          key !== "company_logo" &&
          key !== "additional_info" &&
          key !== "target_date_year" &&
          key !== "type_number" &&
          key !== "type_number_month" &&
          key !== "est_time" &&
          !task[key]
        ) {
          errors[index] = errors[index] || {};
          errors[index][key] = `The ${key.replace("_", " ")} ?.`;
        }
      }

      if (task.type === "1" && !task.type_number_month) {
        task.type_number_month = "1";
      }

      if (task.type === "2" && !task.type_number) {
        task.type_number = "1";
      }

      if (task.type === "3" && !task.target_date_year) {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
        const day = String(currentDate.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        task.target_date_year = formattedDate;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsSaving(true); // Set loading state

    try {
      const responses = await Promise.all(
        tasks.map(async (task) => {
          const formData = new FormData();
          for (const key in task) {
            if (key === "company_logo" && task[key] instanceof File) {
              formData.append(key, task[key]);
            } else {
              formData.append(key, task[key]);
            }
          }

          const response = await http.post("/week_month", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          return response.data.task;
        })
      );

      setIsSaving(false); // Clear loading state

      // Notify success and navigate to the dashboard
      toast.success("Bulk week moth tasks added successfully!");
      navigate("/week_month_task");
    } catch (error) {
      setIsSaving(false); // Clear loading state
      console.error("Error submitting tasks:", error);
      setHasError(true);
      toast.error(
        "Please review all input selections carefully as there was an error when submitting bulk tasks"
      );
    }
  };
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  console.log(formErrors);

  return (
    <>
      <h3>Add Bulk Week/Month Task</h3>
      <Toaster />
      <div className="container mt-5">
        {isSaving && (
          <div className="alert alert-warning" role="alert">
            Saving tasks, please wait...
          </div>
        )}
        {tasks.map((task, index) => (
          <div
            key={index}
            className={`row border p-2 bulk-form  ${
              index % 2 === 0 ? "custom-hover" : ""
            }  ${hasError ? "error-border" : ""}`}
          >
            <div className="form-group col-md-1 mt-3">
              <label>Department</label>
              <select
                name="department_id"
                className="form-control"
                value={task.department_id}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Select Department</option>
                {departmentsAll.map((depart) => (
                  <option key={depart.id} value={depart.id}>
                    {depart.name}
                  </option>
                ))}
              </select>
              {formErrors[index]?.department_id && (
                <div className="error-message">
                  {formErrors[index].department_id}{" "}
                </div>
              )}
            </div>
            <div className="form-group  mt-3 col-md-1">
              <label>Assigned To</label>
              <select
                name="assigned_to"
                className="form-control text-uppercase"
                value={task.assigned_to}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Select a user</option>
                {userForTasks[index]
                  ? userForTasks[index].map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name} {user.last_name}
                      </option>
                    ))
                  : null}
              </select>
              {formErrors[index]?.assigned_to && (
                <div className="error-message">
                  {formErrors[index].assigned_to}
                </div>
              )}
            </div>
            <div className="form-group mt-3 col-md-2">
              <label>Description</label>
              <textarea
                name="description"
                className="form-control"
                value={task.description} // Change formData to task
                onChange={(e) => handleChange(e, index)}
                rows="1"
              />
              {formErrors[index]?.description && (
                <div className="error-message">
                  {formErrors[index].description}{" "}
                </div>
              )}
            </div>

            <div className="form-group mt-3 col-md-1">
              <label>Select Type</label>
              <select
                name="type"
                className="form-control"
                value={task.type}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Select Type</option>
                <option value="1">Monthly</option>
                <option value="2">Weekly</option>
                <option value="3">Once</option>
                <option value="4">Daily</option>
              </select>
              {formErrors[index]?.type && (
                <div className="error-message">{formErrors[index].type} </div>
              )}
            </div>

            {task.type === "1" && (
              <div className="form-group mt-3 col-md-1">
                <label>Select Month day</label>

                <select
                  name="type_number_month"
                  id="week-dropdown"
                  className="form-control"
                  value={task.type_number_month}
                  onChange={(e) => handleChange(e, index)}
                  required
                >
                  <option value="">Select Month day</option>
                  {Array.from({ length: 30 }, (_, i) => i + 1).map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {task.type === "3" && (
              <div className="form-group mt-3 col-md-1">
                <label>Once date</label>
                <input
                  name="target_date_year"
                  className="form-control"
                  type="date"
                  value={task.target_date_year}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            )}

            {task.type === "2" && (
              <div className="form-group mt-3 col-md-1">
                <label htmlFor="week-dropdown">Select a week day</label>

                <select
                  name="type_number"
                  id="week-dropdown "
                  className="form-control"
                  value={task.type_number}
                  onChange={(e) => handleChange(e, index)}
                  required
                >
                  {days.map((day, index) => (
                    <option key={index} value={index + 1}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="form-group mt-3 col-md-1">
              <label>Target Date</label>
              <select
                name="target_date"
                className="form-control"
                value={task.target_date}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Add Days</option>
                <option value="0">+ 0 Day</option>
                <option value="1">+ 1 Day</option>
                <option value="2">+ 2 Day</option>
                <option value="3">+ 3 Day</option>
                <option value="4">+ 4 Day</option>
                <option value="5">+ 5 Day</option>
                <option value="6">+ 6 Day</option>
                <option value="7">+ 7 Day</option>
                <option value="8">+ 8 Day</option>
                <option value="9">+ 9 Day</option>
                <option value="10">+ 10 Day</option>
              </select>
              {formErrors[index]?.target_date && (
                <div className="error-message">
                  {formErrors[index].target_date}{" "}
                </div>
              )}
            </div>

            <div className="form-group mt-3 col-md-1">
              <label>Additional Info</label>
              <textarea
                name="additional_info"
                className="form-control"
                value={task.additional_info}
                onChange={(e) => handleChange(e, index)}
                rows="1"
              />
            </div>
            
            {user && user.company_id === 1 && (
              <div className="form-group mt-3 col-md-1">
                <label>Estimation Time</label>
                <select
                  name="est_time"
                  className="form-control"
                  value={task.est_time}
                  onChange={(e) => handleChange(e, index)}
                  required
                >
                  <option value="">Select</option>
                  <option value="0">0 Hour</option>
                  <option value="1">1 Hour</option>
                  <option value="2">2 Hour</option>
                  <option value="3">3 Hour</option>
                  <option value="4">4 Hour-Half day</option>
                  <option value="5">5 Hour</option>
                  <option value="6">6 Hour</option>
                  <option value="7">7 Hour</option>
                  <option value="8">8 Hour</option>
                </select>
              </div>
            )}

            <div className="form-group  mt-3 col-md-1">
              <label>TW</label>
              <select
                name="task_weightage"
                className="form-control"
                value={task.task_weightage}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Select a weightage</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            <div className="form-group  mt-3 col-md-1">
              <label>Priority</label>
              <select
                name="priority"
                className="form-control"
                value={task.priority}
                onChange={(e) => handleChange(e, index)}
                required
              >
                <option value="">Select a Priority</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>

            <div className="form-group mt-3 col-md-1">
              <label>Files</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => handleFileChange(e, index)} // Update the file input handler
              />
            </div>

            <div className="form-group col-md-1 mt-3">
              <button
                className="btn button btn-danger btn-sm"
                onClick={() => handleRemove(index)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}

        <div className="row">
          <div className="form-group col-md-1 pt-4">
            <button
              className="btn button btn-success btn-sm"
              onClick={handleAddMore}
            >
              Add More
            </button>
          </div>

          <div className="form-group col-md-1 pt-4">
            <button
              className="btn button btn-success btn-sm"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
