import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function Assign_amc() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const initialState = {
        description: '',
        department_id: '61',
        target_date: '',
        additional_info: '',
        task_weightage: '3',
        priority: 'Medium',
        assigned_by: user.id,
        assigned_to: '',
        task_file: '',
        amc_id: '',
        est_time: '1'
    }

    const [departmetsall, setdepartmentsall] = useState([]);
    const [department, setDepartment] = useState([]);
    const [users, setUsers] = useState([]);
    const [amctasks, setAmctTasks] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    useEffect(() => {
        http.get(`/departments?login_user=${user.id}`).then((res) => {
            setdepartmentsall(res.data.data);
        })

        http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
            setUsers(res.data.users);
        })

        http.get(`/assign_amc_task?&id=${id}`).then((res) => {
            setAmctTasks(res.data.data);
            if (res.data.data.length > 0) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: res.data.data[0].description,
                    customer_name: res.data.data[0].name+'-'+res.data.data[0].phone+'-'+res.data.data[0].company,
                    amc_id: id
                }));
            }
        })

        fetchTaskDetails();
    }, [])

    const fetchTaskDetails = () => {
        setIsLoading(true);
        let endpoint = `/cust_available_total?login_user=${user.id}`;

        http.get(endpoint).then((res) => {
            setIsLoading(false);
            if (Array.isArray(res.data.data)) {
                setTasks(res.data.data);
            } else {
                setIsLoading(false);
                console.error("API response is not an array");
            }
        }).catch(error => {
            setIsLoading(false);
            console.error("Error fetching tasks:", error);
        });
    }

    const [formData, setFormData] = useState({
        description: '',
        department_id: '61',
        target_date: '',
        additional_info: '',
        task_weightage: '3',
        priority: 'Medium',
        assigned_by: user.id,
        assigned_to: '',
        task_file: '',
        amc_id: '',
        est_time: '1'
    });

    const handleChange = (e) => {
        setFormData({

            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'assigned_to') {
            fetchCusterAvail(e.target.value);
        }

    };

    const fetchCusterAvail = async (custId) => {
        try {
            const response = await http.get(`/cust_available?cust_id=${custId}&login_user=${user.id}`);
            setAvailable(response.data.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }

        const taskFileInput = document.querySelector('input[type="file"]');
        if (taskFileInput.files[0]) form.append('task_file', taskFileInput.files[0]);

        try {
            http.post('/tasks', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(async (res) => {
                const response = await http.put(`/amc_status?id=${id}`);
                toast.success("Task assigned successfully !");
                setFormData(initialState);
                navigate('/jobcart/amc_list');
            })

        } catch (error) {
            console.error('Error adding task:', error.response.data);
        }
    };
    const [showModalRevise, setShowModalRevise] = useState(false);
    const [available, setAvailable] = useState();
    const openModalRevise = () => {
        setShowModalRevise(true);
    }
    const closeModal = () => {
        setShowModalRevise(false);
    }

    return (
        <>
            {showModalRevise && (
                <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Work status Details</h5>
                                    <button type="button" className="btn-close" onClick={closeModal}></button>
                                </div>
                                <div className="modal-body">
                                    <table className="table table-striped">
                                        <thead className="table-primary">
                                            <tr>

                                                <th className='text-danger'>No</th>
                                                <th>Name</th>
                                                <th>Total Time</th>
                                                <th>Total Analitics</th>
                                                <th>Today</th>
                                                <th>Tomorrow</th>
                                                <th>Day-after</th>
                                                {/* Add other fields as necessary */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tasks.map((taskuser, index) => (

                                                <tr key={taskuser.id}>
                                                    <td>{index + 1}</td>
                                                    <td className='text-uppercase'>{taskuser.name} {taskuser.last_name}</td>
                                                    <td>
                                                        <b>{taskuser.total_est_time}</b> Hours
                                                    </td>
                                                    <td width="17%">
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar"
                                                                role="progressbar"
                                                                style={{ width: `${taskuser.percentage}%` }}
                                                                aria-valuenow={taskuser.percentage}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {taskuser.percentage}%

                                                            </div>

                                                        </div>


                                                    </td>
                                                    {/* Today logic */}
                                                    <td width="17%">
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar"
                                                                role="progressbar"
                                                                style={{ width: `${taskuser.today_percentage}%` }}
                                                                aria-valuenow={taskuser.today_percentage}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {taskuser.today_percentage}%

                                                            </div>

                                                        </div>
                                                        <div className="row"><span className='text-center col-12'><b>{taskuser.today_est_time} Hrss</b></span></div>
                                                    </td>
                                                    {/* Tommorrw logic */}
                                                    <td width="17%">
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar"
                                                                role="progressbar"
                                                                style={{ width: `${taskuser.tommorrow_percentage}%` }}
                                                                aria-valuenow={taskuser.tommorrow_percentage}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {taskuser.tommorrow_percentage}%
                                                            </div>
                                                        </div>
                                                        <div className="row"><span className='text-center col-12'><b>{taskuser.tommorrow_est_time} Hr</b></span></div>
                                                    </td>

                                                    {/* Dayafter logic */}
                                                    <td width="17%">
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar"
                                                                role="progressbar"
                                                                style={{ width: `${taskuser.dayafter_percentage}%` }}
                                                                aria-valuenow={taskuser.dayafter_percentage}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {taskuser.dayafter_percentage}%
                                                            </div>
                                                        </div>
                                                        <div className="row"><span className='text-center col-12'><b>{taskuser.dayafter_est_time} Hr</b></span></div>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn-sm btn btn-success" onClick={closeModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            <div className="container mt-5">
                <Toaster />
                <h1>Add Task</h1>

                <a href='#' className="text-end btn-link" onClick={() => openModalRevise()} >Analitics 📊</a>
                <form onSubmit={handleSubmit}>
                    <div className='form-group  mt-3'>
                        <label>Assigned To</label>
                        <select
                            name="assigned_to"
                            className='form-control text-uppercase'
                            value={formData.assigned_to}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a user</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.name} {user.last_name}</option>
                            )
                            )}
                        </select>
                    </div>

                    <div className='form-group  mt-2'>
                        <label>Establish Time</label>
                        <select
                            name="est_time" onChange={handleChange}
                            className='form-control'
                            value={formData.est_time}
                            required
                        >
                            <option value="0">0 Hour</option>
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hour</option>
                            <option value="3">3 Hour</option>
                            <option value="4">4 Hour-Half day</option>
                            <option value="5">5 Hour</option>
                            <option value="6">6 Hour</option>
                            <option value="7">7 Hour</option>
                            <option value="8">8 Hour</option>
                        </select>

                    </div>

                    {
                        available && (<div className='border border-warning mt-3 p-3 rounded'>
                            <div className='form-group  mt-3 '>
                                <label className='text-danger px-2'>Total Time Occupied : </label><span class="badge bg-success">{(available.est_time_sum == 0 ? 'Fully available' : available.est_time_sum + ' Hours')} </span>
                            </div>

                            <div className="progress  mt-2">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${available.percentage}%` }}
                                    aria-valuenow={available.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {available.percentage}%
                                </div>
                            </div>
                        </div>
                        )
                    }

                    <div className='form-group mt-3'>
                        <label>Description</label>
                        <textarea
                            name="description"
                            className='form-control'
                            value={formData.description}

                            onChange={handleChange}
                            rows="2"
                        />
                    </div>


                    <div className='form-group  mt-3'>
                        <label>Target Date</label>
                        <input name="target_date" className='form-control' type="date" value={formData.target_date} onChange={handleChange} required />
                    </div>

                    <div className='form-group mt-3'>
                        <label>Additional Info</label>
                        <textarea
                            name="additional_info"
                            className='form-control'
                            value={formData.additional_info}
                            onChange={handleChange}
                            rows="2"
                        />
                    </div>


                    <div className='form-group  mt-3'>
                        <label>Task Weightage</label>
                        <select
                            name="task_weightage"
                            className='form-control'
                            value={formData.task_weightage}
                            onChange={handleChange} required
                        >
                            <option value="">Select a weightage</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>

                    <div className='form-group  mt-3'>
                        <label>Priority</label>
                        <select
                            name="priority"
                            className='form-control'
                            value={formData.priority}
                            onChange={handleChange} required
                        >
                            <option value="">Select a Priority</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                    </div>

                    <div className='form-group mt-3'>
                        <label>Task Files</label>
                        <input
                            type="file"
                            className='form-control'
                        />
                    </div>
                    <button className='btn button btn-success mt-3' type="submit">Add Task</button>
                </form>
            </div>
        </>
    )
}
