import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";


export default function PrintSt() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { http, user } = AuthUser();
    const [usersjob, setUsersJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [companylogo, setCompanylogo] = useState();
    const [companyname, setCompanyname] = useState();
    const [companyaddr, setCompanyaddr] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const num = queryParams.get('num');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`/school_job_ind_print?&id=${id}`);
                // Simulate a 20-second delay using setTimeout
                setTimeout(() => {
                    setUsersJob(response.data.data);
                    setLoading(false); // Set loading to false after setting the state
                }, 700); // 20 seconds in milliseconds
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false in case of an error
            }

            await http.get(`/companyinfo?login_user=${user.id}`).then((resp) => {
                setCompanyname(resp.data.data[0].company_name);
                setCompanylogo(resp.data.data[0].company_logo);
                setCompanyaddr(resp.data.data[0].company_address);
            });
        };

        fetchData();
    }, []);

    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
    }
    const handlePrint = () => {
        window.print();
    };

    const handlePrintSecond = () => {
        const printContents = document.getElementById('second').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    };

    return (
        <>
            {(!usersjob || usersjob.length === 0) ? (
                <div className="alert alert-warning mt-2" role="alert">This payment has been deleted
                    { }  <span class="badge bg-success text-white p2">Receipt No: {num}</span>
                </div>
            ) : (
                <div>
                    <div className="col-md-12 text-end mt-2 mb-3">
                        <div className="row justify-content-md-center">
                            <div className="col-md-auto">
                                <button className="print-button btn btn-primary" onClick={handlePrint}>
                                    Two Copy
                                </button>
                            </div>
                            <div className="col-md-auto">
                                <button className="print-button btn btn-primary" onClick={handlePrintSecond}>
                                    One Copy
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="row container-fluid">
                            <div className="receipt-main col-md-12 col-sm-12 mb-4">
                                <div className="row">
                                    <div className="orginal-container">

                                    </div>
                                </div>
                                <div className="receipt-header">
                                    <div className="row">
                                        <div className="receipt-left col-md-2 col-sm-2">
                                            <img
                                                src={`http://3.111.220.78/api/storage/tasks/${companylogo}`}
                                                alt="Default Logo"
                                                width="137"
                                                height="55"
                                            />
                                        </div>

                                        <div className="col-md-8 col-sm-8 text-center">
                                            <div className="receipt-right">
                                                <h5>{companyname}</h5>
                                                <p> {companyaddr}</p>
                                                <br />
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-2 ">
                                            <div className="receipt-right">
                                                <p>
                                                    Date: {usersjob[0].alt_date
                                                        ? moment(usersjob[0].alt_date).format("DD MMMM, YYYY")
                                                        : moment(usersjob[0].created_at).format("DD MMMM, YYYY")
                                                    }
                                                </p>
                                                <br />
                                            </div>

                                        </div>

                                        <div className="col-md-12 text-end mb-3">
                                            {usersjob[0].ext_4 ? (
                                                <img
                                                    src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].ext_4}`}
                                                    width="110"
                                                    height="110"
                                                    alt="Photo"
                                                    className="rounded"
                                                />
                                            ) : (
                                                <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                            )}
                                        </div>

                                        <div className="col-md-4 col-sm-4 ">
                                            <div className="receipt-right">
                                                <p>
                                                    SID <b>{usersjob[0].adm_no ? usersjob[0].adm_no : ''} </b>
                                                </p>
                                                <p>
                                                    Name: <b> <b> {usersjob[0].first_name ? usersjob[0].first_name : ''} {usersjob[0].last_name ? usersjob[0].last_name : ''}</b></b>
                                                </p>
                                                <p>
                                                    Class: <b>{usersjob[0].class ? usersjob[0].class : ''}</b>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 text-center">

                                        </div>

                                        <div className="col-md-4 col-sm-4 ">
                                            <div className="receipt-right">
                                                <p>
                                                    Receipt No: <b>{usersjob[0] && usersjob[0]['invoice'] && usersjob[0]['invoice'].invoice_id ? usersjob[0]['invoice'].invoice_id : 'NAN'}</b>
                                                </p>
                                                <p>
                                                    Divison: <b>{usersjob[0].dev ? usersjob[0].dev : ''}</b>
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="row">
                                    <table className="table" style={{ minHeight: '250px' }}>
                                        <thead>
                                            <tr>
                                                <th scope="col">SL No</th>
                                                <th scope="col">Academic Year</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Division</th>
                                                <th scope="col">Fees Type</th>
                                                <th scope="col">Mode</th>
                                                <th scope="col">Reff</th>
                                                <th scope="col">Amount Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>1</th>
                                                <th>{usersjob[0].fees_name.ext_2}</th>
                                                <th>{usersjob[0].class ? usersjob[0].class : ''}</th>
                                                <th>{usersjob[0].dev ? usersjob[0].dev : ''}</th>
                                                <th> {usersjob[0].fees_name.name}</th>
                                                <th> {usersjob[0].ext_1}</th>
                                                <th> {usersjob[0].ext_2}</th>
                                                <th> {usersjob[0].amount}</th>
                                            </tr>
                                        </tbody>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{usersjob[0].amount}</th>
                                        </tr>
                                    </table>
                                    <hr />
                                    <div class="mt-3"></div>
                                    <div className="col-md-4 col-sm-4">
                                        <p className="mb-0"> {usersjob[0].user_name.name} {usersjob[0].user_name.last_name}</p>
                                        <p className="mb-0">.................................</p>
                                        <b>Received By </b>
                                    </div>

                                    <div className="col-md-4 col-sm-4">

                                        <p className="mb-0">.................................</p>
                                        <b>Parent Sign </b>
                                    </div>
                                </div>
                                <b className="mt-3 mb-3"> Note:Please retain the receipt for future reference. </b>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 col-sm-12 print-section second" id="second">

                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="row container-fluid">
                            <div className="receipt-main col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="orginal-container">

                                    </div>
                                </div>
                                <div className="receipt-header">
                                    <div className="row">
                                        <div className="receipt-left col-md-2 col-sm-2">
                                            <img
                                                src={`http://3.111.220.78/api/storage/tasks/${companylogo}`}
                                                alt="Default Logo"
                                                width="137"
                                                height="55"
                                            />
                                        </div>

                                        <div className="col-md-8 col-sm-8 text-center">
                                            <div className="receipt-right">
                                                <h5>{companyname}</h5>
                                                <p>{companyaddr}</p>
                                                <br />
                                            </div>
                                        </div>

                                        <div className="col-md-2 col-sm-2 ">
                                            <div className="receipt-right">
                                                <p>
                                                    Date: {usersjob[0].alt_date
                                                        ? moment(usersjob[0].alt_date).format("DD MMMM, YYYY")
                                                        : moment(usersjob[0].created_at).format("DD MMMM, YYYY")
                                                    }
                                                </p>
                                                <br />
                                            </div>

                                        </div>

                                        <div className="col-md-12 text-end mb-3">
                                            {usersjob[0].ext_4 ? (
                                                <img
                                                    src={`http://3.111.220.78/api/storage/tasks/${usersjob[0].ext_4}`}
                                                    width="110"
                                                    height="110"
                                                    alt="Photo"
                                                    className="rounded"
                                                />
                                            ) : (
                                                <img src="/dummy.jpg" alt="photo" width="135" height="145" />
                                            )}
                                        </div>

                                        <div className="col-md-4 col-sm-4 ">
                                            <div className="receipt-right">
                                                <p>
                                                    SID <b>{usersjob[0].admn_no ? usersjob[0].admn_no : ''} </b>
                                                </p>
                                                <p>
                                                    Name: <b> <b> {usersjob[0].first_name ? usersjob[0].first_name : ''} {usersjob[0].last_name ? usersjob[0].last_name : ''}</b></b>
                                                </p>
                                                <p>
                                                    Class: <b>{usersjob[0].class ? usersjob[0].class : ''}</b>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 text-center">

                                        </div>

                                        <div className="col-md-4 col-sm-4 ">
                                            <div className="receipt-right">
                                                <p>
                                                    Receipt No: <b>{usersjob[0] && usersjob[0]['invoice'] && usersjob[0]['invoice'].invoice_id ? usersjob[0]['invoice'].invoice_id : 'NAN'}</b>
                                                </p>
                                                <p>
                                                    Divison: <b>{usersjob[0].dev ? usersjob[0].dev : ''}</b>
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="row">
                                    <table className="table" style={{ minHeight: '250px' }}>
                                        <thead>
                                            <tr>
                                                <th scope="col">SL No</th>
                                                <th scope="col">Academic Year</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Division</th>
                                                <th scope="col">Fees Type</th>
                                                <th scope="col">Mode</th>
                                                <th scope="col">Reff</th>
                                                <th scope="col">Amount Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>1</th>
                                                <th>{usersjob[0].fees_name.ext_2}</th>
                                                <th>{usersjob[0].class ? usersjob[0].class : ''}</th>
                                                <th>{usersjob[0].dev ? usersjob[0].dev : ''}</th>
                                                <th> {usersjob[0].fees_name.name}</th>
                                                <th> {usersjob[0].ext_1}</th>
                                                <th> {usersjob[0].ext_2}</th>
                                                <th> {usersjob[0].amount}</th>
                                            </tr>
                                        </tbody>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{usersjob[0].amount}</th>
                                        </tr>
                                    </table>
                                    <hr />
                                    <div class="mt-3"></div>
                                    <div className="col-md-4 col-sm-4">
                                        <p className="mb-0"><p className="mb-0">
                                            {usersjob[0]?.user_name?.name ?? " "} {usersjob[0]?.user_name?.last_name ?? " "}
                                        </p>
                                        </p>
                                        <p className="mb-0">.................................</p>
                                        <b>Received By </b>
                                    </div>

                                    <div className="col-md-4 col-sm-4">

                                        <p className="mb-0">.................................</p>
                                        <b>Parent Sign </b>
                                    </div>
                                </div>
                                <b className="mt-3 mb-3"> Note:Please retain the receipt for future reference. </b>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>


    );
}