import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import Select from "react-select";
import { debounce } from "lodash";
import * as XLSX from "xlsx";

export default function DueList() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [devision, setDevision] = useState([]);
    const [subject, setSubjects] = useState([]);
    const [year, setYear] = useState([]);
    const [term, setTerm] = useState([]);
    const [fees, setFeesList] = useState([]);
    const [standard, setstandard] = useState([]);
    const [selclass, setselclass] = useState("");
    const [allStudent, setAllStudent] = useState([]);
    const [selectedStud, setSelectedStudent] = useState("");
    const [selectedClassStud, setselectedClassStud] = useState("100");
    const [selectedDevStud, setselectedDevStud] = useState("");
    const [selectedDevYear, setselectedDevYear] = useState("");
    const [selectedDevStudType, setselectedDevStudType] = useState("");
    const [selectedDevStudTypeStd, setselectedDevStudTypeStd] = useState("");
    const [selectedFeesStud, setselectedFeesStud] = useState("");
    const [amount, setAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [dueDateEnd, setDueDateEnd] = useState("");
    const [stClass, setStClass] = useState([]);
    const [stDev, setstDev] = useState([]);
    const [stYear, setstYear] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        http.get(`/class`).then((res) => {
            setStClass(res.data.data);
        });

        http.get(`/devision`).then((res) => {
            setstDev(res.data.data);
        });

        http.get(`/year`).then((res) => {
            setstYear(res.data.data);
        });

        const valueToSet = selectedStud ? selectedStud.value : " ";
        fetchTaskDetails(selectedClassStud, selectedDevStud, dueDate, dueDateEnd, selectedDevStudType, valueToSet, selectedDevStudTypeStd);

        http.get(`/subject?login_user=${user.id}`).then((res) => {
            setSubjects(res.data.data);
        });

        http.get(`/student_all?company_id=${user.company_id}`).then((res) => {
            setAllStudent(res.data.data);
        });

        // Fetch divisions
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        http.get(`/terms?login_user=${user.id}`).then((res) => {
            setTerm(res.data.data);
        });

        http.get(`/class?login_user=${user.id}`).then((res) => {
            setstandard(res.data.data);
        });

        //year
        http.get(`/year?login_user=${user.id}`).then((res) => {
            setYear(res.data.data);
        });

        // http.get(`/tution_feeslist?login_user=${user.id}`).then((res) => {
        //     setFeesList(res.data.data);
        // });

    }, [selectedClassStud, selectedDevStud, dueDate, dueDateEnd, selectedDevStudType, selectedStud, selectedDevStudTypeStd
    ]);

    const fetchTaskDetails = (selectedClassStuds, selectedDevStuds, dueDates, dueDateEnds, typePay, student, std_type) => {
        setIsLoading(true);
        //  const fetchTaskDetailsDebounced = debounce(fetchTaskDetails, 500);
        let endpoint = `/student_annual_fee_sub_due?class=${selectedClassStuds}&dev=${selectedDevStuds}&start_date=${dueDates}&end_date=${dueDateEnds}&type=${typePay}&student=${student}&company_id=${user.company_id}&std_type=${std_type}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };


    const handleSelectChangeClass = (event) => {
        setselectedClassStud(event.target.value);
    };

    const handleSelectChangeDev = (event) => {
        setselectedDevStud(event.target.value);
    };


    const handleSelectChangeStdType = (event) => {
        setselectedDevStudTypeStd(event.target.value);
    };



    //real time year changing
    const handleSelectChangeYear = (event) => {
        setselectedDevYear(event.target.value);
        http.get(`/tution_feeslists?login_user=${user.id}&year=${event.target.value}`)
            .then((res) => {
                setFeesList(res.data.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };



    const handleSelectChangeDevType = (event) => {
        setselectedDevStudType(event.target.value);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedStudent(selectedOption);
    };
    console.log('drop', selectedStud);

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    };

    const initialState = {
        name: "",
    };

    const deleteTask = async (taskId) => {
        await http
            .delete(`/departments_del/${taskId}`)
            .then((response) => {
                toast.success("Departments Deleted successfully !");
                fetchTaskDetails();
            })
            .catch((err) => {
                console.error("Error deleting task", err);
            });
    };

    const options = allStudent.map((user) => ({
        value: user.id,
        label: `${user.adm_no} - ${user.ext_2}${user.ext_3} - ${user.class}${user.section}  - ${user.mobile}`,
    }));

    const handleClearFields = () => {
        window.location.reload();
    };

    const handleSubmit = async () => {
        if (tasks.length === 0 || !selectedFeesStud) {
            toast.error("Please select a student and fees type before submitting.");
            return true;
        }

        if (tasks.length === 0 || !selectedFeesStud || !amount || !dueDate) {
            toast.error("Please enter due date and amount");
            return;
        }


        try {
            const promises = [];

            for (let i = 0; i < tasks.length; i++) {
                const task = tasks[i];

                const formData = {
                    admn_no: task.admn_no,
                    ext_1: task.roll_no,
                    first_name: task.ext_2,
                    last_name: task.ext_3,
                    class: task.class,
                    dev: task.section,
                    fees_id: selectedFeesStud,
                    student_id: task.id,
                    amount: amount,
                    ext_5: dueDate,
                };

                // Push the promise for each API call to the array
                promises.push(http.post('/annual_fees', formData));
            }

            // Wait for all promises to be resolved
            await Promise.all(promises);

            // If all promises are resolved, show success toast
            toast.success("Fees data updated successfully");
            fetchTaskDetails(selectedStud, selectedClassStud, selectedDevStud, selectedFeesStud);

        } catch (error) {
            console.error("Error submitting tasks:", error);
        }
    };
    function handleSelectChangeFees(event) {
        setselectedFeesStud(event.target.value);
    }

    function handleChangeAmount(event) {
        setAmount(event.target.value)
    }

    function handleChangeDate(event) {
        setDueDate(event.target.value)
    }


    function handleChangeDateEnd(event) {
        setDueDateEnd(event.target.value)
    }



    const convertToExcel = () => {
        // Create a copy of the tasks data and remove the unwanted fields
        const filteredTasks = tasks.map(task => {
            const { ext_1, ext_2, ext_3, fees_id, student_id, ext_6, method, reff, ext_8, ext_9, created_at, updated_at, ext_10, ...filteredTask } = task;

            // Calculate the due amount and add it to the filteredTask
            filteredTask.due = calculateDue(task.amount, task.ext_6);

            // Ensure that fees_name is included in the filtered task
            if (task.fees_name) {
                filteredTask.fees_name = task.fees_name.name; // Assuming fees_name is an object with a 'name' property
            }

            if (task.ext_6) {
                filteredTask.paid_amount = task.ext_6; // Assuming fees_name is an object with a 'name' property
            }

            if (task.amount) {
                filteredTask.master_amount = task.amount; // Assuming fees_name is an object with a 'name' property
            }

            return filteredTask;
        });

        // Convert the filtered data to a worksheet
        const ws = XLSX.utils.json_to_sheet(filteredTasks);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Student_Due_List");
        XLSX.writeFile(wb, "student_due_list.xlsx");
    };

    const totalSum = tasks.reduce((total, task) => {
        return total + parseFloat(task.amount) - parseFloat(task.ext_6 || 0);
    }, 0);

    const totalSumPaid = tasks.reduce((total, task) => {
        return total + parseFloat(task.ext_6 || 0);
    }, 0);

    const totalSumMaster = tasks.reduce((total, task) => {
        return total + parseFloat(task.amount || 0);
    }, 0);


    const handlePrint = () => {
        const content = `
            <html>
            <head>
                <title>Print Table</title>
                <style>
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid black;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            <th className="text-danger">No</th>
                            <th>Adm No</th>
                            <th>Student Name</th>
                            <th>Class</th>
                            <th>Division</th>
                            <th>Fee Type</th>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>Amount(Due)</th>
                            <th>Amount Paid</th>
                            <th>Amount Master</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tasks.map((task, index) => {
            const dueAmount = calculateDue(task.amount, task.ext_6);
            return `
                                <tr key=${index}>
                                    <td>${index + 1}</td>
                                    <td>${task.admn_no}</td>
                                    <td>${task.first_name} ${task.last_name}</td>
                                    <td>${task.class}</td>
                                    <td>${task.dev}</td>
                                    <td>${task.fees_name ? task.fees_name.name : ""}</td>
                                    <td>${task.ext_5}</td>
                                    <td>${task.std_type}</td>
                                    <td><b>${dueAmount}</b></td>
                                    <td><b>${task.ext_6}</b></td> 
                                    <td><b>${task.amount}</b></td>
                                </tr>
                            `;
        }).join('')}
                    </tbody>
                </table>
            </body>
            </html>
        `;


        const printWindow = window.open('', '', 'height=500, width=500');
        printWindow.document.write(content);
        printWindow.document.close();
        printWindow.print();
    };

    const calculateDue = (amount, ext_6) => {
        const due = amount - ext_6;
        return due;
    };
    return (
        <>
            <div className="container">
                <Toaster position="top-right" reverseOrder={false} />

                <div className="row mt-3">
                    <div className="col-md-2  text-end">
                        <select onChange={handleSelectChangeClass} className="form-control">
                            <option value="">Select Class</option>
                            <option value="">All</option>
                            {stClass.map((option, index) => (
                                <option key={index} value={option.class_name}>
                                    {option.class_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-1  text-end">
                        <select onChange={handleSelectChangeDev} className="form-control">
                            <option value=""> Div</option>
                            {stDev.map((options, index) => (
                                <option key={index} value={options.name}>
                                    {options.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-1  text-end ">
                        <select onChange={handleSelectChangeYear} className="form-control border border-danger">
                            <option value=""> Year</option>
                            {stYear.map((options, index) => (
                                <option key={index} value={options.name}>
                                    {options.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-2">
                        <input type="date" className="form-control" onChange={handleChangeDate} />
                    </div>
                    <div className="col-md-2">
                        <input type="date" className="form-control" onChange={handleChangeDateEnd}></input>
                    </div>
                    <div className="col-md-4 ">
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            value={selectedStud} // Set the selected value
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-3  text-end">
                        <select onChange={handleSelectChangeStdType} className="form-control">
                            <option value="">Select Type</option>
                            <option value="New">New</option>
                            <option value="Old">Old</option>
                        </select>
                    </div>




                    <div className="col-md-3  text-end">
                        <select onChange={handleSelectChangeDevType} className="form-control">
                            {loading ? (
                                <option>Loading...</option>
                            ) : (
                                <>
                                    <option value="">Fees Type</option>
                                    {fees.map((options, index) => (
                                        <option key={index} value={options.id}>
                                            {options.name}
                                        </option>
                                    ))}
                                </>
                            )}
                        </select>
                    </div>

                    <div className="col-md-1">
                        <Link to="/fees_item" className="btn bt-sm btn-success" onClick={handleClearFields}>
                            Clear
                        </Link>
                    </div>

                    <div className="col-md-1 mt-1">
                        <div className="col-md-1 ">
                            <button className="btn bt-sm btn-success" onClick={convertToExcel}>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : selectedClassStud == 100 ? (
                        <div className="alert alert-warning" role="alert">
                            Please select a class to filter!
                        </div>
                    ) : (
                        <table id="printableTable" className="table table-striped center_tbl mt-2">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Adm No</th>
                                    <th>Student Name</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Fee Type</th>
                                    <th>Type</th>
                                    <th>Due Date</th>
                                    <th>Amount (<b>{totalSum.toFixed(2)}</b>)</th>
                                    <th>Paid (<b>{totalSumPaid.toFixed(2)}</b>)</th>
                                    <th>Master (<b>{totalSumMaster.toFixed(2)}</b>)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{task.admn_no}</td>
                                        <td>{task.first_name} {task.last_name}</td>
                                        <td>{task.class}</td>
                                        <td>{task.dev}</td>
                                        <td>{task.fees_name ? task.fees_name.name : ""}</td>
                                        <td>{task.std_type}</td>
                                        <td> {task.ext_5 ? moment(task.ext_5).format("DD MMM,YYYY") : ""}</td>
                                        <td><b>{calculateDue(task.amount, task.ext_6)}</b></td>
                                        <td><b>{task.ext_6 ? task.ext_6 : ""}</b></td>
                                        <td><b>{task.amount ? task.amount : ""}</b></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>


            </div >
        </>
    );
}
