import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export default function Tasksview() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [date, setDate] = useState(null);
  const [remark, setRemark] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [selectedDepartmentId, setselectedDepartmentId] = useState("");
  const [selectedStatus, setsselectedStatus] = useState("Pending");

  useEffect(() => {
    fetchTaskDetails(selectedDepartmentId, selectedStatus);
  }, [selectedDepartmentId, selectedStatus]);

  const fetchTaskDetails = (seldepartment = "", selstatus = "") => {
    setIsLoading(true);
    let endpoint;
    if (user && user.department_id === 1) {
      endpoint = `/tasks?department=${seldepartment}&status=${selstatus}&user=${user.id}&login_user=${user.id}`;
    } else if (user) {
      endpoint = `/tasks?user=${user.id}&department=${seldepartment}&status=${selstatus}&login_user=${user.id}`;
    } else {
      setIsLoading(false);
      console.error("User is not defined or authenticated");
      return;
    }

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
        } else {
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });

    http.get(`/departments?login_user=${user.id}`).then((res) => {
      setdepartments(res.data.data);
    });
  };
  const [showModal, setShowModal] = useState(false);
  const [showModalRevise, setShowModalRevise] = useState(false);
  const [ModalId, setModalId] = useState(false);
  const openModal = (taskId) => {
    setShowModal(true);
    setModalId(taskId);
  };

  const openModalRevise = (rmark, revisedate) => {
    setDate(revisedate);
    setRemark(rmark);
    setShowModalRevise(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalRevise(false);
  };
  const handleChange = async (event) => {
    setselectedDepartmentId(event.target.value);
  };

  const handleChangeType = async (event) => {
    setsselectedStatus(event.target.value);
  };

  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const deleteTask = async (taskId) => {
    await http
      .delete(`/tasks/${taskId}`)
      .then((response) => {
        toast.success("Task Deleted successfully !");
        fetchTaskDetails(selectedDepartmentId, selectedStatus);
        //  setTasks(response.data.data);
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Task status updated successfully !");
      fetchTaskDetails(selectedDepartmentId, selectedStatus);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const [formData, setFormData] = useState({
    revised_date: "",
    revised_remark: "",
  });
  const initialState = {
    revised_date: "",
    revised_remark: "",
  };

  const handleChangeModal = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure formData.revised_date is a valid date format (you may need additional validation)
      const revisedDate = new Date(formData.revised_date);

      // Calculate the difference in days between current date and revised date
      const currentDate = new Date();
      const differenceInDays = Math.floor(
        (revisedDate - currentDate) / (24 * 60 * 60 * 1000)
      );

      if (differenceInDays >= 3) {
        toast.error(
          "Revised date should not be more than 3 days from the current day."
        );
        return; // Terminate the performance
      }

      if(formData.revised_remark==''){
        toast.error(
          "Please add revise note"
        );
        return;
      }

      // Continue with the API call and other logic if the date is within the limit
      await http.put(`/revise/${ModalId}`, formData);
      toast.success("Revise date added successfully!");
      setFormData(initialState);
      fetchTaskDetails(selectedDepartmentId, selectedStatus);
      closeModal();
    } catch (error) {
      console.error("Error adding task:", error.response.data);
    }
  };

  const downloadFile = (filename) => {
    // Construct the URL to the file.
    const fileUrl = `http://3.6.125.26/api/storage/tasks/${filename}`;

    // Create a link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", ""); // the download attribute can be empty
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link from the DOM
  };

  return (
    <>
      <Toaster />

      {showModal && (
        <form onSubmit={handleSubmit}>
          <div className="modal" tabindex="-1" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Revision Details</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label>New Revision Date</label>
                    <input
                      name="revised_date"
                      className="form-control"
                      type="date"
                      onChange={handleChangeModal}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>Revision Note</label>
                    <textarea
                      name="revised_remark"
                      className="form-control"
                      onChange={handleChangeModal}
                      rows="3"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {showModalRevise && (
        <div className="modal" tabindex="-1" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Revision Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group  mt-3">
                  <label>New Revision Date</label>
                  <input
                    name="revised_date"
                    className="form-control"
                    type="date"
                    value={date}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>Revision Note</label>
                  <textarea
                    name="revised_remark"
                    className="form-control"
                    rows="3"
                    value={remark}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container mt-1">
        <div className="row mb-2">
          <div className="col-md-4">
            {user && user.department_id == 1 ? (
              <h1 className="mb-1">Your Tasks List</h1>
            ) : (
              <h1 className="mb-1">Your Tasks List</h1>
            )}
          </div>
          {user && user.department_id == 1 && (
            <div className="col-md-4 form-group text-end">
              <select onChange={handleChange} className="form-control mb-1">
                <option value="">Select Department</option>
                <option value="">All Department</option>
                {departments.map((depart) => (
                  <option key={depart.id} value={depart.id}>
                    {depart.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-md-4 form-group text-end">
            <select onChange={handleChangeType} className="form-control">
              <option value="">Select Status</option>
              <option value="">All Status</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Progress">Progress</option>
              <option value="Delayed">Delayed</option>
              <option value="Delayed Completed">Delayed Completed</option>
            </select>
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped">
              <thead className="table-primary">
                <tr>
                  <th>No</th>
                  <th>Id</th>
                  <th className="text-danger">Created On</th>
                  <th>Description</th>
                  <th>Assign To</th>
                  <th>Department</th>
                  <th>Status</th>
                  <th>Additional info</th>
                  <th>Due Date</th>
                  <th>ET</th>
                  <th>W</th>
                  <th>Priority</th>
                  <th>Assign By</th>

                  <th>Status</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>T{user.id}</td>
                    <td>{moment(user.created_at).format("DD MMM,YY hh:mm A")}</td>
                    <td>
                      <b>{user.jc_id ? "JC-" + user.jc_id : ""} </b>
                      {user.description}
                    </td>
                    <td className="text-uppercase fw-bold">
                      {user.assignee?.name} {user.assignee?.last_name}
                      {user.task_file && (
                        // <button className='btn btn-link' onClick={() => downloadFile(user.task_file)}>
                        //      File
                        // </button>
                        <FontAwesomeIcon
                          icon={faFile}
                          className="mx-2"
                          onClick={() => downloadFile(user.task_file)}
                        />
                      )}
                    </td>
                    <td>{user.department?.name}</td>
                    <td>
                      {" "}
                      {user.status === "Completed" && (
                        <span className="badge bg-success font-size-13">
                          Completed
                        </span>
                      )}
                      {user.status === "Pending" && (
                        <span className="badge bg-danger font-size-13">
                          Pending
                        </span>
                      )}
                      {user.status === "Delayed" && (
                        <span className="badge bg-warning font-size-13">
                          Delayed
                        </span>
                      )}
                      {user.status === "Delayed Completed" && (
                        <span className="badge bg-warning font-size-13">
                          Delayed Completed
                        </span>
                      )}
                      {user.status === "Progress" && (
                        <span className="badge bg-secondary font-size-13">
                          Progress
                        </span>
                      )}
                    </td>
                    <td>{user.additional_info}</td>
                    <td>
                      {moment(user.target_date).format("DD MMM,YY")}{" "}
                      {user.is_revised == 1 && (
                        <a
                          href="#"
                          className="badge bg-danger font-size-13"
                          onClick={() =>
                            openModalRevise(
                              user.revised_remark,
                              user.revised_date
                            )
                          }
                        >
                          R
                        </a>
                      )}
                    </td>
                    <td>{user.est_time}</td>
                    <td>{user.task_weightage}</td>
                    <td>{user.priority}</td>
                    <td className="text-uppercase fw-bold">
                      {user.assigner?.name} {user.assigner?.last_name}
                    </td>

                    <td>
                      <>
                        <select
                          onChange={statusUpdate}
                          className="form-select-sm mb-2"
                        >
                          <option value="">Status</option>
                          <option value="Progress">Progress</option>
                          <option value="Completed">Completed</option>
                        </select>
                        <button
                          className="btn-sm btn btn-link"
                          onClick={() => handleSave(user.id)}
                        >
                          Save
                        </button>
                        <button
                          className="btn-sm btn btn-link"
                          onClick={() => openModal(user.id)}
                        >
                          Revise
                        </button>
                      </>
                      {loginUser === 1 ? (
                        <button
                          className="button btn-sm btn-danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this task?"
                              )
                            ) {
                              deleteTask(user.id);
                            }
                          }}
                        >
                          Delete
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
