import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';



export default function Departmenttask() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [remark, setRemark] = useState(null);
    const [departments, setdepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setloginUser] = useState(user.department_id);
    const [selectedDepartmentId,setselectedDepartmentId] = useState(user.department_id);
    const [selectedStatus,setsselectedStatus] = useState("Pending");
    useEffect(() => {
        fetchTaskDetails();
    }, [selectedDepartmentId,selectedStatus]);


    const fetchTaskDetails = () => {
        let endpoint = `/get_department_task?department=${selectedDepartmentId}&status=${selectedStatus}&login_user=${user.id}`;

        http.get(endpoint).then((res) => {
            if (Array.isArray(res.data.data)) {
                setTasks(res.data.data);
            } else {
                console.error("API response is not an array");
            }
        }).catch(error => {
            console.error("Error fetching tasks:", error);
        });
        console.log(tasks);

        http.get('/departments').then((res) => {
            setdepartments(res.data.data);
        })
    }
    const [showModal, setShowModal] = useState(false);
    const [showModalRevise, setShowModalRevise] = useState(false);
    const [ModalId, setModalId] = useState(false);
    const openModal = (taskId) => {
        setShowModal(true);
        setModalId(taskId);
    }

    const openModalRevise = (rmark,revisedate) => {
        setDate(revisedate);
        setRemark(rmark);
        setShowModalRevise(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setShowModalRevise(false);
    }
    const handleChange = async (event) => {
        setselectedDepartmentId(event.target.value);
    };

    const statusUpdate = (e) => {
        setCurrentStatus(e.target.value);
    }

    const handleSave = async (taskId) => {
        if (currentStatus === null || taskId === null) {
            toast.error("Please select proper status");
            return true;
        }
        try {
            const response = await http.put(`task_status_update?task_id=${taskId}&status=${encodeURIComponent(currentStatus)}`);
            toast.success("Task status updated successfully !");
            fetchTaskDetails();
        } catch (error) {
            console.error("Error fetching tasks:", error);
            toast.error("Please select proper item");
        }

    }
    
    const [formData, setFormData] = useState({
        revised_date: '',
        revised_remark: '',
    });
    const initialState={
        revised_date: '',
        revised_remark: '',
    }

    const handleChangeModal = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            http.put(`/revise/${ModalId}`, formData).then((res) => {
                toast.success("revise date added successfully !");
                setFormData(initialState);
                fetchTaskDetails();
                closeModal();
            })
        } catch (error) {
            console.error('Error adding task:', error.response.data);
        }
    };

    const handleChangeType = async (event) => {
        setsselectedStatus(event.target.value);
       
    }

    function getRandomLightColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const backgroundColorMap = new Map();

    // Function to get a unique background color for a user.id
    function getUniqueBackgroundColor(userId) {
        if (!backgroundColorMap.has(userId)) {
            // If the user.id doesn't have a background color assigned, generate one and store it in the map
            backgroundColorMap.set(userId, getRandomLightColor());
        }
        return backgroundColorMap.get(userId);
    }
 
    
    return (
        <>
            <Toaster />

            {showModal && (
                <form onSubmit={handleSubmit}>
                <div className="modal" tabindex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Revision Details</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className='form-group  mt-3'>
                                    <label>New Revision Date</label>
                                    <input name="revised_date" className='form-control' type="date" onChange={handleChangeModal} />
                                </div>

                                <div className='form-group mt-3'>
                                    <label>Revision Note</label>
                                    <textarea
                                        name="revised_remark"
                                        className='form-control' onChange={handleChangeModal}
                                        rows="3"
                                    />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                <button type="submit" className="btn btn-primary" >Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            )}

        {showModalRevise && (
               
                <div className="modal" tabindex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Revision Details</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className='form-group  mt-3'>
                                    <label>New Revision Date</label>
                                    <input name="revised_date" className='form-control' type="date" value={date}/>
                                </div>

                                <div className='form-group mt-3'>
                                    <label>Revision Note</label>
                                    <textarea
                                        name="revised_remark"
                                        className='form-control' 
                                        rows="3" value={remark}
                                    />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
               
            )}

            <div className="container mt-1">
              
                    <div className='row'>
                    <div className='col-md-4'>
                        <h1 className="mb-4">Your Department Tasks</h1>
                    </div>
                    {user && user.department_id == 1 && (
                       
                        <div className='form-group col-md-4 text-end'>
                            <select onChange={handleChange} className='form-control'>
                                <option value="">Select Department</option>
                                <option value="">All Department</option>
                                {departments.map(depart => (
                                    <option key={depart.id} value={depart.id} >{depart.name}</option>
                                ))}

                            </select>
                        </div>
                        )}

                    <div className='col-md-4 form-group text-end'>
                        <select onChange={handleChangeType} className='form-control'>
                            <option value="">Select Status</option>
                            <option value="">All Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Completed</option>
                            <option value="Progress">Progress</option>
                            <option value="Delayed">Delayed</option>
                            <option value="Delayed Completed">Delayed Completed</option>
                        </select>
                    </div>
                    </div>
                
                
                <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>

                            <th className='text-danger'>Created On</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Status</th>
                            <th>Additional info</th>
                            <th>Due Date</th>
                            <th>Weightage</th>
                            <th>Priority</th>
                            <th>Assigned By</th>
                            

                            {/* <th>Action</th> */}

                            {/* Add other fields as necessary */}
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map(user => (

                            <tr key={user.id}>
                                <td>{moment(user.created_at).format('DD MMM,YY')}</td>
                                <td>{user.description}</td>
                                <td className={`text-uppercase fw-bold ${user.assignee?.name && user.assignee?.last_name ? 'background-color-class' : ''}`}
                                            style={{ color: getUniqueBackgroundColor(user.assignee?.id) }}
                                        >
                                            {user.assignee?.name} {user.assignee?.last_name}
                                            
                                        </td>
                                <td>{user.department?.name}</td>
                                <td> {user.status === 'Completed' && <span className="badge bg-success font-size-13">Completed</span>}
                                    {user.status === 'Pending' && <span className="badge bg-danger font-size-13">Pending</span>}
                                    {user.status === 'Progress' && <span className="badge bg-secondary font-size-13">Progress</span>}
                                    {user.status === 'Delayed' && <span className="badge bg-warning font-size-13">Delayed</span>}
                                    {user.status === 'Delayed Completed' && <span className="badge bg-warning font-size-13">Delayed Completed</span>}
                                    </td>
                                <td>{user.additional_info}</td>
                                <td>{moment(user.target_date).format('DD MMM,YY')} {user.is_revised==1 &&(
                                    <a href='#' className="badge bg-danger font-size-13" onClick={()=>openModalRevise(user.revised_remark,user.revised_date)} >R</a>
                                )}</td>
                                 
                                <td>{user.task_weightage}</td>
                                <td>{user.priority}</td>
                                <td>{user.assigner?.name} {user.assigner?.last_name}</td>
                                

                                {/* <td>
                                <button className='button btn-sm btn-danger'>Delete</button>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>

            </div>
        </>
    )
}
