import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Group_task() {
  const { http, user } = AuthUser();
  const [formData, setFormData] = useState({
    name: "",
  });
  const navigate = useNavigate();
  const initialState = {
    name: "",
  };

  const [departmetsall, setdepartmentsall] = useState([]);
  const [department, setDepartment] = useState([]);
  const [usersSel, setUsersSel] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    http.get(`/departments?login_user=${user.id}`).then((res) => {
      setdepartmentsall(res.data.data);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      login_user: user.id,
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "department_id") {
      fetchUsersForDepartment(e.target.value);
    }
  };

  const fetchUsersForDepartment = async (departmentId) => {
    try {
      const response = await http.get(
        `/department_user?department=${departmentId}&login_user=${user.id}`
      );
      setUsersSel(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Handle the selected users here and post them to the server
      const selectedUsers = usersSel
        .filter((user) => user.selected)
        .map((user) => user.id);

      const res = await http.post("/post_group", {
        group_name: formData.name,
        dept_id: formData.department_id,
        sel_users: selectedUsers,
        ext_1: user.company_id,
        user_id: user.id,
      });

      setFormData(initialState);
      setTimeout(() => {
        navigate("/group_tasks");
      }, 1000); // 10 seconds
      toast.success("Group added successfully!");
    } catch (error) {
      // Handle error
      console.error("Error adding group:", error);
    }
  };

  const handleUserCheckboxChange = (userId) => {
    setUsersSel((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, selected: !user.selected } : user
      )
    );
  };
  console.log(user);
  return (
    <>
      <div className="container mt-5">
        <Toaster />
        <h3>Create Groups</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>Group Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Department</label>

            <select
              name="department_id"
              className="form-control"
              value={formData.department_id}
              onChange={handleChange}
              required
            >
              <option value="">Select Department</option>
              {departmetsall.map((depart) => (
                <option key={depart.id} value={depart.id}>
                  {depart.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mt-3">
            <label>Users</label>
            {usersSel.map((user) => (
              <div key={user.id} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`userCheckbox_${user.id}`}
                  checked={user.selected || false}
                  onChange={() => handleUserCheckboxChange(user.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`userCheckbox_${user.id}`}
                >
                  {user.name} {user.last_name}
                </label>
              </div>
            ))}
          </div>

          <button className="btn button btn-success mt-3" type="submit">
            Create
          </button>
        </form>
      </div>
    </>
  );
}
