import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Customeradd() {
  const navigate = useNavigate();
  const { http, user } = AuthUser();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    aadress: "",
    email: "",
  });

  const initialState = {
    name: "",
    company: "",
    phone: "",
    email: "",
    aadress: "",
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    try {
      http.post("/company_add_job", form, {}).then((res) => {
        toast.success("Company added successfully !");
        setFormData(initialState);
        navigate("/jobcart/customer");
      });
    } catch (error) {
      console.error("Error adding company:", error.response.data);
    }
  };
  return (
    <>
      <div className="container mt-5">
        <Toaster />
        <h3>Add Customer</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-3">
            <label>Company Name</label>

            <input
              name="company"
              className="form-control"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Person Name</label>

            <input
              name="name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Phone</label>

            <input
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Email</label>

            <input
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-3">
            <label>Location/Address</label>
            <textarea
              name="address"
              onChange={handleChange}
              className="form-control"
              required
            ></textarea>
          </div>
          <button className="btn button btn-success mt-3" type="submit">
            Add
          </button>
        </form>
      </div>
    </>
  );
}
