import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";

export default function RegisterJob() {
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();
    const [name, setName] = useState();
    const [last_name, setLastName] = useState();
    const [department_id, setDepartment] = useState();
    const [designation_name, setdesignation] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [company_id, setcompanyid] = useState();
    const [phone_number, setphonenumber] = useState();


    const [companyid, setCompanyid] = useState('');
    const [departments, setDepartments] = useState([]);
    const [noDepartments, setNoDepartments] = useState(true);

    if (typeof phone_number === 'string' && phone_number.length > 10) {
        toast.error('Phone Number must be less than or equal to 10 digits');
    }


    const submitForm = (e) => {
        // api call
        e.preventDefault();
        if (typeof phone_number === 'string' && phone_number.length > 10) {
            toast.error('Phone Number must be less than or equal to 10 digits');
            return;
        }
        if (typeof phone_number === 'string' && phone_number.length < 7) {
            toast.error('Phone Number must be greater than 7 digits');
            return;
        }
        http.post('/register_job', { email: email, password: password, name: name, last_name: last_name, department_id: department_id, designation_name: designation_name, phone_number: phone_number, company_id: company_id }).then((res) => {
            toast.success("Successfuly registered, Wait for the admin approval !");
            setTimeout(() => {
                navigate('/jobcart/login');
            }, 100); // 10 seconds

        })
            .catch(error => {
                if (error.response && error.response.data.message.includes("SQLSTATE[23000]")) {
                    toast.error('This email is already registered. Please choose another email.');
                } else {
                    toast.error('An error occurred while registering. Please try again.');
                }
            });
    }

    useEffect(() => {
        setNoDepartments(true);
    }, [])

    function validatePhone(input) {
        console.log(input);
        const phonePattern = /^\d{10}$/; // Pattern for 10 digits only
        const errorElement = document.getElementById('error');

        if (!phonePattern.test(input.value) && input.value.length) {
            errorElement.textContent = 'Please enter a valid 10 digit phone number.';
        } else {
            errorElement.textContent = '';
        }
    }

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        setCompanyid(companyId);

        if (companyId) {
            // Make an API request to fetch departments
            http.get(`/company_dept/${companyId}/departments`)
                .then((response) => {

                    if (response.data.company !== 'zero') {
                        // Company exists, add is-valid class
                        e.target.classList.remove('is-invalid');
                        e.target.classList.add('is-valid');
                    } else {
                        // Company doesn't exist, add is-invalid class
                        e.target.classList.remove('is-valid');
                        e.target.classList.add('is-invalid');
                    }
                    setcompanyid(response.data.company_id);
                    const fetchedDepartments = response.data.departments;
                    if (fetchedDepartments.length > 0) {
                        setDepartments(fetchedDepartments);
                        setNoDepartments(false);
                    } else {

                        // If there are no departments, set the state to show "Super Admin"
                        setDepartments([]);
                        setNoDepartments(true);
                    }
                })
                .catch((error) => {
                    e.target.classList.remove('is-valid');
                    e.target.classList.add('is-invalid');
                    setcompanyid('');
                    // Handle errors, e.g., company not found
                    setDepartments([]);
                    setNoDepartments(true);
                });
        } else {
            // If companyId is empty, reset the input border
            e.target.classList.remove('is-valid', 'is-invalid');
            setcompanyid('');
            setDepartments([]);
            setNoDepartments(true);
        }
    }

    console.log(company_id);


    return (
        <div className="row justify-content-center pt-5">
            <Toaster />
            <div className="col-sm-8 ">
                <div className="card p-4 wall">
                    <form onSubmit={submitForm}>
                        <h1 className="text-center mb-3">Register </h1>

                        <div className="form-group mt-3">
                            <label>School Code</label>
                            <input
                                type="text"
                                name="company_id"
                                className="form-control"
                                placeholder="EG: KA1010"
                                onChange={handleCompanyChange}
                                required
                            />
                        </div>


                        <div className="form-group mt-3">
                            <label htmlFor="department">Main Branch:</label>
                            <select
                                className="form-control text-uppercase"
                                id="department_id"
                                name="department_id"
                                required onChange={e => setDepartment(e.target.value)}
                            >
                                {
                                    departments.map((depart) => (
                                        <option key={depart.id} value={depart.id}>
                                            {depart.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="view_branch">View Branches</label>

                            <div>
                                <input type="checkbox" id="k1" name="k1" />
                                <label htmlFor="k1">K1</label>
                            </div>

                            <div>
                                <input type="checkbox" id="k2" name="k2" />
                                <label htmlFor="k2">K2</label>
                            </div>
                        </div>

                        <div className="form-group mt-3">
                            <label>First Name:</label>
                            <input type="text" className="form-control text-uppercase" placeholder="Enter name" name="name"
                                onChange={e => setName(e.target.value)}
                                id="name" required />
                        </div>

                        <div className="form-group mt-3">
                            <label>Last Name:</label>
                            <input type="text" className="form-control text-uppercase" placeholder="Enter name" name="last_name"
                                onChange={e => setLastName(e.target.value)}
                                id="last_name" required />
                        </div>
                        <div className="form-group mt-3">
                            <label>Phone No:</label>
                            <input type="number" name="phone_number" maxLength="10" className="form-control" placeholder="Enter Phone Number"
                                onChange={e => setphonenumber(e.target.value)}
                                id="phone_number" required />

                        </div>


                        <div className="form-group mt-3">
                            <label>Email address:</label>
                            <input type="email" className="form-control" placeholder="Enter email"
                                onChange={e => setEmail(e.target.value)}
                                id="email" name="email" required />
                        </div>

                        <div className="form-group mt-3">
                            <label>Password:</label>
                            <input type="password" name="password" className="form-control" placeholder="Enter password"
                                onChange={e => setPassword(e.target.value)}
                                id="pwd" required />
                        </div>

                        <button type="submit" className="btn btn-primary mt-4">Register</button>
                    </form>
                </div>
            </div>
        </div>
    )
}