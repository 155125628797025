import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export default function Stocks() {
  const { http, user } = AuthUser();
  const [tasks, setTasks] = useState([]);
  const [TotalCount, setTotalCount] = useState();
  const [TotalCountMRP, setTotalCountMRP] = useState();
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loginUser, setloginUser] = useState(user.department_id);
  const [selectedStatus, setsselectedStatus] = useState();
  const [selectedStatusFinal, setsselectedStatusFinal] = useState("OPEN");
  const [selectedtp, setsselectedtp] = useState();
  const [selectedPurpose, setsselectePuropse] = useState();
  const [selectedAssigner, setsselecteAssiner] = useState();
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [cat, setCat] = useState([]);
  const [masterdata, setmasterdata] = useState([]);
  const [masterdataIndi, setMasterdataIndi] = useState([]);
  const [tasksind, setTasksInd] = useState([]);
  const [editColumn, setEditColumn] = useState(null);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    http.get(`/category`).then((res) => {
      setCat(res.data.data);
    });
    http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
      setUsers(res.data.users);
    });
    fetchTaskDetails(
      selectedStatus,
      selectedtp,
      selectedPurpose,
      selectedAssigner,
      selectedStatusFinal
    );
  }, [
    selectedStatus,
    selectedtp,
    selectedPurpose,
    selectedAssigner,
    selectedStatusFinal,
  ]);

  const fetchTaskDetails = (
    selectedStatus = "",
    selectedtp = "",
    selectedPurpose = "",
    selectedAssigners = "",
    selFinal = ""
  ) => {
    setIsLoading(true);
    let endpoint = `/stocks?status=${selectedStatus}&type=${selectedtp}&purpose=${selectedPurpose}&assign=${selectedAssigners}&finalstatus=${selFinal}`;

    http
      .get(endpoint)
      .then((res) => {
        setIsLoading(false);
        if (Array.isArray(res.data.data)) {
          setTasks(res.data.data);
          setTotalCount(res.data.total_count);
          setTotalCountMRP(res.data.total_count_mrp);
        } else {
          setIsLoading(false);
          console.error("API response is not an array");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };
  console.log(tasks);
  const statusUpdate = (e) => {
    setCurrentStatus(e.target.value);
  };

  const handleSave = async (taskId) => {
    if (currentStatus === null || taskId === null) {
      toast.error("Please select proper status");
      return true;
    }
    try {
      const response = await http.put(
        `task_status_update?task_id=${taskId}&status=${encodeURIComponent(
          currentStatus
        )}`
      );
      toast.success("Task status updated successfully !");
      fetchTaskDetails();
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Please select proper item");
    }
  };

  const initialState = {
    revised_date: "",
    revised_remark: "",
  };

  const handleChangeModal = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "category") {
      fetchUsersForDepartment(e.target.value);
    }
    if (e.target.name === "master_id") {
      fetchMasterIndividual(e.target.value);
    }
  };

  const fetchUsersForDepartment = async (departmentId) => {
    try {
      const response = await http.get(`/master_cat?cat=${departmentId}`);
      setmasterdata(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const deleteTask = async (taskId) => {
    console.log(taskId);
    await http
      .delete(`/stock_del/${taskId}`)
      .then((response) => {
        toast.success("Stock Deleted successfully !");
        fetchTaskDetails();
      })
      .catch((err) => {
        console.error("Error deleting task", err);
      });
  };

  const handleChangeType = async (event) => {
    setsselectedStatus(event.target.value);
  };

  const handleChangeTypeFinal = async (event) => {
    setsselectedStatusFinal(event.target.value);
  };

  const handleChangeTp = async (event) => {
    setsselectedtp(event.target.value);
  };

  const handleChangePurpose = async (event) => {
    setsselectePuropse(event.target.value);
  };

  const handleChangePurposeCust = async (event) => {
    setsselecteAssiner(event.target.value);
  };

  const [formData1, setFormData1] = useState({
    master_id: "",
    status: "",
    final_status: "",
  });

  const openEditModal = async (taskAll, index) => {
    setEditColumn(index);
    setShowModal(true);
    setFormData1({
      master_id: taskAll.master_id || "",
      status: taskAll.status || "",
      final_status: taskAll.final_status || "",
    });
    setEditUser(taskAll);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditColumn("");
  };
  const saveEditedCustemer = async (e) => {
    e.preventDefault();
    try {
      if (!editUser) {
        // Handle the case when editUser is null
        console.error("No user data to edit.");
        return;
      }

      const response = await http.put(`/stocks_edit/${editUser.id}`, formData1);
      if (Array.isArray(response.data.data)) {
        toast.success("Stock data updated successfully!");
        setShowModal(false);
        fetchTaskDetails(selectedStatus,
          selectedtp,
          selectedPurpose,
          selectedAssigner,
          selectedStatusFinal);
      } else {
        console.log("API response is not an array");
      }
    } catch (error) {
      console.error("Error updating user", error);
      toast.error("An error occurred while updating the customer.");
    }
    setEditColumn("");
  };

  const fetchMasterIndividual = async (msterId) => {
    try {
      const response = await http.get(`/master_cat_indi?cat=${msterId}`);
      setMasterdataIndi(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const DropdownOption = ({ user }) => {
    return (
      <option key={user.id} value={user.id}>
        {user.item_name}
      </option>
    );
  };

  const downloadFile = (filename) => {
    // Construct the URL to the file.
    const fileUrl = `http://3.6.125.26/api/storage/tasks/${filename}`;

    // Create a link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", ""); // the download attribute can be empty
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link from the DOM
  };

  return (
    <>
      <Toaster />
      {showModal && (
        <form onSubmit={saveEditedCustemer}>
          <div className="modal" tabindex="-1" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Edit Stocks of {formData1.customer}{" "}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group  mt-3">
                    <label> Category </label>
                    <select
                      name="category"
                      className="form-control"
                      value={formData1.category}
                      onChange={handleChangeModal}
                    >
                      <option value="">Select Any</option>
                      {cat.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <label>Stocks</label>
                    <select
                      disabled={!masterdata.length}
                      name="master_id"
                      className="form-control text-uppercase"
                      onChange={handleChangeModal}
                    >
                      <option value="">Select Stocks</option>
                      {masterdata.map((user) => (
                        <DropdownOption key={user.id} user={user} />
                      ))}
                    </select>
                    {masterdataIndi.length > 0 && (
                      <div>
                        <label className="badge bg-success mx-2">
                          MRP: {masterdataIndi[0].mrp}
                        </label>
                        {/* <label className="badge bg-secondary mt-2 mx-2">
                          Supplier: {masterdataIndi[0].suplier}
                        </label>
                        <label className="badge bg-secondary  ">
                          Vendor: {masterdataIndi[0].vendor}
                        </label> */}
                      </div>
                    )}
                  </div>

                  <div className="form-group mt-3">
                    <label>Status</label>
                    <select
                      name="status"
                      className="form-control"
                      value={formData1.status}
                      onChange={handleChangeModal}
                    >
                      <option value="PENDING">PENDING</option>
                      <option value="RECEIVED">RECEIVED</option>
                      <option value="HOLD">HOLD</option>
                      <option value="INVOICED">INVOICED </option>
                      <option value="WARRANTY">WARRANTY </option>
                      <option value="CONSUMPTION">CONSUMPTION </option>
                      <option value="INTERNAL">INTERNAL </option>
                      <option value="RETURNED">RETURNED </option>
                    </select>
                  </div>

                  <div className="form-group mt-3">
                    <label>Final Status</label>
                    <select
                      name="final_status"
                      className="form-control"
                      value={formData1.final_status}
                      onChange={handleChangeModal}
                      required
                    >
                      <option value="">Select Any</option>
                      <option value="OPEN">OPEN</option>
                      <option value="CLOSE">CLOSE</option>
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      <div className="container mt-1">
        <div className="row">
        <div className="col-8">
      {user && user.department_id === 1 ? (
      <div className="row">
        <div className="col-3">
          <h1 className="mb-1">
            Total Price
            <span className="text-primary">({TotalCount})</span>
          </h1>
        </div>
        <div className="col-3">
          <h1 className="mb-1">
            Total MRP
            <span className="text-primary">({TotalCountMRP})</span>
          </h1>
        </div>
      </div>
  ) : (
    <h1 className="mb-1">Stocks</h1>
  )}
</div>

          <div className="col-4 text-end mt-4">
           
              <Link className="btn-link mb-3" to="/jobcart/stocks_add">
                Add Stocks
              </Link>
         
          </div>

          <div className="row mb-2">
            <div className="col-md-2 form-group text-end">
              <select onChange={handleChangeType} className="form-control">
                <option value="">All Status</option>
                <option value="PENDING">PENDING</option>
                <option value="RECEIVED">RECEIVED</option>
                <option value="HOLD">HOLD</option>
                <option value="INVOICED ">INVOICED </option>
                <option value="WARRANTY ">WARRANTY </option>
                <option value="CONSUMPTION ">CONSUMPTION </option>
                <option value="INTERNAL ">INTERNAL </option>
              </select>
            </div>

            <div className="col-md-3 form-group text-end">
              <select onChange={handleChangeTypeFinal} className="form-control">
                <option value="">Final Status</option>
                <option value="CLOSE">CLOSE</option>
                <option value="OPEN">OPEN</option>
              </select>
            </div>
            <div className="col-md-2 form-group text-end">
              <select onChange={handleChangeTp} className="form-control">
                <option value="">All Type</option>
                <option value="PURCHASE">PURCHASE</option>
                <option value="STORES">STORES</option>
              </select>
            </div>
            <div className="col-md-2 form-group text-end">
              <select className="form-control" onChange={handleChangePurpose}>
                <option value="">All Purpose</option>
                <option value="Normal">For Stock</option>
                <option value="For Client">For Client</option>
                <option value="For MSL">For MSL</option>
                <option value="For Work">For Work</option>
              </select>
            </div>

            <div className="col-md-3 form-group text-end">
              <select
                name="assigned_to"
                className="form-control text-uppercase"
                onChange={handleChangePurposeCust}
              >
                <option value="">All</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name} {user.last_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <TaskSkeleton />
          ) : (
            <table className="table table-striped center_tbl">
              <thead className="table-primary">
                <tr>
                  <th className="text-danger">No</th>
                  <th>Date</th>
                  <th>Assigner</th>
                  <th>Type</th>
                  <th>JC-No</th>
                  <th>Item Name</th>
                  <th>Brand</th>
                  <th>Qty</th>
                  <th>customer</th>
                  <th>purpose</th>
                  <th>priority</th>
                  <th>Plant</th>
                  
                  {user.department_id === 1 &&
                  <th>Supplier</th>}
                  
                  {user.department_id === 1 && <th>Price*QTY</th>}
                  <th>MRP*QTY</th>
                  <th>Status</th>
                  <th>Final Status</th>
                  <th>Action</th>

                  {/* Add other fields as necessary */}
                </tr>
              </thead>
              <tbody>
                {tasks.map((taskuser, index) => (
                  <tr
                    className={editColumn === index ? "highlight" : ""}
                    key={taskuser.id}
                  >
                    <td>{index + 1}</td>
                    <td width="9%">
                      {moment(taskuser.created_at).format("DD MMM,YY hh:mm A")}
                    </td>
                    <td className="text-uppercase">{taskuser.assigner?.name}</td>
                    <td>{taskuser.type}</td>
                    <td>{taskuser.jc}</td>
                    <td width="16%">{taskuser.master?.item_name} 
                    {taskuser.ext2 && (
                    <FontAwesomeIcon
                          icon={faFile}
                          className="mx-2"
                          onClick={() => downloadFile(taskuser.ext2)}
                        />
                      )}
                    </td>
                    <td width="6%">{taskuser.brand}</td>
                    <td>{taskuser.qty}</td>
                    <td width="11%">{taskuser.customer}</td>
                    <td width="11%">{taskuser.purpose}</td>
                    <td>{taskuser.priority}</td>
                    <td>{taskuser.plant}</td>
                   
                    {user.department_id === 1 &&<td>{taskuser.master?.suplier}</td>}
                    
                    {user.department_id === 1 && (
                      <td>{taskuser.master?.price * taskuser.qty}</td>
                    )}
                    <td>{taskuser.master?.mrp * taskuser.qty}</td>


                    <td>
                      {taskuser.status === "PENDING" && (
                        <span className="badge bg-danger font-size-13">
                          PENDING
                        </span>
                      )}
                      {taskuser.status === "RECEIVED" && (
                        <span className="badge bg-success font-size-13">
                          RECEIVED
                        </span>
                      )}
                      {taskuser.status === "HOLD" && (
                        <span className="badge bg-secondary font-size-13">
                          HOLD
                        </span>
                      )}
                      {taskuser.status === "INVOICED" && (
                        <span className="badge bg-secondary font-size-13">
                          INVOICED
                        </span>
                      )}
                      {taskuser.status === "WARRANTY" && (
                        <span className="badge bg-secondary font-size-13">
                          WARRANTY
                        </span>
                      )}
                      {taskuser.status === "CONSUMPTION" && (
                        <span className="badge bg-secondary font-size-13">
                          CONSUMPTION
                        </span>
                      )}
                      {taskuser.status === "INTERNAL" && (
                        <span className="badge bg-secondary font-size-13">
                          INTERNAL
                        </span>
                      )}

                      {taskuser.status === "RETURNED" && (
                        <span className="badge bg-secondary font-size-13">
                          RETURNED
                        </span>
                      )}
                    </td>
                    <td>
                      <b>{taskuser.final_status}</b>
                    </td>
                    <td width="8%">
                      {user.department_id === 1 && (
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this Stocks?"
                              )
                            ) {
                              deleteTask(taskuser.id);
                            }
                          }}
                        >
                          D
                        </button>
                      )}

                      <button
                        className="btn  btn-sm btn-link"
                        onClick={() => openEditModal(taskuser, index)}
                      >
                        ✏️
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
