import React from 'react'

export default function TaskSkeleton() {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-table">
                {[...Array(15)].map((_, idx) => ( // render 5 skeleton rows, you can adjust as needed
                    <div key={idx} className="skeleton-row">
                        {[...Array(11)].map((_, index) => ( // render 11 skeleton cells, according to your table
                            <div key={index} className="skeleton-cell"></div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
