import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import TaskSkeleton from './TaskSkeleton';



export default function Users() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { http, user } = AuthUser();
    const [selectedDepartmentId, setselectedDepartmentId] = useState("");
    const [selectedStatus, setselectedStatus] = useState("");
    const [departments, setdepartments] = useState([]);
    const [designation, setdesignation] = useState([]);
    const [editUser, setEditUser] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUserDetail(selectedDepartmentId, selectedStatus);
    }, [selectedDepartmentId, selectedStatus]);

    const fetchUserDetail = (dept, status) => {
        setIsLoading(true);
        if (user && user.department_id != 1) {
            setIsLoading(false);
            toast.error("You dont have access!!");
            navigate('/dashboard');
            return true;
        }
        http.get(`/users?dept=${dept}&status=${status}&login_user=${user.id}`).then((res) => {
            setIsLoading(false);
            if (Array.isArray(res.data.data)) {  // Ensure that the data is an array
                setUsers(res.data.data);
            } else {
                setIsLoading(false);
                console.log("API response is not an array");
            }
        });
        http.get(`/departments?login_user=${user.id}`).then((res) => {
            setdepartments(res.data.data);
        })

        http.get('/designation').then((res) => {
            setdesignation(res.data.data);
        })

    }
    const updateStatus = async (id_user) => {
        http.post('/approve', { userId: id_user }).then((res) => {
            if (Array.isArray(res.data.data)) {  // Ensure that the data is an array
                fetchUserDetail(selectedDepartmentId, selectedStatus);
                toast.success("Approved successfully !");
            } else {
                console.log("API response is not an array");
            }

        })
            .catch((error) => {
                console.log("Error:", error.response.data);
                toast.error("An error occurred while approving.");
            });
    }

    const updateStatusDis = async (id_user) => {
        http.post('/disapprove', { userId: id_user }).then((res) => {
            if (Array.isArray(res.data.data)) {  // Ensure that the data is an array
                fetchUserDetail(selectedDepartmentId, selectedStatus);
                toast.success("User DisaApproved successfully !");
            } else {
                console.log("API response is not an array");
            }

        })
            .catch((error) => {
                console.log("Error:", error.response.data);
                toast.error("An error occurred while approving.");
            });
    }



    const handleChangeType = async (event) => {
        setselectedStatus(event.target.value);
    };

    const handleChangeDept = async (event) => {
        setselectedDepartmentId(event.target.value);
    }

    const sendEmail = async () => {
        try {
            const response = await http.post('/sendmail', {});
            console.log('Email sent successfully', response.data);
        } catch (error) {
            console.error('Error sending email', error);
        }
    }

    const deleteUser = async (userId) => {
        await http.delete(`/user_del/${userId}`)
            .then(response => {
                toast.success("User Deleted successfully !");
              //  setUsers(response.data.data);
                fetchUserDetail(selectedDepartmentId, selectedStatus);
            })
            .catch(err => {
                console.error("Error while deleting users", err);
            });
    };

    const openEditModal = (user) => {
        setShowModal(true);
        setFormData({
            name: user.name || '',
            last_name: user.last_name || '',
            phone_number: user.phone_number || '',
            department_id: user.department_id || '',
            designation_name: user.designation_name || '',
            branch_admin: user.branch_admin || '',
        });
        setEditUser(user);
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const [formData, setFormData] = useState({
        name: editUser.name || '',
        last_name: editUser.last_name || '',
        phone_number: editUser.phone_number || '',
        department_id: editUser.department_id || '',
        designation_name: editUser.designation_name || '',
        branch_admin: user.branch_admin || '',
    });

    const handleChangeModal = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const saveEditedUser = async (e) => {
        e.preventDefault();
        try {
            if (!editUser) {
                // Handle the case when editUser is null
                console.error("No user data to edit.");
                return;
            }

            const response = await http.put(`/users/${editUser.id}`, formData);
            if (Array.isArray(response.data.data)) {
                toast.success("User updated successfully!");
                setShowModal(false);
                fetchUserDetail(selectedDepartmentId, selectedStatus);
               
            } else {
                console.log("API response is not an array");
            }
        } catch (error) {
            console.error("Error updating user", error);
            toast.error("An error occurred while updating the user.");
        }
    };

    return (
        <>
            <Toaster />
            {showModal && (
                <form onSubmit={saveEditedUser}>
                    <div className="modal" tabindex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit User Details</h5>
                                    <button type="button" className="btn-close" onClick={closeModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group  mt-3'>
                                        <label>First Name</label>
                                        <input name="name" required className='form-control' type="text" value={formData.name}
                                            onChange={handleChangeModal} />
                                    </div>
                                    <div className='form-group  mt-3'>
                                        <label>Last Name</label>
                                        <input name="last_name" required className='form-control' type="text" value={formData.last_name}
                                            onChange={handleChangeModal} />
                                    </div>
                                    <div className='form-group  mt-3'>
                                        <label>Phone No</label>
                                        <input name="phone_number" required className='form-control' type="text" value={formData.phone_number}
                                            onChange={handleChangeModal} />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label>Department</label>
                                        <select
                                            required
                                            className="form-control"
                                            name="department_id"
                                            value={formData.department_id}
                                            onChange={handleChangeModal}
                                        >
                                            {departments.map((depart) => (
                                                <option key={depart.id} value={depart.id}>
                                                    {depart.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label>Designation</label>
                                        <input
                                            
                                            className="form-control"
                                            name="designation_name"
                                            value={formData.designation_name}
                                            onChange={handleChangeModal}
                                        />
                                    </div>
                                    {
                                    user && user.company_id === 1 && (                
                                    <div className="form-group mt-3">
                                        <label>Branch Admin</label>
                                        <select
                                           
                                            className="form-control"
                                            name="branch_admin"
                                            value={formData.branch_admin}
                                            onChange={handleChangeModal}
                                        >
                                           <option>Select</option>
                                           <option value="1">Yes</option>
                                           <option value="2">No</option>
                                        </select>
                                    </div>
                                )
                                }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                    <button type="submit" className="btn btn-primary" >Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}


            <div className="container mt-1">
                <div className='row'>
                    <h1 className="mb-2 col-md-4 mt2 ">Users List</h1>
                    <div className='col-md-4 form-group text-end mb-3 mt-2'>
                        <select onChange={handleChangeType} className='form-control'>
                            <option value="">All</option>
                            <option value='1'>Approved</option>
                            <option value='0'>Non-Approved</option>
                        </select>

                    </div>

                    <div className='col-md-4 form-group text-end mb-3 mt-2'>
                        <select onChange={handleChangeDept} className='form-control'>
                            <option value="">All Department</option>
                            {departments.map(depart => (
                                <option key={depart.id} value={depart.id}>{depart.name}</option>
                            ))}

                        </select>

                    </div>
                </div>
                {isLoading ? (
                    <TaskSkeleton />

                ) : (
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                    <th>No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Department</th>
                                    <th>Designations</th>
                                    <th>Registered on</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                    <th>Delete</th>
                                    {/* Add other fields as necessary */}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (

                                    <tr key={user.id}>
                                        <td className='text-uppercase fw-bold'>{index + 1}</td>
                                        <td className='text-uppercase fw-bold'>{user.name}</td>
                                        <td className='text-uppercase fw-bold'>{user.last_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone_number}</td>
                                        <td>{user.department?.name}</td>
                                        <td>{user.designation_name}</td>
                                        <td>{moment(user.created_at).format('DD MMM,YY')}</td>
                                        <td> {user.status === 1 ? <span style={{ color: 'green' }}>Approved</span> : <span style={{ color: 'red' }}>Not Approve</span>}</td>
                                        <td>{user.status === 0 ? <button onClick={() => updateStatus(user.id)} className='btn btn-sm btn-success'>Approve</button> : ''}
                                            {user.status === 1 && user.department_id !== 1 ? <button className='btn btn-warning btn-sm' onClick={() => updateStatusDis(user.id)}>De-Activate</button> : ''}
                                            {user.status === 1  ? <button className='btn btn-info btn-sm mx-2' onClick={() => openEditModal(user)}>Edit</button> : ''}
                                        </td>
                                        <td>{user.department_id !== 1 ?
                                            <button
                                                className='btn btn-sm btn-danger mx-2'
                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to delete this User it will delete there tasks too?')) {
                                                        deleteUser(user.id);
                                                    }
                                                }}
                                            >
                                                Delete
                                            </button>
                                            : ''}</td>
                                        {/* Add other fields as necessary */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}