import { useEffect, useState } from "react";
import AuthUser from "./AuthUser";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import TaskSkeleton from "./TaskSkeleton";

export default function DueReport() {
    const { http, user } = AuthUser();
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [remark, setRemark] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [loginUser, setLoginUser] = useState(user.department_id);
    const [editColumn, setEditColumn] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editUser, setEditUser] = useState({});
    useEffect(() => {
        fetchTaskDetails();
    }, []);

    const fetchTaskDetails = () => {
        setIsLoading(true);
        let endpoint = `/due_report?login_user=${user.id}`;

        http
            .get(endpoint)
            .then((res) => {
                setIsLoading(false);
                if (Array.isArray(res.data.data)) {
                    setTasks(res.data.data);
                } else {
                    setIsLoading(false);
                    console.error("API response is not an array");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching tasks:", error);
            });
    };

    console.log(tasks);
    return (
        <>
            <Toaster />

            <div className="container mt-1">
                <div className="row">
                    <h3>Class Due Report</h3>
                </div>

                <div className="table-responsive">
                    {isLoading ? (
                        <TaskSkeleton />
                    ) : (
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                    <th className="text-danger">No</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Total Students</th>
                                    <th>Year</th>
                                    <th>Total Due</th>
                                    <th>Total Paid</th>
                                    <th>Total Master</th>

                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((taskuser, index) => (
                                    <tr key={taskuser.id}>
                                        <td>{index + 1}</td>
                                        <td>{taskuser.class}</td>
                                        <td>{taskuser.dev}</td>
                                        <td><b>{taskuser.total_students}</b></td>
                                        <td><b>{taskuser.year}</b></td>
                                        <td><b>{taskuser.total_due.toLocaleString('en-IN')}</b></td>
                                        <td><b>{taskuser.total_ext_6.toLocaleString('en-IN')}</b></td>
                                        <td><b>{taskuser.total_master.toLocaleString('en-IN')}</b></td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
}
