import axios from "axios";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function AuthUser() {
  const navigate = useNavigate();
  const location = useLocation();

  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = localStorage.getItem("user");
    const user_detail = JSON.parse(userString);
    return user_detail;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(user));

    setToken(token);
    setUser(user);

    const isJobCartPage = location.pathname.startsWith("/jobcart");
    if (isJobCartPage) {
      navigate("/jobcart/dashboard-job");
    } else {
      navigate("/dashboard");
    }
  };

  const logout = () => {
    localStorage.clear();
    const isJobCartPage = location.pathname.startsWith("/jobcart");
    if (isJobCartPage) {
      navigate("/jobcart/login");
    } else {
      navigate("/login");
    }
  };

  const http = axios.create({
    baseURL: "http://3.111.220.78/api/api",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logout(); // This will clear the session and navigate to /login
      }
      return Promise.reject(error);
    }
  );

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
  };
}
